import { createAsyncThunk } from '@reduxjs/toolkit'
import { alterGameProgress } from '../store/schemas/gameProgressSlice'
import { alterNotification } from '../store/schemas/notificationSlice'
import { alterCurrentBot, alterCurrentBotPerformanceData } from '../store/schemas/automateSlice'
import { createContract } from '../helper-functions/contract'
import { loadingReset, setLoadingSlice } from '../store/schemas/loadingSlice'
import { alterNavigate } from '../store/schemas/helperFunctionsSlice'
import { purchaseItem } from './storeThunk'

const server = process.env.REACT_APP_DOMAIN


  export const getIndividualBot = createAsyncThunk(
    'bot/getIndividualBot',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=bot&path=/getIndividualBot`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({bot_uid:data.bot_uid})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            const state = thunkApi.getState()
            const botObject = state.mainapp.automateSlice.allBots.find((item) => item.botNumber == data.bot_uid)

            if(results.message.setup_status == false){

                thunkApi.dispatch(alterCurrentBot({
                    totalEarnings: parseFloat(results.message.total_earned),
                    botActivity: results.message.bot_on_off_status,
                    botFunds: parseFloat(results.message.current_balance),
                    botName: botObject.botName,
                    botId: data.bot_uid,
                    botRules: (results.message.rules == null) ? [] : results.message.rules,
                    botMatches: results.message.total_live_games,
                    setupStatus: !results.message.setup_status,
                    performanceData: {
                        totalRewards: 0,
                        totalKills: 0,
                        totalDeaths: 0,
                        botFundsDeposited: 0,
                        avgGameLength: 0
                    }
                }))  
            }
            else{
                thunkApi.dispatch(alterCurrentBot({
                    totalEarnings: parseFloat(results.message.total_earned),
                    botActivity: results.message.bot_on_off_status,
                    botFunds: parseFloat(results.message.current_balance),
                    botName: botObject.botName,
                    botId: data.bot_uid,
                    botRules: results.message.rules,
                    botMatches: results.message.total_live_games,
                    setupStatus: !results.message.setup_status,
                    performanceData: {
                        totalRewards: results.message.performanceData.totalRewards,
                        totalKills: results.message.performanceData.totalKills,
                        totalDeaths: results.message.performanceData.totalDeaths,
                        botFundsDeposited: results.message.performanceData.botFundsDeposited,
                        avgGameLength: results.message.performanceData.avgGameLength
                    }
                }))  
            }
          

            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )

  
  export const validateBotSetup = createAsyncThunk(
    'bot/getIndividualBot',
    async (data, thunkApi) => {
        try {
            if(data.player_amount == null || data.player_amount == ''){
                throw {error:"Malformed Input", message:"The player field was not provided or malformed."}
            }
            if(data.operation == null || data.operation == '' || data.operation != 'exact' && data.operation != 'minimum'){
                throw {error:"Malformed Input", message:"The operation field was not provided or malformed."}
            }
            if(data.busd == null || data.busd == ''){
                throw {error:"Malformed Input", message:"The busd field was not provided or malformed."}
            }

            const state = thunkApi.getState()

            const bot_uid = state.mainapp.automateSlice.currentBot.botId

            const response = await fetch(`${server}?auth=true&model=bot&path=/validateBotSetup`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({player_amount:data.player_amount, busd:data.busd, operation:data.operation, bot_uid: bot_uid})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }          

            thunkApi.dispatch(setLoadingSlice('MetaMask Approval...'))

            await thunkApi.dispatch(purchaseItem({item_id:4, bot_uid:bot_uid, url:'/automate'}))   
            window.location.reload()
         
            return results
        } catch (error) {
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )


  export const getAnalytics = createAsyncThunk(
    'bot/getAnalytics',
    async (data, thunkApi) => {
        try {

            const response = await fetch(`${server}?auth=true&model=bot&path=/getAnalytics`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({bot_uid:data.bot_uid, time_id:data.time_id})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            thunkApi.dispatch(alterCurrentBotPerformanceData({
                    totalRewards: results.message.totalRewards,
                    totalKills: results.message.totalKills,
                    totalDeaths: results.message.totalDeaths,
                    botFundsDeposited: results.message.botFundsDeposited,
                    avgGameLength: results.message.avgGameLength
            }))  

            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )

  export const getBotLobbies = createAsyncThunk(
    'bot/getBotLobbies',
    async (data, thunkApi) => {
        try {

            const response = await fetch(`${server}?auth=true&model=bot&path=/getBotLobbies`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({bot_uid:data.bot_uid})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            return results.message.lobbies
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )

  export const getBotLobbyInformation = createAsyncThunk(
    'bot/getBotLobbyInformation',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=bot&path=/getBotLobbyInformation`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({bot_uid:data.bot_uid})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return {message:[]}
        }
    }
  )

  
  export const turnOnOrOff = createAsyncThunk(
    'bot/turnOnOrOff',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=bot&path=/turnOnOrOff`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({bot_uid:data.bot_uid})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(getIndividualBot({bot_uid:data.bot_uid}))
            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return {message:[]}
        }
    }
  )

  export const uploadRules = createAsyncThunk(
    'bot/uploadRules',
    async (data, thunkApi) => {
        try {
            if(data.player_amount == null || data.player_amount == ''){
                throw {error:"Malformed Input", message:"The player field was not provided or malformed."}
            }
            if(data.operation == null || data.operation == '' || data.operation != 'exact' && data.operation != 'minimum'){
                throw {error:"Malformed Input", message:"The operation field was not provided or malformed."}
            }

            const state = thunkApi.getState()

            const bot_uid = state.mainapp.automateSlice.currentBot.botId

            const response = await fetch(`${server}?auth=true&model=bot&path=/uploadRules`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({player_amount:data.player_amount, operation:data.operation, bot_uid: bot_uid})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }          
            thunkApi.dispatch(getIndividualBot({bot_uid:bot_uid}))

            thunkApi.dispatch(alterNavigate({url:'/automate',replace:true}))

            return results
        } catch (error) {
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )


  
  export const fundBotSettings = createAsyncThunk(
    'bot/fundBotSettings',
    async (data, thunkApi) => {
        try {
          
            if(data.busd == null || data.busd == ''){
                throw {error:"Malformed Input", message:"The busd field was not provided or malformed."}
            }

            const state = thunkApi.getState()

            const bot_uid = state.mainapp.automateSlice.currentBot.botId

            const response = await fetch(`${server}?auth=true&model=bot&path=/fundBotSettings`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({busd:data.busd, bot_uid: bot_uid})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }          

            thunkApi.dispatch(setLoadingSlice('MetaMask Approval...'))

            await thunkApi.dispatch(purchaseItem({item_id:4, bot_uid:bot_uid, url:'/automate'}))  
            thunkApi.dispatch(getIndividualBot({bot_uid:bot_uid}))
 

            return results
        } catch (error) {
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )


  
  export const botPayout = createAsyncThunk(
    'bot/botPayout',
    async (data, thunkApi) => {
        try {
          
            const state = thunkApi.getState()

            const bot_uid = state.mainapp.automateSlice.currentBot.botId

            const response = await fetch(`${server}?auth=true&model=bot&path=/botPayout`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({transactionHash:data.transactionHash, bot_uid: bot_uid})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }         

            return results
        } catch (error) {
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )

  
import { createSlice } from "@reduxjs/toolkit";
/*

    filterLocation: can be {lat:x, long:y} data, or specify the county as a string. This will be managed on the backend.
    filters: [
        current widgets with there respective active filters
        {
            name:"Property Values",
            activeFilters: [
                {
                    name:"face Value",
                    type:"slider",
                    values: {min:0, max: 500}
                }
            ]
        }
    ]

*/

const initialState = () => ({

  "userStats": [],

  "activeUserStats": []




}
)


const userStatsSlice = createSlice({
  name: 'stats',
  initialState: initialState(),
  reducers: {
    resetUserStats: state => initialState(),
    alterUserStatsCompletedGames: (state, action) => {
        state.userStats = action.payload
    },
    alterUserStatsActiveGames: (state, action) => {
        state.activeUserStats = action.payload
    }
   

  }
})

export const {
    resetUserStats,
    alterUserStatsCompletedGames,
    alterUserStatsActiveGames
 

} = userStatsSlice.actions

export default userStatsSlice.reducer
import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import LeaderBoardStage from '../../../components/leaderboardComponents/stage';
import LeaderBoardBoard from '../../../components/leaderboardComponents/board';

const M_Top100 = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
       
        <div className='leaderboard-container' style={{
            marginTop: "0px"
        }}>
               
                <LeaderBoardBoard displayCount={100}  />
            </div>
            <style>
        {`
   .board-header > .board-item {border-top:none !important;}

        `}
      </style>

        </>
        
    );

}

export default M_Top100;
import React from 'react';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const LeaderBoardBoard = (props) => {
    const { displayCount } = props; // prop that decides whether to show top 10 or top 100

    const boardTop10 = useSelector(state => state.mainapp.leaderboardSlice.top10);
    const boardTop100 = useSelector(state => state.mainapp.leaderboardSlice.top100);

    const leaderboardData = displayCount === 100 ? boardTop100 : boardTop10;


    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const threshold = 10; // Set your threshold here
            if (window.pageYOffset > threshold) {

                setHasScrolled(true);


            } else {

                setHasScrolled(false);

            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        <div className='board-container'>
            <div className='board-header'>
                <div className={
                    !hasScrolled ? 'board-item vivify fadeIn duration-300 delay-300' : 'board-item vivify fadeIn duration-300 delay-300 scrolled-board-header'
                }>
                    <p>Rank</p>
                    <p>Username</p>

                    <p>Rewards</p>
               
                    <p>Completed Battles</p>
                    <p>Ongoing Battles</p>
                    <p>Kills</p>
                    <p>Deaths</p>
                    <p>K/D</p>
                    <p>Survivor Bonus</p>
                </div>
            </div>
            <div className='board-column'>
                {leaderboardData.map((user, index) => (
                    <div key={index}
                        className={`board-item ${user.rank <= 3 ? 'top-3' : ''} ${user.rank === 1 ? 'first vivify fadeIn duration-300 delay-500' : user.rank === 2 ? 'second vivify fadeIn duration-300 delay-600' : user.rank === 3 ? 'third vivify fadeIn duration-300 delay-700' : 'vivify fadeIn duration-300 delay-800'}`}>
                        {/* Rank */}
                        <p>{user.rank}</p>
                        {/* User Name */}
                        <p className={"board-name"}>{user.userName}</p>
                        {/* Total Rewards */}
                        <p>{user.totalRewards}</p>
                        {/* Number of Battles */}
                        <p>{user.completedBattles || "0"}</p>
                        {/* Number of Ongoing Battles */}
                        <p>{user.ongoingBattles || "0"}</p>
                        {/* Number of Kills */}
                        <p>{user.numberOfKills}</p>
                        {/* Number of Deaths */}
                        <p>{user.numberOfDeaths}</p>
                        {/* K/D Ratio */}
                      
                        <p>
                            {user.kdratio === "Infinity" ? <span>Unkillable</span> : user.kdratio}
                        </p>

                        <p>{user.survivorRewards || "Unavailable"}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LeaderBoardBoard;

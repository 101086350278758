import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import SubNavBar from '../../subNavbar';
import { alterNotification } from '../../../store/schemas/notificationSlice';
import ProgressMap from '../../progressMap';
const GameActivity = () => {

    const [agreeStatus, setAgreeStatus] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pastPlays_team1 = useSelector((state) => state.mainapp.gameProgressSlice.gameProgressSlice.pastPlays_team1)
    const pastPlays_team2 = useSelector((state) => state.mainapp.gameProgressSlice.gameProgressSlice.pastPlays_team2)
    const pastPlays = useSelector((state) => state.mainapp.gameProgressSlice.gameProgressSlice.pastPlays)
    const [gameData, setGameData] = useState(pastPlays_team1)
    const currentBot = useSelector((state) => state.mainapp.automateSlice.currentBot)
    const userInfo = useSelector((state) => state.auth.userInfo)
    const [currentUserTeam, setCurrentUserTeam] = useState()


    const [currentTeam1, setCurrentTeam1] = useState("Team 1")
    const [currentTeam2, setCurrentTeam2] = useState("Team 2")


    useEffect(() => {
        let username = (window.location.pathname.includes('/automate') ? currentBot.botName : userInfo.username)

        pastPlays_team1.map((day) => {
            day.results.map((shot) => {

                if (shot.username === username) {
                    setActiveLink('team1')
                    setCurrentUserTeam('team1')
                }
            })
        }
        )

        pastPlays_team2.map((day) => {
            
            day.results.map((shot) => {
                if (shot.username === username) {
                   
                    setActiveLink('team2')
                    setCurrentUserTeam('team2')
                }
            })
        }
        )

    }, [])

    const [activeLink, setActiveLink] = useState(null)

    useEffect(() => {
        setActiveLink(currentUserTeam)
    }, [currentUserTeam])

    useEffect(() => {
        if (activeLink === 'team1') {
            setGameData(pastPlays_team1);
            document.getElementById('team1').checked = true;
  
        } else if (activeLink === 'team2') {
            setGameData(pastPlays_team2);
            document.getElementById('team2').checked = true;

        }
    }, [activeLink]);





    const links = [


        {
            'name': currentTeam1,
            'id': 'team1',
        },
        {
            'name': currentTeam2,
            'id': 'team2',
        }


    ]






    return (
        <>
            <div className='game-time-strip'>
                <div className='side-buttons smart-strip'>
                    {links.map((link) => (
                        <div className='side-button'>
                            <input type="radio" id={link.id} onChange={
                                async (e) => {

                                    setActiveLink(e.target.value);
                                }
                            } defaultChecked={
                                link.id === activeLink ? true : false
                            } name="smartStrip" value={link.id} />
                            <label id={link.id + "label"} for={link.id}>
                                {link.name}
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            <div className='progress-container vivify fadeIn duration-300'>

                <div className='left-gameBlock'>
                    <h4>Battle Timeline</h4>


                    <div className='battle-timeline'>
                        <div className='b-timeline'>
                            <div className='b-timeline-header'>
                                <h5>Attacker</h5>

                                <h5>Opponent</h5>
                            </div>

                            <div className='b-timeline-body'>
                                {
                                    gameData === null || gameData === undefined ?
                                        navigate(-1) : null
                                }


                                {gameData.map((day) => {
                                    return (
                                        <div className='day-grouper'>

                                            <h5 id={day.day + "currentDay"}>Day {day.day}</h5>
                                            {day.results.map((shot) => {
                                                return (
                                                    <div className='b-time-strip'>
                                                        <div className='b-time-left b-time'>
                                                            <img src={'https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=' + shot.username}></img>
                                                            <div className='b-text'>
                                                                <h6>{shot.username}</h6>
                                                            </div>
                                                        </div>
                                                        <div className='b-time-divider'></div>
                                                        {(shot.status != "miss") ?
                                                            <div className='b-time-right b-time'>


                                                                {


                                                                    shot.status != "miss" ?



                                                                        shot.status.length > 1 ?

                                                                            <div className='img-grid'>

                                                                                {
                                                                                    shot.status.length < 4 ?
                                                                                        shot.status.map((status) => {
                                                                                            return (
                                                                                                <div className='img-grid-item'>
                                                                                                    <img src={'https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=' + status}></img>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        )
                                                                                        :
                                                                                        <img src={'https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=' + shot.status[0]}></img>
                                                                                }
                                                                                {

                                                                                }
                                                                            </div>
                                                                            :
                                                                            <img src={'https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=' + shot.status[0]}></img> : null

                                                                }


                                                                <div className='b-text'>
                                                                    {


                                                                        (shot.status.length > 1) ?

                                                                            <details >
                                                                                <summary className='b-text'>
                                                                                    <p><span onClick={
                                                                                        () => {




                                                                                        }
                                                                                    }>{shot.status.length} Player(s)</span></p>
                                                                                </summary>

                                                                                {shot.status.map((status) => {
                                                                                    return (
                                                                                        <div className='b-text vivify fadeIn duration-300' style={
                                                                                            {
                                                                                                marginBottom: '3px'
                                                                                            }
                                                                                        }>
                                                                                            <p>{status}</p>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                }

                                                                            </details>




                                                                            :
                                                                            <h6 style={
                                                                                {
                                                                                    fontSize: '13px'
                                                                                }
                                                                            }>{shot.status[0]}</h6>


                                                                    }

                                                                    <p className='eliminated'>Eliminated</p>
                                                                </div>
                                                                <span>{shot.attack}</span>
                                                            </div>
                                                            :
                                                            <div className='b-time-right b-time'>
                                                                <img src={'https://th.bing.com/th/id/OIP.XMCnkvAWeStGCMOkkGliLQHaHa?pid=ImgDet&rs=1'}></img>
                                                                <div className='b-text'>
                                                                    <h6>Missed</h6>
                                                                    <p>Empty Location</p>
                                                                </div>

                                                                <span>{shot.attack}</span>
                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            })}




                                        </div>

                                    )
                                })}












                            </div>
                        </div>
                    </div>


                </div>

                <div className='right-gameBlock' style={
                    {
                        position: "sticky",
                        top: "0px",
                        height: "fit-content",

                    }
                }>
                    <h4><span id='dayChip' className='vivify fadeIn duration-300'>First Day </span>Game View {/* <p> Beta v0.8</p> */}</h4>
                    <ProgressMap team={activeLink} />

                </div>

            </div>

            <style>
                {`
                    .game-time-strip{
                        margin-top: -27px;
                        display: flex;
                        justify-content: flex-end;
                    }

                    .side-button > label {
                        background-color: var(--frostedBackground);
                        outline: var(--frostedBorder);
                    }
                    `}
            </style>

        </>
    );

}

export default GameActivity;
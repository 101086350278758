import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getGameProgress } from '../../httpcalls/gameThunk';
import { payout } from '../../httpcalls/moneyThunk';
import { alterLobbyBackgroundInfo } from '../../store/schemas/gameProgressSlice';
import { loadingReset, setLoadingSlice } from '../../store/schemas/loadingSlice';
import Loader from '../loader';
import { createPayout } from '../../helper-functions/contract';

const LobbyFinishedCard = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [status, setStatus] = useState(props.status);
    const currentBot = useSelector((state) => state.mainapp.automateSlice.currentBot)


    
    const launchTask = () => {
        dispatch(setLoadingSlice('Loading Game Progress...'))
        dispatch(alterLobbyBackgroundInfo(props))
        dispatch(getGameProgress({gameIndex:props.trackNum})).then((res) => {
            dispatch(loadingReset())
            const location = window.location.pathname
            const first_name = '/' + (location.split('/'))[1] +'/progress/report'
            navigate(first_name)
        })
    }    

    // const launchPayout = (busd, lobby_id) => {
    //     if (window.location.pathname.includes('automate')) {
    //         dispatch(createPayout({ amount: busd, lobby_id: lobby_id, bot_uid: currentBot.botId }))
    //     }
    //     else {
    //         dispatch(createPayout({ amount: busd, lobby_id: lobby_id }))

    //     }
    // }

    return (
        <>



            <div className='game-card claim-card' >
            
                <div className='game-card-header'>
                    <div className='game-card-header-left'>
                        <h3 className={'game-track claim-track'}><span>{props.trackNum}</span></h3>
                        <div className='game-card-title'>
                            
                                {(props.dead == true ? <h4>Eliminated</h4> : <h4>Game Completed</h4>)}
                            
                                <p>BUSD <span className={'claim-price'}>{props.value}</span></p>

                        </div>
                    </div>

                    <div className='game-card-header-right'>
                        {

                            props.day >= 0 ? <p>Day {props.day}</p> : ""
                        }

                    </div>
                </div>

                <div className='game-card-body'>
               
                    <div className={'claim-mode ' + status + '-theMode'} style={{width:"100%"}}>
                    {/* {(props.value != "$0.00" && props.claim == false ?  <h4 style={
                        {
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            gap: "5px"
                        }
                    }><i className='material-icons'>price_check</i>Rewards Claimed</h4> : "")} */}
                        <div className='card-two-buttons' >
                            
                            {(() => {
                                if(props.busd != 0 && props.claim == true){
                                    return (<>
                                        {/* <button onClick={() => {launchPayout(props.busd, props.trackNum)}} className="claim-button-card card-button-double">Claim</button> */}
                                        <button className="view-claim-button-card card-button-double" onClick={() => {launchTask()}}>View Game</button>
                                        </>
                                    )
                                }
                                else{
                                    return (<>
                                        <button className="view-claim-button-card card-button-solo" onClick={() => {launchTask()}}>View Game</button>
                                        </>
                                    )
                                }
                            })()}
                           
                           </div>
                        <p style={{fontSize:"12px", textAlign:"center"}}>All games are final. No refunds.</p>
                    </div>

                </div>

                    
                <div className='game-card-block'>
                    <p>Game Mode: {
                        props.gameType == 'default' ? "Classic" : props.gameType.includes('survivor') ? "Survivor" : "Unavailable"
                    }</p>
                    <div className='vert-divider'>
                        <div className='vert-line'></div>
                    </div>
                    <p>Total Players: {props.players}</p>      </div>
                    
            </div>


        </>
    );

}

export default LobbyFinishedCard;
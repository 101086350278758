import { createAsyncThunk } from '@reduxjs/toolkit'
import { alterNotification } from '../store/schemas/notificationSlice'
import { loadingReset, setLoadingSlice } from '../store/schemas/loadingSlice'
import { alterAllLeaderboard, alterPlayerLookupResults } from '../store/schemas/leaderboardSlice'


const server = process.env.REACT_APP_DOMAIN



  
  export const getHundredPlayers = createAsyncThunk(
    'leaderboard/getHundredPlayers',
    async (data, thunkApi) => {
        try {
       
            const response = await fetch(`${server}?auth=true&model=lead&path=/getHundredPlayers`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }         
            await thunkApi.dispatch(alterAllLeaderboard(results.message))

            return results
        } catch (error) {
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )

  export const getTenPlayers = createAsyncThunk(
    'leaderboard/getTenPlayers',
    async (data, thunkApi) => {
        try {
       
            const response = await fetch(`${server}?auth=true&model=lead&path=/getTenPlayers`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }         
            await thunkApi.dispatch(alterAllLeaderboard(results.message))

            return results
        } catch (error) {
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )



  export const findPlayer = createAsyncThunk(
    'leaderboard/findPlayer',
    async (data, thunkApi) => {
        try {
                if(data.username == ''){
                    await thunkApi.dispatch(alterPlayerLookupResults([]))
                }
                else{
                    const response = await fetch(`${server}?auth=true&model=lead&path=/findPlayer`, {
                        method:'POST',
                        credentials: 'include',
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify({username:data.username})               
                    })
                    const results = await response.json()
                    if("error" in results){
                        throw results
                    }         
                    await thunkApi.dispatch(alterPlayerLookupResults(results.message)) 
                }               
                     
        } catch (error) {
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )

  
import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import SubNavBar from '../../subNavbar';
const Order = () => {

  const [agreeStatus, setAgreeStatus] = useState(false);
  const navigate = useNavigate();


  return (
    <>

      <div className='legal-text'>
        
        <p>At 0XBullzeye, we believe in providing a fair and transparent gameplay experience for our users. To achieve this, we utilize a seed-based system for matchmaking and attack order. This means that when a user enters a lobby, they are placed on a team based on a seed generated by our cryptographic algorithm API. This ensures that team assignments are not random and are based on a predetermined and transparent system. Additionally, when it's time for attacks to take place, another seed is generated to determine the order of attacks. Again, this system ensures that the order of attacks is not random, but rather based on a predetermined system that is transparent to our users.</p>

        <p>
        We understand that fairness is critical in a game that uses crypto currency, and we are committed to providing a transparent system for randomization. By utilizing seed-based systems, we are able to provide a transparent and fair experience for all of our users. We believe that this sets us apart from other games that rely on randomization or other opaque methods for matchmaking and attack order. At 0XBullzeye, we believe in transparency and we are proud to offer a system that ensures that all users have an equal and fair opportunity to succeed.
        </p>

   

      </div>

    
      <style>
        {`
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}
        `}
      </style>

    </>
  );

}

export default Order;
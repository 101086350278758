import { createSlice } from "@reduxjs/toolkit";
/*

    filterLocation: can be {lat:x, long:y} data, or specify the county as a string. This will be managed on the backend.
    filters: [
        current widgets with there respective active filters
        {
            name:"Property Values",
            activeFilters: [
                {
                    name:"face Value",
                    type:"slider",
                    values: {min:0, max: 500}
                }
            ]
        }
    ]

*/

const initialState = () => ({

  "top10": [],

  "top100": [],

  "playerLookupResults": []

}
)


/* {todaysKills: 0, 
totalKills: 0, 
totalDead: 0, 
gameRewardsPerKill:9.3, 
currentDay:1, 
maxDay:27, 
playersRewards:{
    userTeam:[{userName:"name", totalKills:0, dead:false}], 
    enemyTeam:[{userName:"name", totalKills:0, dead:false}]
} */

const leaderBoardSlice = createSlice({
  name: 'Leaderboard',
  initialState: initialState(),
  reducers: {
    resetLeaderboard: state => initialState(),
    resetSearchLeaderboard: (state, action) => {
      state.playerLookupResults = []
    },
    alterAllLeaderboard: (state, action) => {
      state.top10 = action.payload.slice(0, 10);
      state.top100 = action.payload
  },
    alterTop10: (state, action) => {
        state.top10 = action.payload
    },
    alterTop100: (state, action) => {
        state.top100 = action.payload
    },
    alterPlayerLookupResults: (state, action) => {
      state.playerLookupResults = action.payload
    }

  }
})

export const {
    resetLeaderboard,
    alterGameProgress,
    alterLobbyBackgroundInfo,
    alterAllLeaderboard,
    alterPlayerLookupResults,
    resetSearchLeaderboard
 

} = leaderBoardSlice.actions

export default leaderBoardSlice.reducer
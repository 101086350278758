import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import LeaderBoardStage from '../../../components/leaderboardComponents/stage';
import LeaderBoardBoard from '../../../components/leaderboardComponents/board';
import { useDispatch, useSelector } from 'react-redux';
import { findPlayer } from '../../../httpcalls/leaderboardThunk';
import Queue from 'queue'
import { resetSearchLeaderboard } from '../../../store/schemas/leaderboardSlice';


const M_Lookup = (props) => {

    const [searchInput, setSearchInput] = useState(null); // Search input state


    const [queue, setQueue] = useState(new Queue({ autostart: true }))
    const [time, setTime] = useState(null)
    const [load, setLoad] = useState(true)
    useEffect(() => {
        dispatch(resetSearchLeaderboard())
        setLoad(false)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const dispatch = useDispatch()


    useEffect(() => {
        if (searchInput != null) {
            if (time != null) {
                clearTimeout(time)
            }
            const t = setTimeout(() => {
                queue.push(async () => {
                    dispatch(findPlayer({ username: searchInput }))

                })
            }, 200)
            setTime(t)


        }


    }, [searchInput])

    const { displayCount } = props; // prop that decides whether to show top 10 or top 100

    const lookup = useSelector(state => state.mainapp.leaderboardSlice.playerLookupResults);

    const leaderboardData = lookup

    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const threshold = 10; // Set your threshold here
            if (window.pageYOffset > threshold) {
                setHasScrolled(true);

            } else {
                setHasScrolled(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (

        <>
        {load == false ?
        <div className='leaderboard-container'>
        <div className='search-wrapper'>
            <div className='search-container'>
                <i  className='material-icons'>
                    search
                </i>
                <input
                    type="text"
                    placeholder="Enter Username..."
                    className="search-input"
                    onChange={(e) => { setSearchInput(e.target.value) }}
                />

            </div>
        </div>

        <div className='board-container'>

            {
                leaderboardData.length < 1 ? "" : <div className='board-header'>
                    <div className={
                        !hasScrolled ? 'board-item vivify fadeIn duration-300 ' : 'board-item vivify fadeIn duration-300  scrolled-board-header'
                    }>
                     <p>Rank</p>
            <p>Username</p>
            
            <p>Rewards</p>
        
            <p>Completed Battles</p>
            <p>Ongoing Battles</p>
            <p>Kills</p>
            <p>Deaths</p>
            <p>K/D</p>
            <p>Survivor Bonus</p>
                    </div>
                </div>
            }
            <div className='board-column'>
                {
                    leaderboardData.length < 1 && (searchInput != null || searchInput != "") ? <p id='itemTitle' >
                        No Results Found
                    </p>
                        : leaderboardData.map((user, index) => (
                            <div key={index}
                                className={`board-item ${user.rank <= 3 ? 'top-3' : ''} ${user.rank === 1 ? 'first vivify fadeIn duration-300 ' : user.rank === 2 ? 'second vivify fadeIn duration-300 ' : user.rank === 3 ? 'third vivify fadeIn duration-300 ' : 'vivify fadeIn duration-300'}`}>


                                {/* Rank */}
                                <p>{user.rank}</p>
                                {/* User Name */}
                                <p title={user.userName} className={"board-name"}>{user.userName}</p>
                                {/* Total Rewards */}
                                <p>{user.totalRewards}</p>
                                {/* Number of Battles */}
                                <p>{user.completedBattles || "0"}</p>
                                {/* Number of Ongoing Battles */}
                                <p>{user.ongoingBattles || "0"}</p>
                                {/* Number of Kills */}
                                <p>{user.numberOfKills}</p>
                                {/* Number of Deaths */}
                                <p>{user.numberOfDeaths}</p>
                                {/* K/D Ratio */}
                                <p>
                                    {user.kdratio === "Infinity" ? <span>Unkillable</span> : user.kdratio}
                                </p>

                                <p>{user.survivorRewards || "Unavailable"}</p>
                            </div>
                        ))
                }

            </div>
        </div>
    </div>
        :
        ""
        }
           
        
        </>
     
    );

}

export default M_Lookup;
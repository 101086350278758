import React, { useRef, useEffect, useState } from 'react';


const LobbyNoMinCard = (props) => {

        // HANDLES WHEN PERSON ENTERS LOBBY WITHOUT MIN PLAYERS BEING MET

    return (
        <>

            <div className='game-card taken-card ' >

                <div className='game-card-header'>
                    <div className='game-card-header-left'>
                        <h3 className={'game-track taken-track'}><span>{props.trackNum}</span></h3>
                        <div className='game-card-title'>
                            <h4>Collecting Players...</h4>

                        </div>
                    </div>

                    <div className='game-card-header-right'>

                    </div>
                </div>

                <div className='game-card-body'>
                
                    
                    <div className={'claim-mode waiting-theMode'}>
                        <p style={{fontSize:"12px", fontWeight:600, color:"white"}}>6 players are needed to start</p>
                        <p style={{fontSize:"12px", textAlign:"center"}}>All games are final. No refunds.</p>
                    </div>

                </div>

                    {  
                <div className='game-card-block'>
                    <p>Game Mode: {
                         props.gameType == 'default' ? "Classic" : props.gameType.includes('survivor') ? "Survivor" : "Unavailable"
                    }</p>
                    <div className='vert-divider'>
                        <div className='vert-line'></div>
                    </div>
                    <p>Total Players: {props.players}</p>      </div>
 }
            </div>


        </>
    );

}

export default LobbyNoMinCard
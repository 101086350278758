import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const Loader = (prop) => {
  const loadingText = useSelector(state => state.mainapp.loadingSlice.loading)
   
  return (
    <>
      {(loadingText != null) ? 
        <div style={{zIndex:999999999999999}} className='loader-container'>
        <div className='loader vivify swoopInBottom delay-100'>
      
            <div className='loader-inner '>
            <div class="indeterminate-progress-bar">
    <div class="indeterminate-progress-bar__progress"></div>
</div>
                <h3 className='vivify'>{loadingText}</h3>

                </div>
            </div>

          
      </div>
      
      : ""}
    

      

    </>
  );

}

export default Loader;
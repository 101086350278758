import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import SubNavBar from '../../subNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { alterSetupStatus } from '../../../store/schemas/automateSlice';
import BotConfig from '../../botComponents/botConfig';

const BotSetup = () => {


  return (
    <>

      <BotConfig mode="setup" />


    </>
  );

}

export default BotSetup;
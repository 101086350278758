import React, { useRef, useEffect, useState } from 'react';


const WaitingCard = (props) => {

    // HANDLES WHEN PERSON IS ON WAITLIST

    return (
        <>

            <div className='game-card waiting-card ' >

                <div className='game-card-header'>
                    <div className='game-card-header-left'>
                        <h3 className={'game-track waiting-track'}>{
                           <span><i className='material-icons' style={
                            {
                                marginTop: '5px',
                                color: 'var(--main-color)'
                            }
                        }>pending</i></span>
                        }<span></span></h3>
                        <div className='game-card-title'>
                        <h4>Game Queue </h4> 

                                <p><span className={'waiting-price'}>Waiting For Open Lobby</span></p>

                        </div>
                    </div>

                    <div className='game-card-header-right'>

                    </div>
                </div>

                <div className='game-card-body'>                    
                    <div className={'claim-mode'}>
                        <p style={{fontSize:"12px", fontWeight:600, color:"white"}}>Waiting for an open lobby.</p>
                       
                        <p style={{fontSize:"12px", textAlign:"center"}}>All games are final. No refunds.</p>
                    </div>

                </div>
            </div>


        </>
    );

}

export default WaitingCard;
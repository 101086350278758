import React, { useRef, useEffect, useState } from 'react';
import SideToolBar from '../../../components/sideToolbar';
import Footer from '../../../components/footer';

import { Outlet, useNavigate } from 'react-router-dom';
const MyStats = () => {

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
      function handleScroll() {
          const threshold = 0; // Set your threshold here
          if (window.pageYOffset > threshold) {
              setHasScrolled(true);
          
          } else {
              setHasScrolled(false);
          }
      }

      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);



  return (
    <>
      <Outlet />
      <div className='menu-body vivify fadeIn duration-300 delay-200'>


     

      <h1 className='page-title' id='pageTitle' style={
        !hasScrolled ? {opacity: 1} : {opacity: 0}
     }>My Stats</h1>
     

        <SideToolBar
          links={

            [
              {
                'name': 'Completed Games',
                'id': 'stats'
              },
              {
                'name': 'Active Games',
                'id': 'activegamestats'
              }

            ]
          }
          defaultLink={'stats'}
        />

      </div>

    </>
  );

}

export default MyStats;
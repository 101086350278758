import React, { useRef, useEffect, useState } from 'react';
import Logo from '../assets/logo.svg';
import { Link } from "react-router-dom";
import ProfileChip from './profileChip';
import navbaracrylic from '../assets/navbaracrylic.png';
import { useDispatch, useSelector } from 'react-redux';
import { alterNotification } from '../store/schemas/notificationSlice';
import { getUserClaimableAmount } from '../httpcalls/moneyThunk'

const Navbar = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.auth.userInfo)
    const totalValue = useSelector(state => state.mainapp.claimSlice.totalValue);
    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const threshold = 100; // Set your threshold here
            if (window.pageYOffset > threshold) {
                setHasScrolled(true);
            } else {
                setHasScrolled(false);
            }
        }

        window.addEventListener('scroll', handleScroll);

        dispatch(getUserClaimableAmount())
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <div className='padding-acrylic'></div>

            <nav className={
                hasScrolled ? 'navbar vivify fadeIn duration-300 delay-200 scroll-nav ' : 'navbar vivify fadeIn duration-300 delay-200 '
            }>

                <div className='left-nav'>
                    <Link to={"/play"}>
                        <div className='navbar-logo vivify fadeIn duration-300 delay-200'>
                            <img style={
                                {

                                }
                            } src={Logo} alt='logo' />
                        </div>
                    </Link>

                    <ul className='navbar-links'>

                        <Link to={"/play"} className={(window.location.pathname == "/play" || window.location.pathname == "/games" || window.location.pathname == "/stats") ? "focused-nav-item" : "inactive-nav-item"} >
                            <button className='navbar-button vivify fadeIn duration-300 delay-300'  >
                                <i style={
                                    {
                                        margin: '-3px -5px -5px -8px',

                                        fontSize: '1.7rem',

                                    }
                                } class="material-icons-outlined">play_arrow</i>
                                <span>Play</span>
                            </button>
                        </Link>

                        <Link to="/shop" className={(window.location.pathname == "/shop") ? "focused-nav-item" : "inactive-nav-item"}>
                            <button
                                className='navbar-button green-outline vivify fadeIn duration-300 delay-350' >
                                <i class="material-icons-outlined">shopping_cart</i>
                                <span>Shop</span>
                            </button>
                        </Link>

                        <Link to={"/leaderboard"} className={(window.location.pathname == "/leaderboard") ? "focused-nav-item" : "inactive-nav-item"}>
                            <button className='navbar-button yellow-outline vivify fadeIn duration-300 delay-400 ' >
                                <i class="material-icons-outlined">leaderboard</i>
                                <span>Leaderboard</span>
                            </button>
                        </Link>

                        <Link to={"/automate"} className={(window.location.pathname == "/automate") ? "focused-nav-item" : "inactive-nav-item"}>
                            <button onClick={
                                () => {
                                    /*  dispatch(alterNotification( {alertStyle: "info",  messageTitle: 'Feature Unavailable', message: 'This  feature is currently unavailable. ', visible: true} )) ; */
                                }
                            } className='navbar-button blue-outline vivify fadeIn duration-300 delay-450'  >
                                <i style={
                                    {
                                        marginLeft: '-3px',
                                        fontSize: '20px'
                                    }
                                } class="material-icons-outlined">smart_toy</i>
                                <span>Automate</span>
                            </button>
                        </Link>


                    </ul>
                </div>



                <div className='nav-profile vivify fadeIn duration-300 delay-500'>
                 

                    <ProfileChip />
                </div>




            </nav>

        </>
    );

}

export default Navbar;
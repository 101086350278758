import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";


const M_Featured = (props) => {
    const navigate = useNavigate();



    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
            <div className='side-content'>

                <div className='block-layout'>
                    <div className='hero-block'>
                        <div className='block-tagline'>
                            <div className='block-vert-divder'>
                                <div className='block-vert-line'></div>
                            </div>
                            <h1>New Item </h1>
                        </div>
                        <div className='block-charm'>
                            <div className='charm-text'>
                                <h4>BUSD <span>20.00</span></h4>
                                <h2>Auto Play <span>Bot</span> </h2>
                                <p>Level up Your Earnings. Make Auto-Play Bot Your Winning Strategy</p>
                            </div>
                            <div className='charm-button'>
                                <button className='btn btn-primary' onClick={
                                    () => {
                                        navigate('/shop/item/botPurchase')
                                    }
                                }>Get Started</button>
                            </div>
                        </div>
                    </div>

                 

                <div className='shop-blocks'>
                        <div className='shop-block'>
                        <div className='block-charm'>
                            <div className='charm-text'>
                                <h4>BUSD <span>20.00</span></h4>
                                <h2>Name <span>Change</span> </h2>
                                <p>Stand out from the crowd with a personalized username.</p>
                            </div>
                            <div className='charm-button'>
                                <button className='btn btn-primary' onClick={
                                    () => {
                                        navigate('/shop/item/namechange')
                                    }
                                }>Get Started</button>
                            </div>
                        </div>

                    </div>

                   
                </div>

                

            </div>
            </div>


        </>
    );

}

export default M_Featured;
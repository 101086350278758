import { createAsyncThunk } from '@reduxjs/toolkit'
import { alterNotification } from '../store/schemas/notificationSlice'
import { alterNavigate } from '../store/schemas/helperFunctionsSlice'
import { loadingReset, setLoadingSlice } from '../store/schemas/loadingSlice'
import { setClaimAll } from '../store/schemas/claimSlice'


const server = process.env.REACT_APP_DOMAIN


  export const trackTransaction = createAsyncThunk(
    'auth/trackTransaction',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=money&path=/trackTransaction`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({tx:data.tx})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            // await thunkApi.dispatch(getUser())
            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )

  export const payout = createAsyncThunk(
    'auth/payout',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=money&path=/payout`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({gameIndex:data.gameIndex})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            // thunkApi.dispatch(alterGameProgress(results.message))
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle:  'success', messageTitle: 'Payout Success', message: 'Your recent payout was a success!' ,visible: true}))
            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )
  

  export const listenToTransaction = createAsyncThunk(
    'contract/listenToTransaction',
    async (data, thunkApi) => {
        try {
            await thunkApi.dispatch(setLoadingSlice('Processing and Validating Payment...'))
            const res = await fetch(`${server}?auth=true&model=money&path=/listenToTransaction`, {
                method:"POST",
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({transact_id: data.transact_id})
            })
            const result = await res.json()
            if("error" in result){
                throw result
            }
            
            await thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle:  'success', messageTitle: 'Payment Success', message: 'Your recent payment was a success!' ,visible: true}))
            await thunkApi.dispatch(loadingReset())
            await thunkApi.dispatch(alterNavigate({url:data.url, replace:true}))

        } catch (error) {
            thunkApi.dispatch(loadingReset())

            console.log(error)
        }
    }
  )




  export const getUserClaimableAmount = createAsyncThunk(
    'auth/getUserClaimableAmount',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=money&path=/getUserClaimableAmount`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            await thunkApi.dispatch(setClaimAll(results.message))
            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )
module.exports = {
  0: {f: 'General'},
  1: {f: '0'},
  2: {f: '0.00'},
  3: {f: '#,##0'},
  4: {f: '#,##0.00'},
  9: {f: '0%'},
  10: {f: '0.00%'},
  11: {f: '0.00E+00'},
  12: {f: '# ?/?'},
  13: {f: '# ??/??'},
  14: {f: 'mm-dd-yy'},
  15: {f: 'd-mmm-yy'},
  16: {f: 'd-mmm'},
  17: {f: 'mmm-yy'},
  18: {f: 'h:mm AM/PM'},
  19: {f: 'h:mm:ss AM/PM'},
  20: {f: 'h:mm'},
  21: {f: 'h:mm:ss'},
  22: {f: 'm/d/yy "h":mm'},

  27: {
    'zh-tw': '[$-404]e/m/d',
    'zh-cn': 'yyyy"年"m"月"',
    'ja-jp': '[$-411]ge.m.d',
    'ko-kr': 'yyyy"年" mm"月" dd"日"',
  },
  28: {
    'zh-tw': '[$-404]e"年"m"月"d"日"',
    'zh-cn': 'm"月"d"日"',
    'ja-jp': '[$-411]ggge"年"m"月"d"日"',
    'ko-kr': 'mm-dd',
  },
  29: {
    'zh-tw': '[$-404]e"年"m"月"d"日"',
    'zh-cn': 'm"月"d"日"',
    'ja-jp': '[$-411]ggge"年"m"月"d"日"',
    'ko-kr': 'mm-dd',
  },
  30: {'zh-tw': 'm/d/yy ', 'zh-cn': 'm-d-yy', 'ja-jp': 'm/d/yy', 'ko-kr': 'mm-dd-yy'},
  31: {
    'zh-tw': 'yyyy"年"m"月"d"日"',
    'zh-cn': 'yyyy"年"m"月"d"日"',
    'ja-jp': 'yyyy"年"m"月"d"日"',
    'ko-kr': 'yyyy"년" mm"월" dd"일"',
  },
  32: {
    'zh-tw': 'hh"時"mm"分"',
    'zh-cn': 'h"时"mm"分"',
    'ja-jp': 'h"時"mm"分"',
    'ko-kr': 'h"시" mm"분"',
  },
  33: {
    'zh-tw': 'hh"時"mm"分"ss"秒"',
    'zh-cn': 'h"时"mm"分"ss"秒"',
    'ja-jp': 'h"時"mm"分"ss"秒"',
    'ko-kr': 'h"시" mm"분" ss"초"',
  },
  34: {
    'zh-tw': '上午/下午 hh"時"mm"分"',
    'zh-cn': '上午/下午 h"时"mm"分"',
    'ja-jp': 'yyyy"年"m"月"',
    'ko-kr': 'yyyy-mm-dd',
  },
  35: {
    'zh-tw': '上午/下午 hh"時"mm"分"ss"秒"',
    'zh-cn': '上午/下午 h"时"mm"分"ss"秒"',
    'ja-jp': 'm"月"d"日"',
    'ko-kr': 'yyyy-mm-dd',
  },
  36: {
    'zh-tw': '[$-404]e/m/d',
    'zh-cn': 'yyyy"年"m"月"',
    'ja-jp': '[$-411]ge.m.d',
    'ko-kr': 'yyyy"年" mm"月" dd"日"',
  },

  37: {f: '#,##0 ;(#,##0)'},
  38: {f: '#,##0 ;[Red](#,##0)'},
  39: {f: '#,##0.00 ;(#,##0.00)'},
  40: {f: '#,##0.00 ;[Red](#,##0.00)'},
  45: {f: 'mm:ss'},
  46: {f: '[h]:mm:ss'},
  47: {f: 'mmss.0'},
  48: {f: '##0.0E+0'},
  49: {f: '@'},

  50: {
    'zh-tw': '[$-404]e/m/d',
    'zh-cn': 'yyyy"年"m"月"',
    'ja-jp': '[$-411]ge.m.d',
    'ko-kr': 'yyyy"年" mm"月" dd"日"',
  },
  51: {
    'zh-tw': '[$-404]e"年"m"月"d"日"',
    'zh-cn': 'm"月"d"日"',
    'ja-jp': '[$-411]ggge"年"m"月"d"日"',
    'ko-kr': 'mm-dd',
  },
  52: {
    'zh-tw': '上午/下午 hh"時"mm"分"',
    'zh-cn': 'yyyy"年"m"月"',
    'ja-jp': 'yyyy"年"m"月"',
    'ko-kr': 'yyyy-mm-dd',
  },
  53: {
    'zh-tw': '上午/下午 hh"時"mm"分"ss"秒"',
    'zh-cn': 'm"月"d"日"',
    'ja-jp': 'm"月"d"日"',
    'ko-kr': 'yyyy-mm-dd',
  },
  54: {
    'zh-tw': '[$-404]e"年"m"月"d"日"',
    'zh-cn': 'm"月"d"日"',
    'ja-jp': '[$-411]ggge"年"m"月"d"日"',
    'ko-kr': 'mm-dd',
  },
  55: {
    'zh-tw': '上午/下午 hh"時"mm"分"',
    'zh-cn': '上午/下午 h"时"mm"分"',
    'ja-jp': 'yyyy"年"m"月"',
    'ko-kr': 'yyyy-mm-dd',
  },
  56: {
    'zh-tw': '上午/下午 hh"時"mm"分"ss"秒"',
    'zh-cn': '上午/下午 h"时"mm"分"ss"秒"',
    'ja-jp': 'm"月"d"日"',
    'ko-kr': 'yyyy-mm-dd',
  },
  57: {
    'zh-tw': '[$-404]e/m/d',
    'zh-cn': 'yyyy"年"m"月"',
    'ja-jp': '[$-411]ge.m.d',
    'ko-kr': 'yyyy"年" mm"月" dd"日"',
  },
  58: {
    'zh-tw': '[$-404]e"年"m"月"d"日"',
    'zh-cn': 'm"月"d"日"',
    'ja-jp': '[$-411]ggge"年"m"月"d"日"',
    'ko-kr': 'mm-dd',
  },

  59: {'th-th': 't0'},
  60: {'th-th': 't0.00'},
  61: {'th-th': 't#,##0'},
  62: {'th-th': 't#,##0.00'},
  67: {'th-th': 't0%'},
  68: {'th-th': 't0.00%'},
  69: {'th-th': 't# ?/?'},
  70: {'th-th': 't# ??/??'},

  81: {'th-th': 'd/m/bb'},
};

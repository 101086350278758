import React, { useRef, useEffect, useState } from 'react';


import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { alterAttack, alterManagedGame, alterSpecificGamePoint } from '../../store/schemas/gameSlice';
import { alterNotification } from '../../store/schemas/notificationSlice';
import MiniMap from '../../components/minimap';
import { shuffle } from 'shuffle-seed';
import { alterMiniMapAttack } from '../../store/schemas/miniMapSlice';

const Sheet = (prop) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tempgame = useSelector(state => state.mainapp.gameSlice.gameManager);
    const [triggerAnimation, settriggerAnimation] = useState(false);
    const [currentDaySelection, setCurrentDaySelection] = useState(1);
    const dayMax = 27;
    const attackData = useSelector((state) => state.mainapp.gameSlice.gameManager.attack)
    const [pageReady, setPageReady] = useState(false);
    const userUid = useSelector(state => state.auth.userInfo.uid)

    useEffect(() => {
        if (prop.type === "attack") {
            document.getElementById("play-zone").classList.add("extented-play");
            setPageReady(true);

        } else {


            setPageReady(true);
            document.getElementById("play-zone").classList.remove("extented-play");
        }

    }, [prop.type]);

 
    useEffect(() => {
        if(triggerAnimation){
            let count = 0
            document.getElementById('prev-day-btn').click()
            const timer = () => {
                setTimeout(() => {
                    document.getElementById('next-day-btn').click()
                    count++
                    if(count < 27){
                        timer()
                    }
                    else{
                        settriggerAnimation(false)
                        setCurrentDaySelection(1)

                    }
                }, 70)
            }
            timer()
        }


    }, [triggerAnimation])



    const genValues = (letter, min, max) => {
        let arr = []
        let i = min - 1
        for (i; i < max; i++) {
            arr.push({value:letter + (i + 1)})
        }
        return arr
    }



    const autoPlace = () => {

        let genArr = [...genValues('A', 1, 9), ...genValues('B', 1, 9), ...genValues('C', 1, 9)]
        const random_order_no_day = shuffle(genArr, userUid + Date.now())
        const random_order_fixed = random_order_no_day.map((item, index) => ({value:item.value}))
        const random_order_day = random_order_no_day.map((item, index) => ({value:[item.value, index - 1], type:"attack", index:currentDaySelection - 1}))
        // setData(random_order_fixed)
        dispatch(alterAttack(Array.from({ length: 27 }, () => ({ value: null }))))
        setTimeout(() =>{
            dispatch(alterAttack(random_order_fixed))
            setCurrentDaySelection(1)
            settriggerAnimation(true)
            dispatch(alterMiniMapAttack(random_order_day))
           
        }, 300)
        setTimeout(() => {
            dispatch(alterNotification( {alertStyle: 'success', messageTitle: 'Auto Place Complete', message: 'Your points have been randomly placed.', visible: true} )) ;
        }
            , 2400)
    }

    return (
        <>



            {
                prop.type === "attack" ? <MiniMap autoplaceFunction={autoPlace} /> : ""
            }




            <div className='game-sheet rainbow defend-img ' >
                <div className='top-strip vivify fadeIn duration-300'>
                    <span>A</span>
                    <span>B</span>
                    <span>C</span>
                </div>
                <div className='sheet-zone vivify fadeIn duration-300'>
                    <div className='side-strip'>
                        <span>1</span>
                        <span>2</span>
                        <span>3</span>
                        <span>4</span>
                        <span>5</span>
                        <span>6</span>
                        <span>7</span>
                        <span>8</span>
                        <span>9</span>
                    </div>

                    <form id={"sheet-form" + prop.type}  >
                        <button style={{ display: "none" }} type='submit' id='submit-btn'></button>
                        <div className='sheet-grid vivify fadeIn duration-300'>

                            {
                                ['A','B','C'].map((char) => {
                                    return(
                                        <div className={char.toLowerCase() + '-column column'}>
                                        {
                                            [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                                return (
        
                                                    <div className='grid-cell' >
                                                        <input onClick={
                                                            (e) => {
                                                                let str = e.target.id;
                                                                let index = str.indexOf(prop.type);
                                                                let result = str.substring(0, index);
                                                                if(prop.type == "attack"){
                                                                    dispatch(alterSpecificGamePoint({value:result, type:"attack", index:currentDaySelection - 1}))

                                                                }
                                                                else{
                                                                    dispatch(alterSpecificGamePoint({value:result, type:"defend"}))
                                                                }
                                                                
                                                                // setClickCount(clickCount + 1);
                                                            }
                                                        } type='radio' id={char + num + prop.type} name='sheetGrid' />
                                                        <label id={`${char + num + prop.type}ghost`} for={`${char + num + prop.type}`}>
                                                            <p id={`${char + num + prop.type}Track`}>0</p>
                                                            <span>    <div className='back-sheet'><p>{"Day " + currentDaySelection}</p></div></span>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    )
                                })
                            }
                           

                    






                        </div>
                        {
                            prop.type === "attack" ? (

                                <div className='sheet-date'>

                                    <div className='day-prev sheet-stepper'>
                                        <button id='prev-day-btn' onClick={
                                            (e) => {

                                                e.preventDefault();
                                                if (currentDaySelection > 1) {
                                                    document.getElementById("sheet-form" + prop.type).reset()
                                                    setCurrentDaySelection(currentDaySelection - 1);
                                                    if(attackData[(currentDaySelection - 2)].value != null){
                                                        document.getElementById(attackData[(currentDaySelection - 2)].value + "attack").checked = true
                                                    }

                                                }
                                                else{
                                                    document.getElementById("sheet-form" + prop.type).reset()
                                                    if(attackData[(currentDaySelection - 1)].value != null){
                                                        document.getElementById(attackData[(currentDaySelection - 1)].value + "attack").checked = true
                                                    }
                                                }
                                            }
                                        } type='button' className='btn btn-primary'><i className='material-icons'>chevron_left</i>Previous Day</button>

                                    </div>
                                    <div className='sheet-desc' id='currentDayContent'>
                                        {attackData.map((day, index) => (
                                            currentDaySelection === index + 1 ? (
                                                <div key={index}>
                                                    <p>  Day {index + 1} :  {day.value != null ? day.value.substring(0, 2) : " Not Selected"}
                                                    </p>
                                                    {
                                                     
                                                    }

                                                    {

                                                    }
                                                </div>
                                            ) : null
                                        ))}

                                    </div>
                                    <div className='day-next sheet-stepper'>
                                        <button id='next-day-btn' onClick={
                                            (e) => {
                                                e.preventDefault();

                                                if (currentDaySelection < dayMax) {
                                                    document.getElementById("sheet-form" + prop.type).reset()
                                                    setCurrentDaySelection(currentDaySelection + 1);
                                                    if(attackData[(currentDaySelection)].value != null){
                                                        document.getElementById(attackData[(currentDaySelection)].value + "attack").checked = true
                                                    }
                                                   
                                                }

                                            }
                                        } type='button' className='btn btn-primary'>Next Day<i className='material-icons'>chevron_right</i></button>
                                    </div>

                                </div>


                            ) : null



                        }

                    </form>


                </div>
            </div>

        </>
    );

}

export default Sheet;
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createContract } from '../helper-functions/contract'
import { loadingReset, setLoadingSlice, setNavigationRedirect } from '../store/schemas/loadingSlice'
import { alterNotification } from '../store/schemas/notificationSlice'
import { getUser } from './authThunk'
import { alterNavigate } from '../store/schemas/helperFunctionsSlice'
import { checkIfGameTypeOpen, getUsersLobbiesInfo } from './gameThunk'


const server = process.env.REACT_APP_DOMAIN


  export const validateUsername = createAsyncThunk(
    'shop/validateUsername',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=shop&path=/validateUsername`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({username:data.username})               
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            thunkApi.dispatch(setLoadingSlice('MetaMask Approval...'))

            const contract = await thunkApi.dispatch(createContract({item:data.item_id, url:'/shop'}))   
            if("error" in contract.payload ){
                throw contract.payload
            }
          
            thunkApi.dispatch(setLoadingSlice('Purchasing Username...'))

            const purchaseUsername = await fetch(`${server}?auth=true&model=shop&path=/purchaseItem`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({transaction_id: contract.payload.transaction_id, item_id: data.item_id})               
            })
            const purchaseResults = await purchaseUsername.json()
            if("error" in purchaseResults ){
                throw purchaseResults
            }
            thunkApi.dispatch(getUser())
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNavigate({url:'/shop', replace:true}))
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'success', messageTitle: "Username Purchase", message: "Your username purchase was a success." ,visible: true}))
            return purchaseResults
        } catch (error) {
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )



  export const purchaseItem = createAsyncThunk(
    'shop/purchaseItem',
    async (data, thunkApi) => {
        try {
            if(data.item_id == 6){
                thunkApi.dispatch(setLoadingSlice('Checking the Waitlist...'))
                const res = await thunkApi.dispatch(checkIfGameTypeOpen({checkIfGameTypeOpen:'survivor_no_house'}))
                if ("error" in res.payload) {
                  throw res.payload
                }
            }
           

            thunkApi.dispatch(setLoadingSlice('MetaMask Approval...'))

            const contract = await thunkApi.dispatch(createContract({item:data.item_id, url:data.url, bot_uid:data.bot_uid}))   
            if("error" in contract.payload ){
                throw contract.payload
            }
          
            await thunkApi.dispatch(setLoadingSlice('Processing and Validating Payment...'))
            const listenResponse = await fetch(`${server}?auth=true&model=shop&path=/purchaseItem`, {
                method:"POST",
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({transaction_id: contract.payload.transaction_id, item_id: data.item_id})
            })
            const listenResult = await listenResponse.json()
            if("error" in listenResult){
                throw listenResult
            }
            thunkApi.dispatch(getUser())
            if(data.item_id == 6){
                await thunkApi.dispatch(getUsersLobbiesInfo())
            }
            await thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle:  'success', messageTitle: 'Payment Success', message: 'Your recent payment was a success!' ,visible: true}))
            await thunkApi.dispatch(loadingReset())
            await thunkApi.dispatch(alterNavigate({url:data.url, replace:true}))

        } catch (error) {
            thunkApi.dispatch(loadingReset())
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: error.error, message: error.message ,visible: true}))
            if(error.code == "000005" || error.code == "000006"){
                thunkApi.dispatch(alterNavigate({url:'/', replace:true}))
            }
      

            return error
        }
    }
  )


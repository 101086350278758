import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Outlet } from 'react-router-dom';

import SubNavBar from '../../subNavbar';
const ShopModal = () => {

  const navigate = useNavigate();


  return (
    <>


        <Outlet />
    

    
      <style>
        {`
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}

        .sub-navbar-wrapper {
            position: static;
        }
        `}
      </style>

    </>
  );

}

export default ShopModal;
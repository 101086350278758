import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import SubNavBar from '../../subNavbar';
const AutoPlace = () => {

  const [agreeStatus, setAgreeStatus] = useState(false);
  const navigate = useNavigate();


  return (
    <>

      <div className='legal-text'>
        
        <p>Auto Place is a feature in our game that allows users to have their defense and attack points placed randomly on their behalf, using a cryptographic algorithm API called "Shuffle Seed" and their crypto wallet ID. To ensure transparency and fairness, we offer a validation system that allows users to use code we provide to replicate the result of the algorithm. Users can validate the results themselves.</p>

        <p>

At 0XBullzeye, we are committed to providing a transparent and trustworthy experience for our users. Our validation system is designed to reinforce this commitment. We understand that fairness is critical in a game that uses crypto currency, and we are proud to offer a feature that empowers our users to validate the results of the algorithm for themselves. We believe that Auto Place, along with our validation system, enhances the overall experience of the game and ensures that all users have an equal opportunity to succeed.

        </p>

       
        <div className='code-view'>
        <p>npm install shuffle-seed</p>
       <textarea>
        {
        `import { shuffle } from 'shuffle-seed'; 
        let genArr = [...genValues('A', 1, 9), ...genValues('B', 1, 9), ...genValues('C', 1, 9)] 
        const random_order_no_day = shuffle(genArr, userUid + Date.now())
        const random_order_fixed = random_order_no_day.map((item, index) => ({value:item.value}))`
        }
        </textarea>

     </div>
     

      </div>

    
      <style>
        {`
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}
        `}
      </style>

    </>
  );

}

export default AutoPlace;
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from "react-router-dom";
import quickplayimg from '../../../assets/homBackground.png';
import Loader from '../../../components/loader';
import { checkIfGameTypeOpen } from '../../../httpcalls/gameThunk';
import { alterGameType } from '../../../store/schemas/gameSlice';
import { loadingReset, setLoadingSlice } from '../../../store/schemas/loadingSlice';
import survivorImg from '../../../assets/survivor.gif';

import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';

const M_QuickPlay = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const autoplay = useRef(Autoplay({ delay: 5000, loop: true }));

    


    useEffect(() => {
    
        if (localStorage.getItem('update1') === 'true') {

        } else {
            localStorage.setItem('update1', 'true')
            navigate('/play/update')
        }
    }, [])
    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
            <div className='side-content'>




                <div className='block-layout' >
                    {/*           <div className='block-nav'>

<div className='block-alert vivify fadeIn duration-300 delay-350'>
    <i className='material-icons-outlined'>new_releases</i>
    <p>New Game Mode: Coming Soon Into Rotation. Stay tuned for more information about QUICK PLAY: SURVIVOR </p>
</div>



</div> */}
                    <div style={
                        {
                            backgroundColor: "var(--frostedBackground)",
                            backdropFilter: "blur(10px)",
                            padding: "0px",
                            height: "298px",
                            justifyContent: "inherit",
                            flexDirection: "row-reverse",

                        }
                    } className='hero-block' >

                        <Carousel  plugins={[autoplay.current]}
                        
                            className='block-carousel' maw={680} width={50} loop withIndicators withControls={false} height={296}>


                            <Carousel.Slide>
                                <div className='slim-blocks'>

                                    <div onClick={
                                        () => {
                                            navigate('/play/update')
                                        }
                                    } className='slim-block vivify fadeIn duration-300 delay-200 colored-slimblock' style={
                                        {
                                            backgroundImage: `url(${survivorImg})`,

                                        }
                                    }>
                                        <div className='slim-title'>
                                            <i style={
                                                {
                                                    color: "var(--light-text)"
                                                }
                                            } className='material-icons'>toll</i>
                                            <p >All Rewards Are Yours</p>
                                            <h2 >0 BUSD</h2>

                                            <span >{/* <i className='material-icons'>fiber_new</i> */}Enlistment Fee</span>
                                        </div>
                                   
                                    </div>
                                    <div className='slim-block vivify fadeIn duration-300 delay-200' style={
                                        {
                                            /*   backgroundImage: `url(${survivorImg})`,
                                              backgroundSize: "cover",
                                              filter: "hue-rotate(68deg)", */

                                        }
                                    }>
                                        <div className='slim-title'>
                                            <i className='material-icons'>hotel_class</i>
                                            <p >Make It To The End</p>
                                            <h2 >10.00 BUSD</h2>

                                            <span >{/* <i className='material-icons'>fiber_new</i> */}Survivor Bonus</span>
                                        </div>

                                    </div>
                                    <div className='slim-block'>
                                        <div className='slim-title'>
                                            <i className='material-icons'>auto_graph</i>
                                            <span>Currently</span>
                                            <h2>10.00 BUSD/Kill</h2>
                                            <p>Potential Earnings</p>
                                        </div>

                                    </div>




                                </div>
                            </Carousel.Slide>


                            <Carousel.Slide>
                                <div className='slim-blocks'>

                                    <div className='slim-block'>
                                        <div className='slim-title'>
                                            <i className='material-icons'>attach_money</i>
                                            <span>Currently</span>
                                            <h2>10.00 BUSD</h2>
                                            <p>Entry Fee</p>
                                        </div>

                                    </div>

                                    <div className='slim-block'>
                                        <div className='slim-title'>
                                            <i className='material-icons'>groups_2</i>
                                            <span>Up To</span>
                                            <h2>27 vs 27</h2>
                                            <p>Potential Players</p>
                                        </div>

                                    </div>

                                </div>
                            </Carousel.Slide>


                        </Carousel>



                        <div className='block-charm' style={
                            {
                                width: "fit-content",
                            }
                        }>
                            <div className='charm-text'>
                                <h4>BUSD <span>10.00</span></h4>
                                <h2>{/* <i className='material-icons'>fiber_new</i> */}Quick <span>Play</span> </h2>
                                <span>Survivor</span>
                                <p>Join the battle for rewards, fight up to 27 players for 28 days and Get 10 Bonus BUSD for surviving.</p>
                            </div>
                            <div className='charm-button'>
                                <button className='btn btn-primary' onClick={
                                    () => {
                                        dispatch(setLoadingSlice('Loading Game...'));

                                        dispatch(checkIfGameTypeOpen({ gameType: "default" })).then((res) => {
                                            dispatch(loadingReset())
                                            if (!("error" in res.payload)) {
                                                dispatch(alterGameType("default"))
                                                navigate('/playing')
                                            }
                                        })


                                    }
                                }>Play Now</button>
                                <button onClick={
                                    () => {
                                        localStorage.setItem('agreeStatus', 'false');
                                        window.location.reload();
                                    }
                                }>
                                    Disclaimer
                                </button>
                            </div>
                        </div>
                    </div>




                    <p>
                        Click 'Play Now' to enter the battle where you'll fight against up to 27 players for a maximum of 28 days, earning rewards based on your kills which can be claimed on the day you die or the day the last enemy is killed.
                    </p>

                    <p>
                        Earn BUSD 10.00 per kill (subject to change) in our exhilarating game, regardless of winning or losing the battle!
                    </p>

                </div>






            </div>
            <style>
                {`
      .slim-title {
        height: 100%;
      }

      .slim-block {cursor:grab}

      .slim-block:active {cursor:grabbing}

      .charm-text > h2 {
        font-size: 1.5rem;
        line-height: 30px;
        display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: var(--light-text);
      }

      .charm-text > h4 {
        color: var(--light-text);
      }

      .charm-text > h2 > span {

    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--light-text);
      }

      .charm-text > h2 > i {
        color: var(--light-text);
      }



      .slim-title > span {
        display:flex;
        align-items: center;
        justify-content: center;
        gap: 4px
      }

      .slim-title >span > i {
        font-size: 20px;
        color: var(--light-text);
      }

      .slim-blocks {height: 100%;}

      .slim-block {height: 258px}
    

        `}
            </style>
        </>
    );

}

export default M_QuickPlay;
import {createSlice} from "@reduxjs/toolkit";

{
    /*
       
        alertStyle: can be 'warning', 'error', 'success', 'info', 'help'
        messageTitle: title of the notification
        message: message of the notification
        visible: boolean, true means the notification is visible, false means it is not visible


        Create Open Notification
        dispatch(alterNotification( {alertStyle: "success",  messageTitle: 'Auto Place Complete', message: 'Your points have been randomly placed in their slots. You can confirm and continue to matchmaking', visible: true} )) ;

        Close Notification
        dispatch(alterNotification( {visible: false} )) ;
    */
}

const initialState = () => ({notifications: {autoClose: 0}, id: Math.random()});

const notificationSlice = createSlice({
    name: 'Notification Manager',
    initialState: initialState(),
    reducers: {
        resetNotifications: state => initialState(),
        alterNotification: (state, action) => {
            state.notifications = action.payload
            state.id = Math.random()
        },
    }
})

export const {
    resetNotifications,
    alterNotification,
    
   
} = notificationSlice.actions

export default notificationSlice.reducer
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { logout } from "../httpcalls/authThunk";
import Navbar from "../components/navbar";
import { alterNotification } from "../store/schemas/notificationSlice";

import { useEffect } from "react";
import Loader from "../components/loader";
import NavigateFromThunk from "../components/naivigateFromThunk";

const MainDirectory = () => {
  const dispatch = useDispatch()
  const messageTitle = useSelector(state => state.mainapp.notificationSlice.notifications.messageTitle)
  const message = useSelector(state => state.mainapp.notificationSlice.notifications.message)
  const alertStyle = useSelector(state => state.mainapp.notificationSlice.notifications.alertStyle)
  const autoClose = useSelector(state => state.mainapp.notificationSlice.notifications.autoClose)
  const visible = useSelector(state => state.mainapp.notificationSlice.notifications.visible)
  const id = useSelector(state => state.mainapp.notificationSlice.id)


  // change root variable to change the color of the notification

 


  useEffect(() => {

    document.getElementById('notif-container').style.display = 'flex'
    if(message == undefined){
      document.getElementById('notif-container').style.display = 'none'
      
    }
    else{
      if(autoClose !== 0) {
        const timeoutID = setTimeout(() => {
          document.getElementById('notif-container').style.display = 'none'
        }, autoClose)
      }else {
     
      }
    }
    
  }, [message])

  useEffect(() => {
    if(visible === true) {
      document.getElementById('notif-container').style.display = 'flex'
    }else {
      document.getElementById('notif-container').style.display = 'none'
    }
  }, [visible])


  useEffect(() => {

    document.getElementById('notif-container').style.display = 'none';
    if(message != undefined){
      setTimeout(() => {
        document.getElementById('notif-container').style.display = 'flex'
      }, 10)
    }
  
  
  }, [id])



  useEffect(() => {
    if (alertStyle === 'warning') {
      document.documentElement.style.setProperty('--notif-accent', 'var(--alt-color2)')
    } else if (alertStyle === 'error') {
      document.documentElement.style.setProperty('--notif-accent', 'var(--alt-color4)')
    } else if (alertStyle === 'success') {
      document.documentElement.style.setProperty('--notif-accent', 'var(--alt-color)')
    } else if (alertStyle === 'info') {
      document.documentElement.style.setProperty('--notif-accent', 'var(--main-color)')
    } else if (alertStyle === 'help') {
      document.documentElement.style.setProperty('--notif-accent', 'white')
    }


  }, [alertStyle])
  

    return (
      <>
{/* Notification System */}
      <Loader />
      <NavigateFromThunk />
      <div id="notif-container" className={"notif-container vivify fadeInBottom duration-400 " + alertStyle }>
        <div className="notif-icon">
          <i className="material-icons">{
            alertStyle === 'warning' ? 'warning' :
            alertStyle === 'error' ? 'error' :
            alertStyle === 'success' ? 'check_circle' :
            alertStyle === 'info' ? 'info' :
            alertStyle === 'help' ? 'help' :
            'error'

          }</i>
          </div>
          <div className="notif-text">
            <div className="notif-title">
            <h2>{alertStyle}</h2>
           <button onClick={
              () => {
             
             
                setTimeout(() => {
                  document.getElementById('notif-container').style.display = 'none'
                }, 10)
              
              }
            }>
              <i className="material-icons" >close</i>
            </button> 
            </div>
            <h3>{messageTitle}</h3>
            <p>{message}</p>
          </div>
          </div>
         

            

    <Navbar />
  
    <Outlet />
  


    
      </>
    );
  }
  
export default MainDirectory;
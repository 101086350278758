import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    mainAccount: [],
    botAccounts: [],
    totalValue:0,
    time:null
})


const claimSlice = createSlice({
    name: 'Claiming Slice',
    initialState: initialState(),
    reducers: {
        resetClaim: state => initialState(),
        setClaimAll: (state, action) => {
            state.mainAccount = action.payload.mainAccount
            state.botAccounts = action.payload.botAccounts
            state.totalValue = action.payload.totalValue
            state.time = action.payload.time
        }
    }
})

export const { resetClaim, setClaimAll } = claimSlice.actions

export default claimSlice.reducer
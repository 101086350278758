import {createSlice} from "@reduxjs/toolkit";
/*

    filterLocation: can be {lat:x, long:y} data, or specify the county as a string. This will be managed on the backend.
    filters: [
        current widgets with there respective active filters
        {
            name:"Property Values",
            activeFilters: [
                {
                    name:"face Value",
                    type:"slider",
                    values: {min:0, max: 500}
                }
            ]
        }
    ]

*/

const initialState = () => ({gameType:"default", gameManager:{defend:null, attack:Array.from({ length: 27 }, () => ({ value: null }))}})

const gameSlice = createSlice({
    name: 'Game Manager',
    initialState: initialState(),
    reducers: {
        resetGameManger: state => initialState(),
        alterSpecificGamePoint: (state, action) => {
            if(action.payload.type == "defend"){
                state.gameManager.defend = action.payload.value
            }
            else{
                state.gameManager.attack[action.payload.index].value = action.payload.value
            }
        },
        alterAttack: (state, action) => {
            state.gameManager.attack = action.payload
        },
        alterManagedGame: (state, action) => {
            state.gameManager = action.payload
        },
        alterGameType: (state, action) => {
            state.gameType = action.payload
        },
    }
})

export const {
    resetGameManger,
    alterManagedGame,
    alterGameType,
    alterSpecificGamePoint,
    alterAttack
   
} = gameSlice.actions

export default gameSlice.reducer
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import skull from '../../../assets/skull.svg';
import { alterGameProgress } from '../../../store/schemas/gameProgressSlice';
import { Navigate, useNavigate, Outlet } from 'react-router-dom';
const Report = () => {

    const [agreeStatus, setAgreeStatus] = useState(false);
    const navigate = useNavigate();
    const progress = useSelector(state => state.mainapp.gameProgressSlice.gameProgressSlice)
    const lobbyBackground = useSelector(state => state.mainapp.gameProgressSlice.lobbyBackgroundInfo)
    const username = useSelector((state) => state.auth.userInfo.username)

    const [enemyTeamLength, setEnemyTeamLength] = useState(3);
    const [userTeamLength, setUserTeamLength] = useState(3);

    const [load, setLoad] = useState(true)
    const [yourTeam, setYourTeam] = useState(1)
    const [enemyTeam, setEnemyTeam] = useState(2)


    useEffect(() => {
        if (Object.keys(progress).length == 0) {
            navigate('/', { replace: true })
        }
        else {
            setLoad(false)
            const userInfo = progress.pastPlays[0].results.find((item) => item.username == username)
            if(userInfo.what_team == 'team2'){
                setYourTeam(2)
                setEnemyTeam(1)
            }
        }
      
    }, [])
    return (
        <>

            {(load == false) ?




                <div className='progress-container vivify fadeIn duration-300'>


                    <div className='left-gameBlock'>
                        <h4>Reward Progress</h4>
                        <div className='g-block'>
                     

{
                              (lobbyBackground.status == "taken") ?     <div className='pricing-block'>
                              <h5>Todays Rewards</h5>
                              <div className='reward-block'>
                                  <h3>BUSD</h3>
                                  <h2>{(progress.todaysBusd).toLocaleString("en-us", { style: "currency", currency: "USD" })}</h2>
                                  <p>{progress.todaysKills} Kills</p>
                              </div>
                              <p>May be subject to changes.</p>
                          </div> : null
                            }
                      

                            <div className='pricing-block'>
                                <h5>All Rewards</h5>
                                <div className='reward-block'>
                                    <h3>BUSD</h3>
                                    <h2>{
                                                        (() => {
                                                            const values = progress.gameModes.map((item) => item.amount == null ? 0 : parseFloat(item.amount.toFixed(2))) 
                                                            const sum = values.reduce((a, b) => a + b, 0).toLocaleString("en-us", { style: "currency", currency: "USD" })
                                                            return sum
                                                        })()
                                                    }
                                                        
                                                        
                                                        </h2>
                                    <p>{progress.totalKills} Kills</p>
                                </div>
                                <p>May be subject to changes.</p>
                            </div>

                        </div>
                        <h4>Rewards Breakdown</h4>
                        <div className='g-block'>
                            <div className='pricing-block'>
                            {
                                progress.gameModes.map((item, index) => {
                                    console.log(progress.gameModes)

                                    return (
                                
                                 (
                                    item.amount !== null ? 
                                    <div className='reward-block'>
                                    <p>{item.type === 'survivor'? "Survivor Bonus: " : "Kill Pay: "
                                    
                                    } {(item.amount).toLocaleString("en-us", { style: "currency", currency: "USD" })}</p>
                                    </div> : null
                                 )
                                    )
                                })
                            }
                            </div>
                          
                        </div>

                        <h4>Game Progress</h4>
                        <div className='g-block'>
                            <div className='timeline'>
                                <div className='timeline-title'>
                                    <h3>Game Timeline <span>Day {progress.dayProgress - 1}</span></h3>


                                    <p className='progress-chip'><span>{
                                        progress.totalDeadPlayers
                                    }</span> Total Eleminated</p>

                                </div>

                                <div className='progress'>
                                    <div className='progress-bar'>
                                        <p>Day 1</p>
                                        <progress value={progress.dayProgress} max={progress.maxDayEnd}>
                                        </progress>
                                        <p>Day {progress.maxDayEnd}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='right-gameBlock'>
                        <h4>Team {yourTeam}</h4>
                        <div style={
                            {
                                gap: "0px"
                            }
                        } className='g-block compact-gblock'>
                            <div className='team-block'>
                                {
                                    progress.userTeam.slice(0, userTeamLength).map((item, index) => {
                                        return (
                                            <div className={
                                                item.dead ? 'team-member member-eliminated' : 'team-member'
                                            }>

                                                <div className='member-text'>
                                                    <div className='m-icons'>
                                                        <img src={"https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=" + item.userName}></img>
                                                    </div>
                                                    <div className='m-text'>

                                                        <h2>{
                                                            item.userName
                                                        }</h2>

                                                        {
                                                            item.dead ? <h3>Eliminated</h3> : null
                                                        }

                                                    </div>
                                                </div>
                                                <div className='member-stats'>
                                                    <div className='member-rewards'>
                                                    <h2>{
                                                        (() => {
                                                            const values = item.gameModes.map((item) => item.amount == null ? 0 : parseFloat(item.amount.toFixed(2))) 
                                                            const sum = values.reduce((a, b) => a + b, 0).toLocaleString("en-us", { style: "currency", currency: "USD" })
                                                            return sum
                                                        })()
                                                    }
                                                        
                                                        
                                                        </h2>
                                                        <h3>BUSD</h3>
                                                    </div>
                                                    <div className='member-kills'>
                                                        <img src={skull}></img>
                                                        <h2>{item.totalKills}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })
                                }
                            </div>
                            <div className='block-expand'>
                                <button onClick={
                                    () => {
                                        setUserTeamLength(prevNumber => prevNumber === 3 ? progress.userTeam.length : 3);
                                    }
                                }>

                                    {
                                        userTeamLength === 3 ? <i className='material-icons'>expand_more</i> : <i className='material-icons'>expand_less</i>

                                    }
                                    {
                                        userTeamLength === 3 ? <span>All Members</span> : <span>Less Members</span>
                                    }

                                </button>
                            </div>
                        </div>


                        <h4>Team {enemyTeam}</h4>
                        <div style={
                            {
                                gap: "0px",
                                marginBottom: "20px"
                            }
                        } className='g-block compact-gblock'>
                            <div className='team-block'>
                                {
                                    progress.enemyTeam.slice(0, enemyTeamLength).map((item, index) => {
                                        return (
                                            <div className={
                                                item.dead ? 'team-member member-eliminated' : 'team-member'
                                            }>

                                                <div className='member-text'>
                                                    <div className='m-icons'>
                                                        <img src={"https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=" + item.userName}></img>
                                                    </div>
                                                    <div className='m-text'>

                                                        <h2>{
                                                            item.userName
                                                        }</h2>

                                                        {
                                                            item.dead ? <h3>Eliminated</h3> : null
                                                        }

                                                    </div>
                                                </div>
                                                <div className='member-stats'>
                                                    <div className='member-rewards'>
                                                        
                                                        <h2>{
                                                        (() => {
                                                            const values = item.gameModes.map((item) => item.amount == null ? 0 : parseFloat(item.amount.toFixed(2))) 
                                                            const sum = values.reduce((a, b) => a + b, 0).toLocaleString("en-us", { style: "currency", currency: "USD" })
                                                            return sum
                                                        })()
                                                    }
                                                        
                                                        
                                                        </h2>
                                                        <h3>BUSD</h3>
                                                    </div>
                                                    <div className='member-kills'>
                                                        <img src={skull}></img>
                                                        <h2>{item.totalKills}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })
                                }
                            </div>
                            {
                                progress.enemyTeam.length > 3 ?
                                <div className='block-expand'>
                                <button onClick={
                                    () => {
                                        setEnemyTeamLength(prevNumber => prevNumber === 3 ? progress.enemyTeam.length : 3);
                                    }
                                }>

                                    {
                                        enemyTeamLength === 3 ? <i className='material-icons'>expand_more</i> : <i className='material-icons'>expand_less</i>

                                    }
                                    {
                                        enemyTeamLength === 3 ? <span>All Members</span> : <span>Less Members</span>
                                    }

                                </button>
                            </div>
                            :
                            ""

                            }
                           
                        </div>

                    </div>
                </div>
                :
                ""
            }




        </>
    );

}

export default Report;
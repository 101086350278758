import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <MantineProvider withGlobalStyles withNormalizeCSS>
    <Provider store={store}>
      <BrowserRouter>

        <App />

      </BrowserRouter>

    </Provider>
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from "react-router-dom";
import BackTitle from './backTitle';
import { useSelector } from 'react-redux';

const SubNavBar = (props) => {
    const [currentPage, setCurrentPage] = useState('');
    const links = props.links;
    const currentBot = useSelector(state => state.mainapp.automateSlice.currentBot);

    const [currentSetupStatus, setCurrentSetupStatus] = useState(currentBot.setupStatus);
    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const threshold = 0; // Set your threshold here
            if (window.pageYOffset > threshold) {
                setHasScrolled(true);
            } else {
                setHasScrolled(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  





    return (
        <>
            <div className={
                hasScrolled ? 'sub-navbar-wrapper vivify fadeIn duration-300 delay-100 scrolled-sub ' : 'sub-navbar-wrapper vivify fadeIn duration-300 delay-100  '
            } id='sub-navbar-wrapper'>
                <div className='sub-navbar'>
                  

                    {links.map((link) => (
                        <NavLink to={link.link} replace={true} className={(window.location.pathname == link.link) ? "focused-sub" : "inactive-sub"}  >
                            <div className='sub-navbar-item '>
                                <p>{
                                     currentSetupStatus & link.name === "Setup" ? 
                                        <i className='material-icons'>settings_suggest</i> :
                                      ""
                                    }{link.name}</p>
                            </div>
                        </NavLink>
                    ))}




                </div>

                <BackTitle title={currentPage} />
            </div>

        </>
    );

}

export default SubNavBar;
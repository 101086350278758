import React, { useRef, useEffect, useState } from 'react';
import "../css/entry.css";
import WelcomeBackPlate from "../assets/backplates/WelcomeBackPlate.png";
import PlayBackPlate from "../assets/backplates/PlayBackPlate.png";
import ActiveGamesBackPlate from "../assets/backplates/ActiveGamesBackPlate.png";
import AttackBackPlate from "../assets/backplates/AttackBackPlate.png";
import DefendBackPlate from "../assets/backplates/DefendBackPlate.png";
import ShopBackPlate from "../assets/backplates/ShopBackPlate.png";
import AutomateBackPlate from "../assets/backplates/AutomateBackPlate.png";
import LeaderboardBackPlate from "../assets/backplates/LeaderboardBackPlate.png";

const BackPlate = (props) => {
    const [backplate, setBackplate] = useState(WelcomeBackPlate)
    

    const backplateMap = {
      entry: WelcomeBackPlate,
      play: PlayBackPlate,
      activeGames: ActiveGamesBackPlate,
      attack: AttackBackPlate,
      defend: DefendBackPlate,
      shop: ShopBackPlate,
      automate: AutomateBackPlate,
      leaderboard: LeaderboardBackPlate

    };
    
    useEffect(() => {
      
      const selectedBackplate = backplateMap[props.backplateType] || null;
      setBackplate(selectedBackplate);

    }, [])

  return (
    <>
      
      <div className='back-plate-container '>
        <img className='vivify fadeIn duration-300' src={backplate} alt='backplate' />
      </div>


    </>
  );

}

export default BackPlate;
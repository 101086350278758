import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { alterNotification } from '../store/schemas/notificationSlice';
import { current } from '@reduxjs/toolkit';
const ProgressMap = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const currentGame = useSelector(state => state.mainapp.gameSlice.gameManager);
    const pastPlays_team1 = useSelector((state) => state.mainapp.gameProgressSlice.gameProgressSlice.pastPlays_team1)
    const pastPlays_team2 = useSelector((state) => state.mainapp.gameProgressSlice.gameProgressSlice.pastPlays_team2)
   
    const [gameData, setGameData] = useState(pastPlays_team1)

    const [flipped, setFlipped] = useState(null)
    const [currentDay, setCurrentDay] = useState(1)


    const [itemIndex, setItemIndex] = useState(0);
    const dispatch = useDispatch();


    useEffect(() => {
        if (props.team === "team1") {
            setGameData(pastPlays_team1)
        }else if (props.team === "team2") {
            setGameData(pastPlays_team2)
        }
  
    }, [props.team])
   


    useEffect(() => {



        function handleScroll() {

            gameData.map((item) => {

                const element = document.getElementById(item.day + "currentDay");
               
                if (element) {
                    const rect = element.getBoundingClientRect();
                    if (
                        rect.top >= 0 &&
                        rect.bottom <= window.innerHeight
                    ) {
                        setCurrentDay(item.day);
                        myFunction(item.day);
                    }
                }
            })

        }



        document.getElementById('modalContainer').addEventListener("scroll", handleScroll);
    
        
    }, []);

    function myFunction(id) {
      
        document.getElementById('dayChip').innerHTML = "Day " + id;
        document.getElementById('miniMap').style.display = 'none'
        document.getElementById('miniMap').style.display = 'flex'

    }





    useEffect(() => {



        if (collapsed === true) {

            // duplicate checker



            let frequency = [];



            [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
/* 
   document.getElementById("miniA" + num).innerHTML = "";

                document.getElementById("miniB" + num).innerHTML = "";

                document.getElementById("miniC" + num).innerHTML = "";
 */
                document.getElementById("altMiniA" + num).innerHTML = "";

                document.getElementById("altMiniB" + num).innerHTML = "";

                document.getElementById("altMiniC" + num).innerHTML = "";

            /*     document.getElementById("miniA" + num).title = "";

                document.getElementById("miniB" + num).title = "";

                document.getElementById("miniC" + num).title = "";

                document.getElementById("miniC" + num).title = ""; */

                document.getElementById("holdA" + num).classList.remove("foc-active")

                document.getElementById("holdB" + num).classList.remove("foc-active")

                document.getElementById("holdC" + num).classList.remove("foc-active")

                document.getElementById("holdA" + num).classList.remove("foc-kill")

                document.getElementById("holdB" + num).classList.remove("foc-kill")

                document.getElementById("holdC" + num).classList.remove("foc-kill")
 


                /*    if (document.getElementById("miniA" + num).innerHTML === "1") {
                       document.getElementById("miniA" + num).classList.remove("selected-mini");
                   }
                   if (document.getElementById("miniB" + num).innerHTML === "1") {
                       document.getElementById("miniB" + num).classList.remove("selected-mini");
                   } if (document.getElementById("miniC" + num).innerHTML === "1") {
                       document.getElementById("miniC" + num).classList.remove("selected-mini");
                   } */
            })

            // for loop 7 times

            for (let i = 0; i < currentDay; i++) {
                let frequency = [];
                let duplicateCounts = {};
            
                gameData[(i)].results.map((item, index) => {
                    if (item.attack !== null) {
                        if (item.status.length > 1) {
                            // Increment duplicate count for the specific attack
                 
            
                            if ((item.status === "miss")) {
                            
                            } else {
                          
                                document.getElementById("altMini" + item.attack).innerHTML = "☠️ " +  " Multi Kill";
                                document.getElementById("hold" + item.attack).classList.add("foc-kill");

                                document.getElementById("hold" + item.attack).classList.add("foc-active");
                                document.getElementById("hold" + item.attack).title = item.status.join('\n')
                            }
                        } else {
                            if ((item.status === "miss")) {
                               
                            } else {
                           
                                document.getElementById("altMini" + item.attack).innerHTML = '☠️ Eiminated';
                                document.getElementById("hold" + item.attack).classList.add("foc-kill");

                                document.getElementById("hold" + item.attack).classList.add("foc-active");
                                document.getElementById("hold" + item.attack).title = item.status.join('\n')

                            }
            
                            frequency.push(item.attack);
                        }
                    }
                });
            }
            
            
    }

    }, [currentGame, collapsed, currentDay, gameData])

    const refreshAnimation = () => {
        document.getElementById('miniMap').style.display = 'none';
        setTimeout(() => {
            document.getElementById('miniMap').style.display = 'flex'
        }, 1)
    }



    return (
        <>


            <div className='minimap-container ' style={
                {
                    width: "336px"
                }
            } >

                <div className='minimap-wrapper ' style={
                    {
                        paddingTop: "17px"
                    }
                } >

                    {
                        collapsed ? <div className='minimap ' id='miniMap'>
                            <div className='minimap-column-a mini-column vivify fadeIn duration-500 delay-100' style={
                                {
                                    backgroundColor: "#32373d45"
                                }
                            }>
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                        return (
                                            <>
                                                <p id={"holdA" + num} style={{
                                                    height: "44px"
                                                }} title={"Empty"} >{/* <div id={"miniA" + num}></div>  */}  <span id={'altMiniA' + num}></span></p>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div className='minimap-column-b mini-column vivify fadeIn duration-500 delay-500' style={
                                {
                                    backgroundColor: "#32373d78"
                                }
                            }>
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                        return (
                                            <>
                                                <p id={"holdB" + num} style={{
                                                    height: "44px"
                                                }} title={"Empty"} >{/* <div id={"miniB" + num}></div>  */}  <span id={'altMiniB' + num}></span></p>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div className='minimap-column-c mini-column vivify fadeIn duration-500 delay-1000' style={
                                {
                                    backgroundColor: "#34393f57"
                                }
                            }>
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                        return (
                                            <>
                                                <p id={"holdC" + num} style={{
                                                    height: "44px",

                                                }} title={"Empty"} >{/* <div id={"miniC" + num}></div> */}   <span id={'altMiniC' + num}></span></p>

                                            </>
                                        )
                                    })
                                }
                            </div>


                        </div>
                            :
                            ""
                    }

                    <p id='duplicate-detected' className='vivify blink delay-100'>Hover over items on mini-map to view all duplicate points.</p>
                </div>
            </div>

            <style>
                {`
        .minimap-container {
            padding-right: 0px !important;
            margin-left: 0px !important;
            width: 320px
        }

        .minimap-wrapper {
            border: none;
        }

        .min-column {
            height: 40px
        }
        `}
            </style>
        </>
    );

}

export default ProgressMap;
import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Outlet } from 'react-router-dom';

import SubNavBar from '../../subNavbar';
const FairModal = () => {

  const navigate = useNavigate();


  return (
    <>

<SubNavBar links={
        [{
          'name': 'Auto Place',
          'link': '/fairness/autoplace'
        },
        {
          'name': 'Firing Order',
          'link': '/fairness/order'
        }
        ]
      }
      />
        <Outlet />
    

    
      <style>
        {`
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}

        .sub-navbar-wrapper {
            position: static;
        }
        `}
      </style>

    </>
  );

}

export default FairModal;
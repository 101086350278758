import { useEffect, useRef, useState } from "react";
import '../App.css';
import MetaMaskLogo from '../assets/metamask fox.svg';
import MetaMaskOnboarding from '@metamask/onboarding';
import Web3 from "web3";
import { useDispatch } from "react-redux";
import { login } from "../httpcalls/authThunk";

const Login = () => {
  const [buttonText, setButtonText] = useState("Download Metamask");
  const [isDisabled, setDisabled] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const onboarding = useRef();
  const [authHappening, setAuthHappening] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);



  useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
     
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      setAccounts((window.ethereum._state.accounts == null? [] : window.ethereum._state.accounts))
      return () => {
        window.ethereum.removeListener('accountsChanged', handleNewAccounts);
      };
    }
  }, []);
  
    useEffect(() => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        if (accounts.length > 0) {

          setButtonText("Authenticate With Metamask");
          if (window.ethereum && isDisabled == true && authHappening == false) {    
            setAuthHappening(true)
            const from = accounts[0];
            const msg = `Authenticate your account with our service.`;
            window.ethereum.request({
              method: 'personal_sign',
              params: [msg, from],
            }).then((res) => {
              setButtonText("Logging In...");
              setAuthHappening(false)
              dispatch(login({hash:res})).then(() => {
                setButtonText("Logged In");
                onboarding.current.stopOnboarding();
              })
            }).catch(res => {
              setAuthHappening(false)

              setButtonText("Authenticate With Metamask")
              setDisabled(false)
            });
          } 
          
        } else {
          setButtonText("Connect With Metamask");
        }
      }
    }, [accounts]);

  
    const loginWithMeta = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        if(isDisabled ==false){
          setDisabled(true)
          window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => setAccounts(newAccounts)).catch(res => {
            setDisabled(false)
          });
        }
      } else {
        onboarding.current.startOnboarding();
      }
    };

    return ( 
    
      <>    
        <div className="login-container">
          <button className="login-btn" disabled={isDisabled} onClick={() => {loginWithMeta()}}>
            <img src={MetaMaskLogo} alt="metamask logo" />
            <span>{buttonText}</span>
          </button>         
          <p>
            Always <span>Secure</span>. Always <span>Anonymous</span>.
          </p>
          </div>
      </>
    );
  }
  
  export default Login;
import React, { useRef, useEffect, useState } from 'react';
import BackPlate from "../../components/backplate";
import BackTitle from "../../components/backTitle";
import SubNavBar from '../../components/subNavbar';
import Footer from '../../components/footer';

import { Outlet, useNavigate } from 'react-router-dom';
const Shop = () => {
  const navigate = useNavigate();

  /* useEffect(() => {
    if (window.location.pathname === '/') {
    navigate('/play')
    }
  }, []) */
  return (
    <>
    
      <BackPlate backplateType="shop" />
      <div className='content-body'>
      <SubNavBar links={
        [{
          'name': 'Home',
          'link': '/shop'
        }
        ]
      }
      />
      <Outlet />
      </div>
      <div  className='lg-footer-container' >
        <Footer />
      </div>

      <style>
        {`
       :root {
        --main-color: var(--alt-color)
       }

   
        `}
      </style>
    </>
  );

}

export default Shop;
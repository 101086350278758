import React, { useRef, useEffect, useState } from 'react';
import "../css/entry.css";


const BackTitle = (props) => {
   
  return (
    <>
      
      <div className='back-title'>
        <h1>{props.title}</h1>
      </div>


    </>
  );

}

export default BackTitle;
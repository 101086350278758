import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';

const LeaderBoardStage = (props) => {
    const boardTop10 = useSelector(state => state.mainapp.leaderboardSlice.top10);

    {/* This File Should ONLY be accessed from the sideToolbar.js */}
    
    const topThree = [boardTop10[1], boardTop10[0], boardTop10[2]];

    return (
        <>
            <div className='stage-container'>
                {topThree.map((user, index) => (
                    <div key={index} className={`stage-item ${index === 1 ? 'top' : ''} vivify fadeInBottom delay-${(index+1)*100} duration-300`}>
                        <div className='stage-topper'>
                            <img src={`https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=${user.userName}`}></img>
                            <div className='stage-topper-text'>
                                <h2>{user.userName}</h2>
                                <h3>{user.totalRewards}</h3>
                            </div>
                            <div className='stage-lightbar' style={{backgroundColor: index === 1 ? "" : index === 0 ? "#BEBEBE" : "#CD7F32"}}></div>
                        </div>
                        <p>Ranked <span style={index === 1 ? {} : index === 0 ? {color: "#BEBEBE"} : {color: "#CD7F32"}}>#{user.rank}</span></p>
                    </div>
                ))}
            </div>
        </>
    );
}

export default LeaderBoardStage;

import { createAsyncThunk } from '@reduxjs/toolkit'
import { isLogin, isNotLogin, resetAuth, userInformation } from '../store/schemas/authSlice'
import { alterAllBots } from '../store/schemas/automateSlice'
import { alterNotification } from '../store/schemas/notificationSlice'
import { alterUserStatsActiveGames, alterUserStatsCompletedGames } from '../store/schemas/userStatsSlice'

const server = process.env.REACT_APP_DOMAIN
export const getUser = createAsyncThunk(
    'auth/getUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/getUser`, {
                method:'GET',
                credentials: 'include',
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            const bot_parsing = results.message.bot_accounts.map((item) => {
                return {botName: item.username, botNumber: item.uid}
            })
            thunkApi.dispatch(alterAllBots(bot_parsing))
            thunkApi.dispatch(userInformation(results.message))
            thunkApi.dispatch(isLogin())
        } catch (error) {
            console.log(error)
        }
    }
  )

    


  export const logout = createAsyncThunk(
    'auth/logout',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/logout`, {
                method:'GET',
                credentials: 'include',  
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
  )


  export const login = createAsyncThunk(
    'auth/login',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=false&model=auth&path=/authenticate_user`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({check:data.hash})
                
            })
            if(response.status != 500){
               thunkApi.dispatch(getUser())
            }
            else{
                console.log(await response.text())
                throw JSON.parse((await response.text()))
            }
        } catch (error) {
            console.log(error)
        }
    }
  )

  export const getUserStatsCompleted = createAsyncThunk(
    'auth/getUserStatsCompleted',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/getUserStatsCompleted`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }    
            
            const intervalMap = {
                '24 hours': 'today',
                '7 days': 'yesterday',
                '1 month': 'month',
                '1 year': 'year',
                'all time': 'allTime'
            };

            const fieldMap = {
                "Total Rewards": 'total_rewards',
                "Total Paid Fees": 'total_paid_fees',
                "Total Profit": 'profit',
                "Avg. Game Length (Day)": 'average_game_length',
                "Completed Games": 'completed_games',
            };
            
            // Prepare the template for the output
            const outputTemplate = {
                "Total Rewards": {},
                "Total Paid Fees": {},
                "Total Profit": {},
                "Avg. Game Length (Day)": {},
                "Completed Games": {}
            };
            
            // Transform the data
            const transformedData = Object.entries(outputTemplate).map(([dataType, values]) => {
                const fieldName = fieldMap[dataType];
                results.message.forEach(datum => {
                    const newInterval = intervalMap[datum.interval];
                    values[newInterval] = parseFloat(datum[fieldName]).toFixed(2);
                });
                return { dataType, ...values };
            });

            thunkApi.dispatch(alterUserStatsCompletedGames(transformedData))


            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )

  export const getUserStatsActive = createAsyncThunk(
    'auth/getUserStatsActive',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&model=user&path=/getUserStatsActive`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }    
            
            const fieldMap = {
                "Total Rewards": 'total_rewards',
                "Total Paid Fees": 'total_paid_fees',
                "Total Profit": 'profit',
                "Avg. Game Length (Day)": 'average_game_length',
                "Total Active Games": 'all_active_games'
            };
            
            // Transform the data
            let transformedData = Object.keys(fieldMap).map((key) => {
                return {
                    dataType: key,
                    data: parseFloat(results.message[fieldMap[key]]).toFixed(2) || '0.00'
                };
            });

            thunkApi.dispatch(alterUserStatsActiveGames(transformedData))

            return results
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 5000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
            return error
        }
    }
  )
import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Outlet } from 'react-router-dom';

import SubNavBar from '../../subNavbar';
import survivorImg from '../../../assets/survivor.gif';
const UpdateModal = () => {

  const navigate = useNavigate();


  return (
    <>

      <img className='back-img vivify duration-300 fadeIn delay-400' src={survivorImg}></img>

      <div className='block-charm vivify fadeIn duration-300 delay-600' style={
        {
          width: "fit-content",
        }
      }>
        <div className='charm-text'>

          <h2>Quick <span>Play</span> </h2>
          <span>Survivor</span>
          <blockquote >
            <p style={
              {
                backgroundImage: "url(" + survivorImg + ")",
              }
            } className='vivify blink delay-2000 duration-1200 '>NEW! 0 Enlistment Fee </p>
          </blockquote>
          <p>
Maximize returns! Every deposit in 0xBullzeye game is paid out as fee-free rewards.</p>
        </div>

      </div>

      <div className='promo-blocks'>
        <div>
          <h2>Current Game</h2>
          <p>All of the same game mechanics are still at play. You can still Earn BUSD 10.00 per kill (subject to change) in our exhilarating game, regardless of winning or losing the battle!  </p>
          <p> Quickplay: Survivor is also still in affect. Survive until the end of the game without being eliminated and earn 10.00 additional BUSD.</p>
        </div>

        <div>
          <h2>What's New?</h2>
          <p>Earn more from what you put in! All funds deposited into the game are payed out as rewards without any fees taken by 0xBullzeye.  </p>
          <p>
            Claim On! We've moved claiming rewards to the new rewards center in the navigation bar. You can claim your rewards at any time, and as often as you like.
            
           
          </p>



        </div>
      </div>



      <style>
        {`

        

        .block-charm {
          margin-bottom: 85px
        }
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}

        .sub-navbar-wrapper {
            position: static;
        }


        .modal-hero {
          display: none;
        }

        .modal-body {display: flex;justify-content: center;align-items: center;flex-direction: column;}

        .back-img {
          position: relative;
          margin-top: -66px;
          z-index: -1;
          filter: hue-rotate(31deg);
          height: 360px;
          object-fit: cover;
          width: 108%;
          opacity: 0.7 !important;
      }

      .promo-blocks > div {
  
        padding: 25px;
        border-radius: 15px;
      }

      .promo-blocks > div > h2 {
        color: var(--light-text);
        margin-bottom: 10px;
      }

      .promo-blocks > div > p {
        color: var(--light-text);
        font-size: 14px;
        line-height: 25px;
        margin-bottom: 10px
      }

      .promo-blocks > div > p:last-of-type {
        margin-bottom: 0px
      }

      .promo-blocks {
        display: flex;
    
        gap: 30px;
        margin-top: 30px;

      }

      .modal-container {
        height: fit-content;
    padding-bottom: 33px !important;
      }

      .block-charm {
        width: fit-content;
        transform: scale(1.25);
        margin-top: -227px;
    
      }

      .charm-text > p {
        max-width:fit-content;
        font-size: 12px
      }

      .modal-container {
        overflow: hidden;
      }

        .modal-header {position:relative; z-index:1}

        .charm-text > span {
          color:var(--light-text)
        }

        .massive-button-container {
          width: 100%;
        }

        .massive-button {
          box-shadow: var(--deepShadow);background-color: transparent;outline:var(--filledBorder);color: white;
        }
        `}
      </style>

    </>
  );

}

export default UpdateModal;
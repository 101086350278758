import Web3 from "web3"
import { loadingReset, setLoadingSlice } from "../store/schemas/loadingSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { alterNotification } from "../store/schemas/notificationSlice";
import { getUserClaimableAmount, listenToTransaction } from "../httpcalls/moneyThunk";
import { getUsersLobbiesInfo } from "../httpcalls/gameThunk";
import { alterNavigate } from "../store/schemas/helperFunctionsSlice";
import { botPayout, getBotLobbies } from "../httpcalls/botThunk";
import { resetClaim } from "../store/schemas/claimSlice";

const server = process.env.REACT_APP_DOMAIN


export const createContract = createAsyncThunk(
  'monitization/createContract',
  async (data, thunkApi) => {
      try {
        const net = await checkNetwork()
        if(net == true){
          thunkApi.dispatch(setLoadingSlice('Checking previous pending transactions...'))

              const res = await fetch(`${server}?auth=true&model=money&path=/generatePendingTransaction`, {
                method:"POST",
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({item:data.item, bot_uid:(data.bot_uid == undefined) ? null : data.bot_uid})
            })

            const result = await res.json()

            if("error" in result){
                throw result
            }
            const dataObject = {
              transact_id: result.message.transaction_id
            };

            thunkApi.dispatch(setLoadingSlice('Awaiting Allowance...'))



            const web3 = new Web3(window.ethereum);

            // ADDRESSES
            const senderAddress = window.ethereum.selectedAddress;

            const smartContractAddress = process.env.REACT_APP_SMART_CONTRACT_ADDRESS_PAYMENT
            const busdAddress = process.env.REACT_APP_BUSD

            const contractAbi = JSON.parse(process.env.REACT_APP_SMART_CONTRACT_ABI_PAYMENT)
            const busdAbi = JSON.parse(process.env.REACT_APP_BUSD_ABI)

            // MONEY

            const totalAmount = await web3.utils.toWei(result.message.amount.toString(), "ether");


            const playWallet = process.env.REACT_APP_GAMEWALLET
            const playerAmountRaw = ((result.message.amount * parseFloat(result.message.player_reward_wallet)).toFixed(2)).toString()
            const playerAmount = await web3.utils.toWei(playerAmountRaw, "ether");


            const revenueWallet = process.env.REACT_APP_TREASURY_PUBLIC
            const revenueAmountRaw = ((result.message.amount * parseFloat(result.message.revenue_wallet)).toFixed(2)).toString()
            const revenueAmount = await   web3.utils.toWei(revenueAmountRaw, "ether");
            
            
            const sendBUSDWithDataContract = new web3.eth.Contract(contractAbi, smartContractAddress);

            const busdTokenContract = new web3.eth.Contract(busdAbi,busdAddress );


            const userBalance = await busdTokenContract.methods.balanceOf(senderAddress).call();
            // Check if the user has enough tokens
            if (BigInt(userBalance) >= BigInt(totalAmount)) {
              const allowance = await busdTokenContract.methods
              .allowance(senderAddress, smartContractAddress)
              .call();

              const createPayment = async () => {
                  thunkApi.dispatch(setLoadingSlice('Waiting for Payment...'))
                  const dataString = JSON.stringify(dataObject);
                  const datatest = web3.utils.asciiToHex(dataString);
                  const tx = {
                    from: senderAddress,
                    to: smartContractAddress,
                    maxPriorityFeePerGas: null,maxFeePerGas: null, 
                    data: sendBUSDWithDataContract.methods.sendBUSDWithData(datatest, revenueWallet, revenueAmount, playWallet, playerAmount).encodeABI()
                };

                try {
                  const txHash = await ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [tx]
                  });
                    return {
                      transaction_id:dataObject.transact_id,
                      url:data.url
                    }
                } catch (err) {
                  const error = {error:"Canceled Transaction", message:"The transaction was canceled from Metamask."}
                  return error
                }
                
              }

              if(allowance < totalAmount){
                const approveAllowancePromise = async () => {
                  return new Promise(async (re,rej) => {
                    await busdTokenContract.methods.approve(smartContractAddress, totalAmount).send({ from: senderAddress,    maxPriorityFeePerGas: null,maxFeePerGas: null })
                    .on('transactionHash', async () => {
                      thunkApi.dispatch(setLoadingSlice('Processing Allowance...'))
                    })
                    .on('receipt', async () => {
                      console.log("Fail")

                        const result = await createPayment()
                        re(result)
                    }).catch(error => {
                      rej({error:"Canceled Transaction", message:"The transaction was canceled from Metamask."})
                    });
                  })
                }
                return await approveAllowancePromise()
              }
              else{
                return await createPayment()
              }
                
            }
            else{
                throw  {error:"Insufficient Busd", message:"Cannot initiate due to lack of funds. The registered account has ("+ userBalance / (10 ** 18) +"), while the fee is ("+ totalAmount / (10 ** 18) +")."}
            }
        }
        else{
            throw {error:"Incorrect Network", message:"In order to purchase from this application, you must be on the requested network."}
        }
      } catch (error) {
          return error
      }
  }
)



export const createPayout = createAsyncThunk(
  'monitization/createPayout',
  async (data, thunkApi) => {
      try {
        const net = await checkNetwork()
        if(net == true){
        
            const web3 = new Web3(window.ethereum)

            // ADDRESSES
            const senderAddress = window.ethereum.selectedAddress;

            const busdAddress = process.env.REACT_APP_BUSD
            const busdAbi = JSON.parse(process.env.REACT_APP_BUSD_ABI)

            // MONEY

            const totalAmount = await web3.utils.toWei((1).toString(), "ether");
            const playWallet = process.env.REACT_APP_GAMEWALLET

              const createPayment = async () => {
                  thunkApi.dispatch(setLoadingSlice('Waiting For Payout Confirmation...'))
                try {
                
                  // const res = await fetch(`${server}?auth=true&model=money&path=/payout`, {
                  //   method:"POST",
                  //   credentials: 'include',
                  //   headers: {"Content-Type": "application/json"},
                  //   body: JSON.stringify({lobby_id:data.lobby_id, bot_uid: (data.bot_uid != undefined) ? data.bot_uid : null})
                  // })
  
                  // const result = await res.json()
                  // if("error" in result){
                  //     throw result
                  // }
                } catch (error) {

                  thunkApi.dispatch(loadingReset())
                  thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
                  return
                }
                try {
                 
                  const busdContract = new web3.eth.Contract(busdAbi,busdAddress);
                  const userAddress = senderAddress
                  const fromAddress = playWallet; // The address that has approved the allowance for the user
                  const amount = data.amount.toFixed(2); // The amount of tokens you want to claim from the allowance
              
                  const amountInWei = web3.utils.toWei(amount.toString(), 'ether');
              
                  // Estimate the gas for the transaction
                  try {
                    const gas = await busdContract.methods.transferFrom(fromAddress, userAddress, '1').estimateGas({ from: userAddress });
                  // Create the transaction object
                  const transaction = {
                    from: userAddress,
                    to: busdAddress,
                    gas:gas.toString(),
                    data: busdContract.methods.transferFrom(fromAddress, userAddress, amountInWei).encodeABI()
                  };
              
                  // Send the transaction and let MetaMask handle the signing

                  try {
                    const transactionHash = await window.ethereum.request({
                      method: 'eth_sendTransaction',
                      params: [transaction]
                    });

                    // New Line
                      const res = await fetch(`${server}?auth=true&model=money&path=/payoutAll`, {
                        method:"POST",
                        credentials: 'include',
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify({transactionHash:transactionHash})
                      })

                      const result = await res.json()
                      if("error" in result){
                          throw result
                      }

                      await thunkApi.dispatch(getUserClaimableAmount())
                    
                      setTimeout(() => {
                        window.history.back()
                        thunkApi.dispatch(loadingReset())
                      }, 2000)
                   


                    // if(data.bot_uid != undefined){
                    //   thunkApi.dispatch(botPayout({transactionHash:transactionHash}))
                    // }


                    // setTimeout(async () => {
                      // if(data.bot_uid != undefined){
                        // await thunkApi.dispatch(getBotLobbies({ bot_uid: data.bot_uid }))

                        // thunkApi.dispatch(alterNavigate({url:'/automate', replace:true}))
                      // }
                      // else{
                      //  await thunkApi.dispatch(getUsersLobbiesInfo())
                        // thunkApi.dispatch(alterNavigate({url:'/games', replace:true}))
                      // }
                    // },5000)
                    // setTimeout(() => {
                    //   thunkApi.dispatch(loadingReset())
                    // }, 8000)
                  
                  } catch (err) {
                    const error = {error:"Canceled Payout", message:"The payout was canceled from Metamask."}
                    thunkApi.dispatch(loadingReset())
                    thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
                  }
                   
                  } catch (err) {
                    console.log(err)
                    const error = {error:"Payout Already Claimed", message:'The payout for this game was already claimed, contact support if not.'}
                    thunkApi.dispatch(loadingReset())
                    thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
                  }
                 
                } catch (err) {
                  const error = {error:"Canceled Payout", message:"The payout was canceled from Metamask."}
                  thunkApi.dispatch(loadingReset())
                  thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
                  return
                }
                
              }
                    await createPayment()
            }
            else{
              throw {error:"Incorrect Network", message:"In order to purchase from this application, you must be on the requested network."}
            }
      } catch (error) {
          thunkApi.dispatch(loadingReset())
          thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'error', messageTitle: error.error, message: error.message ,visible: true}))
          return error
      }
  }
)



export const checkNetwork = async () => {
    try {
        // Try to switch to the Mumbai testnet
        if(('0x' + window.ethereum.networkVersion.toString(16)) == process.env.REACT_APP_NETWORK_CHECK){
            return true
        }
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: process.env.REACT_APP_NETWORK_CHECK }], // Check networks.js for hexadecimal network ids
        }).catch(() => {
            return false
        })
        return true
      } catch (error) {
        // This error code means that the chain we want has not been added to MetaMask
        // In this case we ask the user to add it to their MetaMask
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {   
                  chainId: (process.env.REACT_APP_NETWORK_CHECK == '0x61') ? process.env.REACT_APP_NETWORK_CHECK : '0x38',
                  chainName: (process.env.REACT_APP_NETWORK_CHECK == '0x61') ? 'BNB Testnet' : 'BNB Smart Chain',
                  rpcUrls: (process.env.REACT_APP_NETWORK_CHECK == '0x61') ? ['https://data-seed-prebsc-1-s1.binance.org:8545'] : ['https://bsc-dataseed.binance.org/'],
                  nativeCurrency: {
                      symbol: "BNB",
                      decimals: 18
                  },
                  blockExplorerUrls: (process.env.REACT_APP_NETWORK_CHECK == '0x61') ? ['https://testnet.bscscan.com'] : ['https://bscscan.com']
                },
              ],
            }).catch(() => {
                return false
            });
            return true
          } catch (error) {
            return false
          }
        }
        else{
            return true
        }
      }
}
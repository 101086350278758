import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";

const M_Tips = (props) => {




    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
            <div className='side-content'>
                <div className='text-action vivify fadeIn duration-300 delay-100' style={
                    {
                        width: '90%'
                    }
                }>
                    <h2 id='currentArticle'>Tip's & Tricks</h2>

                    <div class="article" >
                        <p>
                            <h4>Plan your points strategically</h4>
                            Take the time to consider where to place your points to maximize their effectiveness.
                        </p>

                        <p><h4>Protect your defense point</h4>
                            This is your top priority, so make sure it's well defended
                        </p>

                        <p><h4>Choose attack points carefully
                        </h4>
                            Target weak points to increase your chances of success.
                        </p>

                        <p><h4>Keep an eye on the leaderboard</h4>
                            The player with the most kills will receive a larger payout, so try to stay on top.
                        </p>

                        <p><h4>Stay active in the game</h4>
                            Check the "Active Games" tab regularly to monitor your points and see how the game is progressing.
                        </p>

                        <p><h4>Stay aware of the game's progress
                        </h4>
                            Keep track of the number of players left and which points have been eliminated. This information can help you make strategic decisions about where to place your attack points and how to defend your defense point.
                        </p>

                        <p><h4>Don't be too predictable
                        </h4>
                            your attack points and defense strategies to keep your opponents guessing.
                        </p>

                        <p><h4>Be patient</h4>
                            Don't rush into attacks or make hasty decisions. Take the time to plan and strategize.
                        </p>

                        <p><h4>Learn from your mistakes</h4>
                            If your defense point is eliminated, take note of what went wrong and adjust your strategy for the next game.


                        </p>

                        <p><h4>Practice makes perfect


                        </h4>
                            The more you play, the more you'll learn and improve your skills.
                        </p>
                    </div>
                </div>
            </div>



        </>
    );

}

export default M_Tips;
import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    loading:null,
    navigation:null
})


const loadingSlice = createSlice({
    name:'loadingSlice',
    initialState:initialState(),
    reducers: {
        loadingReset: state => initialState(),
        setLoadingSlice:(state, action)=> {
          state.loading = action.payload
        },
        setNavigationRedirect:(state, action)=> {
            state.navigation = action.payload
          }
    }
})

export const {loadingReset, setLoadingSlice, setNavigationRedirect} = loadingSlice.actions

export default loadingSlice.reducer
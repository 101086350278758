import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from "react-router-dom";
import quickplayimg from '../../../assets/homBackground.png';
import MyGameCard from '../../../components/myGameCard';
import { getUsersLobbiesInfo } from '../../../httpcalls/gameThunk';
import { v4 as uuidv4 } from 'uuid';
import { getUser } from '../../../httpcalls/authThunk';
import WaitingCard from '../../../components/cards/waitingCard';
import LobbyNoMinCard from '../../../components/cards/lobbyNoMinCard';
import LobbyActiveGameCard from '../../../components/cards/lobbyActiveGameCard';
import LobbyFinishedCard from '../../../components/cards/lobbyFinishedCard';
import { getBotLobbies, getBotLobbyInformation } from '../../../httpcalls/botThunk';
import { setLoadingSlice } from '../../../store/schemas/loadingSlice';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mantine/core';


const M_AllGames = (props) => {
    const dispatch = useDispatch()
    const [activeGames, setActiveGames] = useState(null)
    const personalSelector = (typeof props.bot == 'undefined') ? useSelector(state => state.auth.userInfo.lobbies) : null
    const [userGames, setUserGames] = useState([])
    const location = useLocation()
    const [activeLink, setActiveLink] = useState('active')
    const navigate = useNavigate();

    /* useEffect(() => {
        dispatch(setLoadingSlice('Loading Game Progress...'))
        
    },[])
     */


    // Example Data For Damion Notice Game Type 1 is a classic game and game type 2 is a survival game
    // Damion Please Check The gameProgressSlice file for more information that needs to be added. 


    /* 
        setActiveGames( [
            {
                "day": 990,
                "dead": false,
                "claim": false,
                "lock": true,
                "lock_timer": "2023-03-23T03:01:34.713Z",
                "busd": 9.3,
                "active_view": false,
                "id": 21,
                "player_count": 3,
                "gameType": 1,
              
            },
           
            {
                "day": 18,
                "dead": true,
                "claim": false,
                "lock": true,
                "lock_timer": "2023-05-21T13:09:56.752Z",
                "busd": 0,
                "active_view": false,
                "id": 61,
                "player_count": 6,
                "gameType": 2,
              
            }
        ]) */

    // console.log(props)
    const vis = (e) => {
        if (document.visibilityState === "visible") {
            if (typeof props.bot == 'undefined') {
                dispatch(getUser())
                dispatch(getUsersLobbiesInfo()).then((res) => {
                    setActiveGames(res.payload.message)

                })
            }

            else {
                dispatch(getBotLobbies({ bot_uid: props.bot })).then((res) => {
                    setUserGames(res.payload)
                })

                dispatch(getBotLobbyInformation({ bot_uid: props.bot })).then((res) => {
                    setActiveGames(res.payload.message)
                })

            }
        }
    }
    useEffect(() => {

        if (typeof props.bot == 'undefined') {

            dispatch(getUser())
            setUserGames(personalSelector)
        }
        else {

            dispatch(getBotLobbies({ bot_uid: props.bot })).then((res) => {
                setUserGames(res.payload)
            })

        }
        document.addEventListener("visibilitychange", vis)
        return () => { document.removeEventListener('visibilitychange', vis) };

    }, [])
    useEffect(() => {
        if (window.location.pathname == "/games" || window.location.pathname == '/automate') {
            if (typeof props.bot == 'undefined') {
                dispatch(getUsersLobbiesInfo()).then((res) => {
                    setActiveGames(res.payload.message)

                })
            }
            else {
                dispatch(getBotLobbyInformation({ bot_uid: props.bot })).then((res) => {
                    setActiveGames(res.payload.message)
                })
            }

        }
        else {
            //COMMENT ME OUT TO NOT DERENDER COMPONENTS
            // setActiveGames(null)
        }
    }, [location])




    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
            <div className='side-content' style={{ flexDirection: "column", justifyContent: "flex-start", gap: "40px" }}>






                <div className='mygames-container vivify fadeIn duration-300 delay-100'>
                    <div className='mygames-wrapper vivify fadeIn duration-300 delay-100'>
                        {
                            (activeGames != null) ?
                                activeGames.length == 0 || userGames.length == 0 ?
                                    <div className='game-card-header' style={{ "display": "flex", "flexDirection": "column", "margin": "auto", "padding": "4rem", "lineHeight": "3.4rem", "paddingBottom": "2rem" }}>
                                        <h1>It's pretty empty in here...</h1>
                                        <p>Why not go start a game, it'll make this screen more lively!</p>
                                    </div>

                                    :

                                    (() => {
                                        let filterLobbies = []

                                        if (props.type == 'complete') {
                                            filterLobbies = userGames.filter((lobby) => {

                                                if (lobby != 'waiting') {
                                                    if ((activeGames.find(active => active.id == lobby)).active_view == false) {
                                                        return lobby
                                                    }
                                                }

                                            })
                                        }
                                        else if (props.type == 'active') {
                                            // console.log(userGames)
                                            console.log(userGames)
                                            console.log(activeGames)
                                            filterLobbies = userGames.filter((lobby) => {

                                                if (lobby == 'waiting') {
                                                    return lobby
                                                }
                                                else if ((activeGames.find(active => active.id == lobby)).active_view == true) {
                                                    return lobby
                                                }


                                            })
                                        }
                                        else {
                                            filterLobbies = userGames
                                        }

                                        if (filterLobbies.length == 0) {
                                            if (props.type == 'complete') {
                                                return (
                                                    <div className='game-card-header' style={{ "display": "flex", "flexDirection": "column", "margin": "auto", "padding": "4rem", "lineHeight": "3.4rem", "paddingBottom": "2rem" }}>
                                                        <h1>No completed games detected</h1>
                                                        <p>Finish a game to fill this area!</p>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div className='game-card-header' style={{ "display": "flex", "flexDirection": "column", "margin": "auto", "padding": "4rem", "lineHeight": "3.4rem", "paddingBottom": "2rem" }}>
                                                        <h1>No active games detected</h1>
                                                        <p>Why not go start a game, it'll make this screen more lively!</p>
                                                    </div>
                                                )
                                            }

                                        }
                                        return filterLobbies.map((item, index) => {
                                            console.log(item)
                                            if (item == 'waiting') {
                                                return (
                                                    <WaitingCard />
                                                )
                                            }
                                            const getGame = activeGames.find((game) => game.id == item)

                                            if (getGame.lock_timer == null) {
                                                return (
                                                    <LobbyNoMinCard key={uuidv4()} day={getGame.day} trackNum={getGame.id} players={getGame.player_count} gameType={getGame.gameType} />
                                                )
                                            }
                                            else {


                                                var gameTime = moment(getGame.lock_timer);

                                                // Get the current UTC time.
                                                var currentTime = moment.utc();

                                                // Calculate the difference between the two times in seconds.
                                                while (gameTime.isBefore(currentTime)) {
                                                    gameTime.add(1, 'day');
                                                }
                                                var timeDifference = moment.duration(gameTime.diff(currentTime)).asSeconds();

                                                var hours = Math.floor(timeDifference / 3600);
                                                timeDifference %= 3600;
                                                var minutes = Math.floor(timeDifference / 60);
                                                var seconds = timeDifference % 60;

                                                const totalTime = hours * 60 * 60 + minutes * 60 + seconds




                                                if (getGame.active_view == true) {
                                                    return (
                                                        <LobbyActiveGameCard key={uuidv4()} value={getGame.busd.toLocaleString("en-US", { style: 'currency', currency: 'USD' })} day={getGame.day} trackNum={getGame.id} status="taken" players={getGame.player_count} time={totalTime} gameType={getGame.gameType} />
                                                    )
                                                }
                                                else {

                                                    return (
                                                        <LobbyFinishedCard dead={getGame.dead} claim={getGame.claim} key={uuidv4()} busd={getGame.busd} value={getGame.busd.toLocaleString("en-US", { style: 'currency', currency: 'USD' })} players={getGame.player_count} day={getGame.day} trackNum={getGame.id} status="claim" gameType={getGame.gameType} />
                                                    )
                                                }
                                            }

                                        })
                                    })()

                                // else{
                                //     const gameInfo = activeGames.find(game => game.id.toString() == item)
                                //     if(gameInfo != undefined){
                                //         console.log(gameInfo)
                                //         if(gameInfo.lock_timer == null){
                                //             return(
                                //                 <MyGameCard key={uuidv4()} status="waiting" />
                                //             )
                                //         }
                                //         else{
                                //             if(gameInfo.dead == true){
                                //                 return (
                                //                 <MyGameCard key={uuidv4()} busd={gameInfo.busd} value={gameInfo.busd.toLocaleString("en-US", { style: 'currency', currency: 'USD' })} players={gameInfo.player_count} day={gameInfo.day} trackNum={index + 1} status="claim" />
                                //                 )
                                //             }

                                //             const user_time = new Date(new Date().toISOString())
                                //             const user_time_total_seconds = user_time.getHours() * 60 *60 + user_time.getMinutes() * 60 + user_time.getSeconds()
                                //             const server_time = new Date(new Date(gameInfo.lock_timer).toISOString())
                                //             const server_time_total_seconds = server_time.getHours() * 60 * 60 + server_time.getMinutes() * 60 + server_time.getSeconds()
                                //             let timeDifference = 0
                                //             if((user_time_total_seconds - server_time_total_seconds) < 0){
                                //                  timeDifference = (server_time_total_seconds - user_time_total_seconds)
                                //             }
                                //             else{
                                //                  timeDifference = (24 * 60 * 60) - (user_time_total_seconds - server_time_total_seconds)
                                //             }

                                //             return (
                                //                 <MyGameCard key={uuidv4()} value={gameInfo.busd.toLocaleString("en-US", { style: 'currency', currency: 'USD' })} day={gameInfo.day} trackNum={index + 1} status="taken" players={gameInfo.player_count} time={timeDifference}/> 
                                //             )
                                //         }
                                //     }
                                // }

                                :
                                <div className='inline-loader'>
                                    <div className='loader vivify swoopInBottom delay-100'>

                                        <div className='loader-inner '>
                                            <div class="indeterminate-progress-bar">
                                                <div class="indeterminate-progress-bar__progress"></div>
                                            </div>
                                            <h3 className='vivify'>Loading Games...</h3>

                                        </div>
                                    </div>
                                </div>
                            // activeGames.map((item) => {
                            //     if(item.)
                            //     return (
                            //         <MyGameCard status="waiting" time={400 * 30} />
                            //     )
                            // })
                        }
                        {/* <MyGameCard status="waiting" time={400 * 30} /> */}
                        {/* <MyGameCard value="148.00" day="21" trackNum="1" status="claim" />
                    <MyGameCard value="148.00" day="21" trackNum="2" status="available"  /> */}
                        {/* <MyGameCard value="148.00" day="21" trackNum="3" status="available" />
                    <MyGameCard value="148.00" day="21" trackNum="4" status="available" />
                    <MyGameCard value="148.00" day="21" trackNum="5" status="taken" time={0.3 * 30}/>
                    <MyGameCard value="148.00" day="21" trackNum="6" status="taken" time={19 * 60}/>
                    <MyGameCard value="148.00" day="21" trackNum="7" status="taken" time={20 * 50}/>
                    <MyGameCard value="148.00" day="21" trackNum="8" status="taken" time={21 * 10}/>
                    <MyGameCard value="148.00" day="21" trackNum="9" status="taken" time={20 * 30}/> 

                                        <MyGameCard value="148.00" day="21" trackNum="7" status="taken" time={20 * 50}/> */}


                    </div>
                </div>

            </div>

        </>
    );

}

export default M_AllGames;
import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";
import Loader from './loader';
import { useDispatch, useSelector } from "react-redux";
import { alterNotification } from '../store/schemas/notificationSlice';
import { resetGameManger } from '../store/schemas/gameSlice';
import { validateUserInput } from '../httpcalls/gameThunk';
import { loadingReset, setLoadingSlice } from '../store/schemas/loadingSlice';
const GameFooterNav = (prop) => {
    const [readyToSubmit, setReadyToSubmit] = useState(false);
    const [readyToExit, setReadyToExit] = useState(false);
    const [lockIcon, setLockIcon] = useState("check_circle");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tempgame = useSelector(state => state.mainapp.gameSlice.gameManager);

    // useEffect(() => {
    //     return () => {
    //         dispatch(resetGameManger())
    //       }; 
    //   }, [])



  const handleAttack = () => {
    const currentData =  tempgame.defend

 
       if(currentData !== null){
                            
                                setTimeout(() => {
                               
                                   navigate("/playing/attack");
                                }, 0);
    }else {  
       
            dispatch(alterNotification({ position: 'bottom-left', autoClose: 0, alertStyle: 'warning', messageTitle: 'Place A Defense Point', message: 'You must place a defense point before you can attack.' ,visible: true}));
    } 
  }

  const handleSubmit = () => {
    const currentData =  tempgame.attack[0].value




    if(tempgame.attack.map
        (item => item.value).filter(item => item !== null).length > 1){
        dispatch(setLoadingSlice('Validating...'))
        dispatch(validateUserInput()).then((res) => {
            if(!("error" in res.payload)){
                dispatch(loadingReset())
                navigate("/playing/matchmaking");
            }
            else{

                dispatch(loadingReset())
                dispatch(alterNotification({ position: 'bottom-left', autoClose: 20000, alertStyle: 'error', messageTitle: res.payload.error, message: res.payload.message,visible: true}));
            }
        })
        
     
    }else {     
        dispatch(loadingReset())
        dispatch(alterNotification({ position: 'bottom-left', autoClose: 0, alertStyle: 'warning', messageTitle: 'Place Attack Points', message: 'You must place an attack point for every day. Use the "Next Day" button to select another day. ',visible: true}));
    }
    }



    return (
        <>

    

            {
                prop.style === "attack" ? (
                    <>
                        <NavLink to={"/playing/defend"} replace={true} >
                            <button className='btn btn-primary' > <i style={
                                {
                                    marginLeft: "-10px"
                                }
                            } className='material-icons'>chevron_left</i>Previous</button>



                        </NavLink>

                     

                        <NavLink  replace={true} >

                            <button onClick={
                                () => {
                                    handleSubmit();
                                }
                            } onMouseOver={
                                () => {
                                    setLockIcon("check_circle");
                                }
                            }
                                onMouseLeave={
                                    () => {
                                        setLockIcon("check_circle_outline");
                                    }
                                }
                                style={
                                    {
                                        width: "88% !important"
                                    }
                                } ><i style={
                                    {
                                        marginLeft: "-10px",
                                        marginRight: "7px"
                                    }
                                } className='material-icons-outlined' >{lockIcon}</i>Confirm</button>


                        </NavLink>
                    </>
                ) : (
                    <>
                        <NavLink replace={true}  >

                            <button 
                           onClick={
                            () => {
                               handleAttack();
                            }
                        

                        } id="attack-btn" className='btn btn-primary' 
                            >Next (Attack) <i className='material-icons'>chevron_right</i></button>
                        </NavLink>


                    </>
                )

            }






        </>
    );

}

export default GameFooterNav;
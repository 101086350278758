import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";

const M_HowMuch = (props) => {



    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
            <div className='side-content'>
                <div className='text-action vivify fadeIn duration-300 delay-100' style={
                    {
                        width: '90%'
                    }
                }>
                    <h2 id='currentArticle'>How Much Can I Get</h2>

                    <div class="article" >
                        
 
  
  <p>Wanna know how much you can earn RIGHT NOW for every kill in our game? It's time to reveal the secret!</p>

  <p>Current Kill Reward: BUSD 10.00 per kill</p>

  <p>That's right, folks! You'll get BUSD 10.00 for every opponent you take down. The battlefield has never been more lucrative!</p>

  <p>Remember: The Kill Reward value can change at any time!</p>

  <p>Stay sharp, keep an eye on the prize, and let the thrill of the kill make you some serious cash! Are you ready to give it a shot?</p>

  <p>Jump in and start earning those Kill Rewards NOW!</p>

                    </div>
                </div>


            </div>


        </>
    );

}

export default M_HowMuch;
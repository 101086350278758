import React, {useRef, useEffect, useState} from 'react';
import BackPlate from "../../../components/backplate";
import "../../../css/gamePlay.css"
import GameFooterNav from '../../../components/gameFooterNav';
import { useDispatch } from 'react-redux';
import { alterNotification } from '../../../store/schemas/notificationSlice';

const Attack = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(alterNotification( {position: 'bottom-left', autoClose: 0, alertStyle: 'help', messageTitle: 'Attack Game Help', message: 'Attack your opponent by placing your attack points on the grid. You only have 1 attack point per day if your point is attacked you will be eliminated.', visible: true} )) ;
  }, []);

   
  return (
    <>





        <div className='game-desc-container'>
        <BackPlate backplateType="attack" />
        <div className='back-title'>
        <h1 style={{marginLeft: "-15px"}}>Attack</h1>
      </div>
            <div className='game-desc'>
            <p>CHOOSE YOUR</p>
     <h1 className='page-title' style={{marginTop: "0px"}}>ATTACK POINT</h1>
     <span>Note: The points you choose on the grid cannot be changed after you confirm for today. </span>
            </div>
     </div>



<div className='game-footer'> 
    <div className='game-help'>
    <h1>Help</h1>
        <p>For 28 days you’ll be battling against 58 other players. The points you decide here will be attacks against the enemy team. </p>
    </div>
    <div className='game-nav-footer'>
      <GameFooterNav state="disabled" style="attack"/>
</div>
</div>


     

    <style>
        {`
       :root {
        --main-color: var(--alt-color4)
       }
        `}
    </style>
   
    </>
  );

}

export default Attack;
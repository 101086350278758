import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './schemas/authSlice';
import gameSlice from './schemas/gameSlice';
import notificationSlice from './schemas/notificationSlice';
import gameProgressSlice from './schemas/gameProgressSlice';
import adminSlice from './schemas/adminSlice';
import miniMapSlice from './schemas/miniMapSlice';
import loadingSlice from './schemas/loadingSlice';
import helperFunctionsSlice from './schemas/helperFunctionsSlice';
import claimSlice from './schemas/claimSlice';
import automateSlice from './schemas/automateSlice';
import leaderboardSlice from './schemas/leaderboardSlice';
import userStatsSlice from './schemas/userStatsSlice';




export const store = configureStore({
  reducer: {
    auth:authSlice,
    mainapp: combineReducers({
      gameSlice: gameSlice,
      notificationSlice: notificationSlice,
      gameProgressSlice: gameProgressSlice,
      miniMapSlice: miniMapSlice,
      adminSlice: adminSlice,
      loadingSlice: loadingSlice,
      helperFunctions: helperFunctionsSlice,
      automateSlice: automateSlice,
      leaderboardSlice: leaderboardSlice,
      userStatsSlice: userStatsSlice,
      claimSlice: claimSlice,

    }),
  }
});

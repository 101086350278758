import { createAsyncThunk } from '@reduxjs/toolkit'
import { setDashboard } from '../store/schemas/adminSlice'
import { isLogin, isNotLogin, resetAuth, userInformation } from '../store/schemas/authSlice'
import { alterNotification } from '../store/schemas/notificationSlice'

const server = process.env.REACT_APP_DOMAIN
export const getDashboard = createAsyncThunk(
    'admin/getDashboard',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&admin=true&model=admin&path=/getDashboard`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({type:data.type})
            })
            const results = await response.json()
            if("error" in results){
                window.location.href = '/'
            }

            thunkApi.dispatch(setDashboard(results.message))
            return true
        } catch (error) {
            console.log(error)
        }
    }
  )


  export const addLobby = createAsyncThunk(
    'auth/addLobby',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&admin=true&model=admin&path=/addLobby`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})
                
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            thunkApi.dispatch(getDashboard({type:'active'}))
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: error.error, message: error.message ,visible: true}))
            return false
        }
    }
  )

  
  export const addFake = createAsyncThunk(
    'auth/addFake',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&admin=true&model=admin&path=/addFake`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({lobby_id:data.id})
                
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }

            thunkApi.dispatch(getDashboard({type:'active'}))
            return true
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: error.error, message: error.message ,visible: true}))
            return false
        }
    }
  )
  
  export const shootGame = createAsyncThunk(
    'auth/shootGame',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&admin=true&model=admin&path=/shootGame`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({lobby_id:data.id})
                
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            setTimeout(() => {
                thunkApi.dispatch(getDashboard({type:'active'}))
            }, 10000)
            
            return true
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: error.error, message: error.message ,visible: true}))
            return false
        }
    }
  )


  export const completedBattlesReport = createAsyncThunk(
    'auth/completedBattlesReport',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}?auth=true&admin=true&model=admin&path=/completedBattlesReport`, {
                method:'POST',
                credentials: 'include',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({})
                
            })
            const results = await response.json()
            if("error" in results){
                throw results
            }
            
            let workbook = new ExcelJS.Workbook();
            let worksheetAll = workbook.addWorksheet('All');
            let worksheetSurvivor = workbook.addWorksheet('Survivor');
            let worksheetClassic = workbook.addWorksheet('Classic');


            let numFmt = {
                'TCEF': '$#,##.00',
                'TCRE': '$#,##.00',
                'PC.ROI$': '$#,##.00',
                'PC.ROI%': '0.00%',
                'Completed Lobbies': '0'
            };
            
            worksheetAll.columns = Object.keys(results.message.all[0]).map(key => ({
                header: key,
                key: key,
                width: 10,
                style: { numFmt: numFmt[key] || 'General' }
            }));

            worksheetSurvivor.columns = Object.keys(results.message.all[0]).map(key => ({
                header: key,
                key: key,
                width: 10,
                style: { numFmt: numFmt[key] || 'General' }
            }));

            worksheetClassic.columns = Object.keys(results.message.all[0]).map(key => ({
                header: key,
                key: key,
                width: 10,
                style: { numFmt: numFmt[key] || 'General' }
            }));

            results.message.all.forEach(item => {
                let newItem = {...item, 'PC.ROI%': item['PC.ROI%'] / 100};
                worksheetAll.addRow(newItem);
            });

            results.message.classic.forEach(item => {
                let newItem = {...item, 'PC.ROI%': item['PC.ROI%'] / 100};
                worksheetClassic.addRow(newItem);
            });
            results.message.survivor.forEach(item => {
                let newItem = {...item, 'PC.ROI%': item['PC.ROI%'] / 100};
                worksheetSurvivor.addRow(newItem);
            });
            const buffer = await workbook.xlsx.writeBuffer()
            // Create a Blob from the buffer
            let blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        
            // Create a link element, hide it, direct it towards the blob, and then 'click' it programmatically
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = 'Completed_Battles_Payer_Report_'+Date.now()+'.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
            return 'success'
        } catch (error) {
            thunkApi.dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: error.error, message: error.message ,visible: true}))
            return false
        }
    }
  )
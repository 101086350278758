import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetHelperFunction } from '../store/schemas/helperFunctionsSlice';

const NavigateFromThunk = () => {
    const navigate = useNavigate()
    const navi = useSelector(state => state.mainapp.helperFunctions.nav)
    const dispatch = useDispatch()
    useEffect(() => {
        if(navi != null){
            navigate(navi.url, {replace:navi.replace})
            dispatch(resetHelperFunction(null))
        }
    }, [navi])

  return (
    <>
      
   

    </>
  );

}

export default NavigateFromThunk;
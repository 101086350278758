import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { logout } from '../httpcalls/authThunk';
import { useDispatch, useSelector } from 'react-redux';
import binancelogo from '../assets/binance.svg';
import { Tooltip } from '@mantine/core';
import survivorImg from '../assets/survivor.gif';


const ProfileChip = () => {
    const dispatch = useDispatch()
    const username = useSelector(state => state.auth.userInfo.username)
    const avatar = useSelector(state => state.auth.userInfo.avatar)
    const uid = useSelector(state => state.auth.userInfo.uid)
    const totalValue = useSelector(state => state.mainapp.claimSlice.totalValue);;
    const [opened, setOpened] = useState(localStorage.getItem('featureDiscoveredToolTip') !== 'true');



    return (
        <>

       

            <Tooltip
                withArrow
                arrowSize={6}
                offset={10}
                label="NEW: Claiming your funds has moved."
                opened={opened}
                position='bottom'
            >
                <div  className='fairness-btn'>
                    <Link className={
                        totalValue > 0 ? 'vivify blink duration-2000 delay-1000' : ''
                    } to='/claimall'>
                        <button onMouseOut={() => {
                            localStorage.setItem('featureDiscoveredToolTip', 'true');
                            setOpened(false);
                        }} style={
                            totalValue > 0 ? {
                                opacity: '1'
                            } : {
                            }
                        } className='btn btn-primary'>
                            <img src={binancelogo}></img>
                            <div className='claim-btn-text'>
                                <span style={
                                    totalValue > 0 ? {
                                        color: 'var(--alt-color2)'
                                    } : {}

                                }>{totalValue.toFixed(2)} BUSD </span>
                                <p>{
                                    totalValue > 0 ? 'Claim Available' : 'No Rewards Available'
                                }</p>
                            </div>
                        </button>
                    </Link>

                </div>


            </Tooltip>

            <div className='profile-chip-container'>
                <div className='profile-chip-wrapper'>
                    <div className='profile-chip'>
                        <img src={'https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=' + username}></img>
                        <div className='profile-chip-info'>

                            <p>{
                                username.replace(/#.*/, '')
                            }</p>
                            <h3>#{uid.substring(uid.length - 5, uid.length)}</h3>
                        </div>

                    </div>

                </div>

                <div className='profile-pop '>


                    <button onClick={() => { dispatch(logout()) }} className='vivify fadeIn duration-300'><i className='material-icons vivify fadeIn duration-300 delay-100'>logout</i><span className='vivify fadeIn duration-300 delay-200'>Logout</span></button>
                </div>
            </div>


        </>
    );

}

export default ProfileChip;
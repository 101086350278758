import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from "react-router-dom";
import quickplayimg from '../../../assets/homBackground.png';
import Loader from '../../../components/loader';
import { checkIfGameTypeOpen } from '../../../httpcalls/gameThunk';
import { alterGameType } from '../../../store/schemas/gameSlice';
import { loadingReset, setLoadingSlice } from '../../../store/schemas/loadingSlice';
import { useSelector } from 'react-redux';
import { getUserStatsCompleted } from '../../../httpcalls/authThunk';
const M_Stats = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [load, setLoad] = useState(true)
    const SData = useSelector(state => state.mainapp.userStatsSlice.userStats);
    
    const Stats = SData


    const [hasScrolled, setHasScrolled] = useState(false);

    const checkProfit = (item) => {
        if(item > 0){
            return <p style={{color:'var(--alt-color)'}} >{item}</p>
        }
        else if(item < 0){
            return <p style={{color:'var(--alt-color4)'}} >{item}</p>
        }
        return <p >{item}</p>
    
    }

    useEffect(() => {
        if(SData.length != 0 ){
            setLoad(false)
        }
    }, [SData])

    useEffect(() => {

        dispatch(getUserStatsCompleted())
        
        function handleScroll() {
            const threshold = 10; // Set your threshold here
            if (window.pageYOffset > threshold) {

                setHasScrolled(true);


            } else {

                setHasScrolled(false);

            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
     
    }, []);



    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
            {load == false ?
             <div className='leaderboard-container'>

             <div className='board-container' style={
                 {
                     marginTop: "35px",
                 }
             }>
                 <div className='board-header'>
                     <div style={
                         {
                             gridTemplateColumns: "2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr",
                         }
                     } className={
                         !hasScrolled ? 'board-item vivify fadeIn duration-300 delay-300' : 'board-item vivify fadeIn duration-300 delay-300 scrolled-board-header'
                     }>
                    
                          
                         <p>Data</p>
                         <p>Today</p>
                         <p>Week</p>
                         <p>Month</p>
                         <p>Year</p>
                         <p>Life-Time</p>

                     </div>
                 </div>
                 <div className='board-column'>
                     {Stats.map((user, index) => (
                         <div style={
                             {
                                 gridTemplateColumns: "2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr 1.2fr",
                             }
                         } key={index}
                             className={"board-item"}>
                                
                            
                             <p className={"board-name"}>{user.dataType}</p>
                             {
                                    (() => {
                                        if(user.dataType == 'Total Profit'){
                                            return  <>
                                                {
                                                (() => {
                                                    const keys = ['today', 'yesterday', 'month', 'year', 'allTime']
                                                    return keys.map((key) => checkProfit(user[key]))
                                                })()
                                                
                                                }
                                            </>
                                           
                                        }
                                        else{
                                            return (
                                                <>
                                                <p >{user.today}</p>
                                                <p>{user.yesterday}</p>
                                                
                                                <p>{user.month}</p>
                                                <p>{user.year}</p>
                                                <p>{user.allTime}</p>
                                                </>
                                            )
                                        }
                                        
                                    })()
                                }
                             

                         </div>
                     ))}
                 </div>
             </div>
         </div>
        
            :
            ""
            }
           





            <style>
                {`
      .slim-title {
        height: 100%;
      }

     .board-column > .board-item {
       padding:0px 37px;

       height: 80px
      }

      .board-column {
        gap:0px
      }

      .scrolled-board-header {
        background-color: #271428;
        backdrop-filter: blur(10px);
      }

   .board-column >   .board-item > p:first-of-type {
      
      }

      .board-column > .board-item > p:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%

      }

   
        `}
            </style>
        </>
    );

}

export default M_Stats;
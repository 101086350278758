import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate } from "react-router-dom";
import quickplayimg from '../../../assets/homBackground.png';
import Loader from '../../../components/loader';
import { checkIfGameTypeOpen } from '../../../httpcalls/gameThunk';
import { alterGameType } from '../../../store/schemas/gameSlice';
import { loadingReset, setLoadingSlice } from '../../../store/schemas/loadingSlice';
import { useSelector } from 'react-redux';
import { getUserStatsActive } from '../../../httpcalls/authThunk';
const M_ActiveGamesStats = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [load, setLoad] = useState(true)

    const SData = useSelector(state => state.mainapp.userStatsSlice.activeUserStats);
 
    const Stats = SData

    useEffect(() => {
        if(SData.length != 0 ){
            setLoad(false)
        }
    }, [SData])

    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        dispatch(getUserStatsActive())

        function handleScroll() {
            const threshold = 10; // Set your threshold here
            if (window.pageYOffset > threshold) {

                setHasScrolled(true);


            } else {

                setHasScrolled(false);

            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
     
    }, []);



    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
   {load == false ?
            <div className='leaderboard-container'>

                <div className='board-container' style={
                    {
                        marginTop: "35px",
                    }
                }>
          
                    <div className='board-column'>
                        {Stats.map((user, index) => (
                            <div style={
                                {
                                    gridTemplateColumns: "1fr 1fr 3fr",
                                }
                            } key={index}
                                className={"board-item"}>
                                   
                                {/* Rank */}
                                <p className={"board-name"}>{user.dataType}</p>
                                {/* User Name */}

                                {(() => {
                                    if(user.dataType == 'Total Profit'){
                                        if(user.data > 0){
                                            return <p style={{color:'var(--alt-color)'}} >{user.data}</p>
                                        }
                                        else if(user.data < 0){
                                            return <p style={{color:'var(--alt-color4)'}} >{user.data}</p>
                                        }
                                    }
                                    return  <p >{user.data}</p>
                                })()}
                              

                            </div>
                        ))}
                    </div>
                </div>
            </div>

            :
                            ""
                        }




            <style>
                {`
      .slim-title {
        height: 100%;
      }

     .board-column > .board-item {
       padding:0px 37px;

       height: 80px
      }

      .board-column {
        gap:0px
      }

      .scrolled-board-header {
        background-color: #271428;
        backdrop-filter: blur(10px);
      }

   .board-column >   .board-item > p:first-of-type {
      
      }

      .board-column > .board-item > p:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%

      }

   
        `}
            </style>
        </>
    );

}

export default M_ActiveGamesStats;
import React, { useRef, useEffect, useState } from 'react';
import DiscordChip from '../assets/discordchip.svg';

const Footer = () => {

   
  return (
    <>
      
      <div className='univ-footer'>
        <p>©2023 0xBullzEye</p>
        <div className='community-link'>
          <a href='https://discord.gg/DdaM87e6xW' target='_blank' rel='noreferrer'>
            <img src={DiscordChip}></img>
          </a>
        </div>
      </div>

    </>
  );

}

export default Footer;
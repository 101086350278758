import React, { useRef, useEffect, useState } from 'react';
import "../../css/modal.css";
import DisclaimerModal from './disclaimerModal';
import GameModal from './gameModal/gameModal';
import { useNavigate } from 'react-router-dom';
import FairModal from './fairnessModal/fairController';
import ShopModal from './shopModal/shopModal';
import AutomateModal from './automateModal/automateController';
import ShopModalBackground from '../../assets/shopModalBackground.png';
import AutomateModalBackground from '../../assets/automateModalBackground.png';
import UpdateModal from './updateModal/updateModal';
import GoldModalBackground from '../../assets/backplates/payoutBackPlate.png';
import ClaimModal from './claimallModal';


const ModalController = (props) => {

    const navigate = useNavigate();
  
    const [scrolledBelow, setScrolledBelow] = useState(false);
    const [pageReady, setPageReady] = useState(false);

    useEffect(() => {
      if (props.type === "shop") {
        document.getElementById('modalContainer').style.backgroundImage = `url(${ShopModalBackground})`;
      }  else if (props.type === "automate") {
        document.getElementById('modalContainer').style.backgroundImage = `url(${AutomateModalBackground})`;
      } else if (props.type === "claim") {
        document.getElementById('modalContainer').style.backgroundImage = `url(${GoldModalBackground})`;
      }
    }, [props])
    


  return (
    <>


      <div className='modal-wrapper' id='modal'>
        <button onClick={
              () => {
                navigate(props.exit,{replace:true})
              }
            } className='lg-modal-close'></button>
        <div className='modal-container vivify fadeIn duration-300 ' id='modalContainer'>
       
          <div className='modal-header' id='modalHeader'>
          <button style={
              {
                opacity: '0'
              }
            } className='modal-close'>
              <i className='material-icons'>close</i>
            </button>
            <h1>{props.title}</h1>
            <button onClick={
              () => {
                navigate(props.exit)
              }
            } style={
              props.close === "true" ? {opacity: '1'} : {opacity: '0',cursor: 'default'}
            }  className='modal-close'>
              <i className='material-icons'>close</i>
            </button>
          </div>

          <div className='modal-hero'>
            <h2>{props.heroTitle}</h2>
            <h3 className='page-title'>{props.title}</h3>
          </div>

        
     
          <div className='modal-body vivify fadeIn duration-300 delay-400'>
           {
            props.type === "legal" ? <DisclaimerModal />
            : props.type === "game" ? <GameModal /> : props.type === "fair" ? <FairModal /> : props.type === "shop" ? <ShopModal /> : props.type === "automate" ? <AutomateModal /> : props.type === "update" ? <UpdateModal /> : props.type === "claim" ? <ClaimModal /> : null
           }
          </div>


        

        </div>
      </div>



    </>
  );

}

export default ModalController;
import React, { useRef, useEffect, useState } from 'react';
import BackPlate from "../../components/backplate";
import BackTitle from "../../components/backTitle";
import SubNavBar from '../../components/subNavbar';
import Footer from '../../components/footer';

import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getHundredPlayers } from '../../httpcalls/leaderboardThunk';
const AdminLeaderboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [load, setLoad] = useState(true)

    useEffect(() => {
        dispatch(getHundredPlayers()).then((res) => {
            setLoad(false)
        })
    }, [])
  
    return (
        <>
            <div className='content-body'>




                {(load == false) ? <Outlet />  : "" }
                
            </div>

            <style>
        {`
       :root {
        --main-color: var(--native-color);
        --dark-color-bar:#421539;
       }

        `}
      </style>
        </>
    );

}

export default AdminLeaderboard;
import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";

const M_HowItWorks = (props) => {



    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
            <div className='side-content'>
                <div className='text-action vivify fadeIn duration-300 delay-100' style={
                    {
                        width: '90%'
                    }
                }>
                    <h2 id='currentArticle'>How It Works</h2>

                    <div class="article" ><p>
                        To start playing 0XBullzeye, you'll need to pay 10 BUSD, a type of crypto currency. Once you pay, you'll be able to place your defense point, and all of your attack points before the game starts. You'll need to strategize and think carefully about where to place your points to increase your chances of success.</p>
                        <p> Throughout the day, points on the board will be attacked by other players, and your job is to protect your defense point while trying to eliminate other players' defense points. You can choose your attack point strategically to increase your chances of success.</p>
                        <p> The more successful attacks and kills you make, the higher the rewards you'll earn. If you're the player with the most kills on the board, you'll receive a larger payout.</p>
                        <p>The game will run for a specific amount of time, and during that time, other players will be attacking. You'll receive periodic updates in the "Active Games" tab, which will let you know how your defense and attack points are doing and how many kills you've made on the board.

                        </p>
                        <p>The game uses blockchain technology to create a secure and transparent gaming environment. This means that the game is fair, and all players have an equal chance of winning.

                        </p>
                        <p>So, to summarize, to play 0XBullzeye, you'll need to pay 10 BUSD, place your defense point, and all of your attack points before the game starts. Throughout the day, you'll need to protect your defense point while trying to eliminate other players' defense points. The more kills you make, the higher the rewards you'll earn. You can claim all the rewards you earned in a battle upon either getting hit, or the battle ending, whichever comes first. You'll receive periodic updates in the "My Games" tab, and the game uses blockchain technology to ensure fairness.</p>

                        <p>Are you ready to start playing 0XBullzeye? Good luck and have fun!



                        </p>
                    </div>
                </div>


            </div>


        </>
    );

}

export default M_HowItWorks;
import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createPayout } from '../../helper-functions/contract';
import { getUserClaimableAmount } from '../../httpcalls/moneyThunk';
const ClaimModal = () => {

    const [agreeStatus, setAgreeStatus] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const mainAccount = useSelector(state => state.mainapp.claimSlice.mainAccount);
    const botAccounts = useSelector(state => state.mainapp.claimSlice.botAccounts);
    const totalValue = useSelector(state => state.mainapp.claimSlice.totalValue);
    const time = useSelector(state => state.mainapp.claimSlice.time);

    const userInfo = useSelector((state) => state.auth.userInfo)

    useEffect(() => {
        dispatch(getUserClaimableAmount())
    }, [])

    const startPayout = () => {
        dispatch(createPayout({ time: time, amount: totalValue }))
    }

    return (
        <>


            <div className='claim-container'>
                {
                    mainAccount || botAccounts ? (
                        <div className='left-claim'>
                            {
                                mainAccount ?
                                    <div className='left-item-container'>
                                        <h2>Main Account</h2>

                                        <div className='left-item-sub-container mainColor'>
                                            {
                                                mainAccount.map((item, index) => (
                                                    <div className='left-item'>


                                                        <h3>{item.name}</h3>
                                                        <p>{item.balance} BUSD</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    : <div className='left-item-container'>
                                        <h2>No Main Account Funds Available</h2>

                                    </div>

                            }

                            {
                                botAccounts ?
                                    <div className='left-item-container'>
                                        <h2>Bot Account</h2>

                                        <div className='left-item-sub-container alternateColor'>
                                            {
                                                botAccounts.map((item, index) => (
                                                    <div className='left-item'>
                                                        <h3>{item.name}</h3>
                                                        <p>{item.balance} BUSD</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div> : <div className='left-item-container'>
                                        <h2>No Bot Account Funds Available</h2>

                                    </div>
                            }

                        </div>
                    )
                        : <div className='left-claim'>
                            <div className='left-item-container'>
                                <h2>No Funds Available</h2>

                            </div>
                        </div>
                }




                {
                    mainAccount || botAccounts ?
                        <div className='right-claim'>
                            <div className='claim-item-container'>
                                <h2>BUSD</h2>
                                <h1>{totalValue.toFixed(2)}</h1>
                            </div>

                            <div className='right-claim-btn'>
                                {totalValue > 0 ?
                                    <button onClick={() => { startPayout() }}>
                                        Claim Now
                                    </button>
                                    :
                                    ""
                                }
                            </div>


                        </div> : null
                }
            </div>

                <style>
                    {`
                    :root {
                        --mainColor: var(--alt-color2);
                    }

                    .modal-close > i:hover {
                        background-color: var(--alt-color2);
                        color:black
                    }
                    `
                }
                </style>
        </>
    );

}

export default ClaimModal;
import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import M_QuickPlay from '../mainapp/play/play/m_quickplay';
import M_HowItWorks from '../mainapp/play/play/m_howitworks';
import M_Tips from '../mainapp/play/play/m_tips';
import M_AllGames from '../mainapp/play/activeGames/m_allgames';
// import M_Ongoing from '../mainapp/play/activeGames/m_ongoing';
import M_Featured from '../mainapp/shop/shop/m_featured';
import M_HowMuch from '../mainapp/play/play/m_howmuch';
import M_Botview from '../mainapp/automate/bots/m_botview';
import M_Top10 from '../mainapp/leaderboard/leaderboard/m_top10';
import M_Top100 from '../mainapp/leaderboard/leaderboard/m_top100';
import M_Lookup from '../mainapp/leaderboard/leaderboard/m_lookup';
import M_Stats from '../mainapp/play/myStats/m_stats';
import M_ActiveGamesStats from '../mainapp/play/myStats/m_activeGameStats';


const SideToolBar = (props) => {


    const links = props.links;
    const [activeLink, setActiveLink] = useState(props.defaultLink);
    const [currentVariableID, setCurrentVariableID] = useState(props.defaultLink);

    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        function handleScroll() {
            const threshold = 200; // Set your threshold here
            if (window.pageYOffset > threshold) {
                setHasScrolled(true);

            } else {
                setHasScrolled(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        !props.variableLinks ? "" : setActiveLink("botview");


    }, [])

    return (
        <>
            <div className='side-toolbar-content'>
                <div className='side-dock'>
                    <Link to={"/shop/item/botPurchase"}>
                        <button className='side-dock-btn'>
                            <i className='material-icons-outlined'>loupe</i>
                            <p>Create New Bot</p>
                        </button>
                    </Link>

                    {
                        !props.variableLinks ?
                            <div className='side-buttons'>
                                {
                                    links.map((link) => (

                                        <div className='side-button'>
                                            <div className='side-button'>
                                                <input type="radio" id={link.id} onChange={
                                                    (e) => {
                                                        setActiveLink(e.target.value);
                                                    }
                                                } defaultChecked={
                                                    link.id === props.defaultLink ? true : false
                                                } name="sideToolBar" value={link.id} />
                                                <label for={link.id}>{link.name}</label>

                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            <div className='side-buttons overSide' >
                                {
                                    links.map((link) => (


                                        <div className='side-button' >
                                            <input type="radio" id={link.variableId} onChange={
                                                (e) => {
                                                    setActiveLink(link.id);
                                                    setCurrentVariableID(link.variableId);
                                                }
                                            } name="sideToolBar" defaultChecked={
                                                link.variableId === props.defaultLink ? true : false
                                            } value={link.variableId} />
                                            <label for={link.variableId}>{link.name}</label>
                                            {

                                            }
                                        </div>


                                    ))
                                }

                            </div>
                    }

                    <button onClick={
                        () => {
                            window.scrollTo(0, 0);
                        }
                    } className='backToTop' style={
                        hasScrolled ? { opacity: 1 } : { opacity: 0 }
                    }>
                        <i className='material-icons-outlined'>arrow_upward</i>
                        <p>Back to Top</p>
                    </button>


                </div>


           
                {

                    {
                        "quickplay": <M_QuickPlay />,
                        "howitworks": <M_HowItWorks />,
                        "tipsandtricks": <M_Tips />,
                        "allgames": <M_AllGames type={activeLink} />,
                        "active": <M_AllGames type={activeLink} />,
                        "complete": <M_AllGames type={activeLink} />,
                        "featured": <M_Featured />,
                        "howmuch": <M_HowMuch />,
                        // "ongoing": <M_Ongoing />,
                        "botview": <M_Botview type={currentVariableID} key={currentVariableID} />,
                        "top10": <M_Top10 />,
                        "top100": <M_Top100 />,
                        "lookup": <M_Lookup />,
                        "stats": <M_Stats />,
                        "activegamestats": <M_ActiveGamesStats  />,

                    }[activeLink]


                }



            </div>





        </>
    );

}

export default SideToolBar;
import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { alterNotification } from '../store/schemas/notificationSlice';
import { current } from '@reduxjs/toolkit';
const MiniMap = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const currentGame = useSelector(state => state.mainapp.gameSlice.gameManager);

    const [itemIndex, setItemIndex] = useState(0);
    const dispatch = useDispatch();



  useEffect(() => {

    if (collapsed === true) {

    // duplicate checker
    
    let duplicate = JSON.parse(JSON.stringify(currentGame));

    let frequency = [];


   
    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
  
        document.getElementById("miniA" + num).innerHTML = "";
        
        document.getElementById("miniB" + num).innerHTML = "";

        document.getElementById("miniC" + num).innerHTML = "";

        document.getElementById("miniA" + num).title = "";

        document.getElementById("miniB" + num).title = "";

        document.getElementById("miniC" + num).title = "";

   

     /*    if (document.getElementById("miniA" + num).innerHTML === "1") {
            document.getElementById("miniA" + num).classList.remove("selected-mini");
        }
        if (document.getElementById("miniB" + num).innerHTML === "1") {
            document.getElementById("miniB" + num).classList.remove("selected-mini");
        } if (document.getElementById("miniC" + num).innerHTML === "1") {
            document.getElementById("miniC" + num).classList.remove("selected-mini");
        } */
    })


            currentGame.attack.map((item, index) => {
               
                if (item.value !== null) {

                    if (frequency.includes(item.value)) {
                        dispatch(alterNotification({
                            messageTitle: "Duplicate Attack Points",
                            message: "You have a duplicate value in your attack. Please change it or risk losing possible rewards. Remember the more kills you get the more rewards you will have at the end of the game.",
                            alertStyle: "warning", visible: true
                        
                        }))

                        document.getElementById("mini" + item.value).innerHTML = document.getElementById("mini" + item.value).innerHTML + ", " +  (parseInt(index) + 1)  ;

                        document.getElementById("mini" + item.value).title = document.getElementById("mini" + item.value).title + ", " +  (parseInt(index) + 1)  ;

                        document.getElementById("duplicate-detected").style.display = "block";
                     
                    }
                    else {
                    
                        frequency.push(item.value);
                        
                      
                        document.getElementById("mini" + item.value).innerHTML = "<span>Day </span> " +  (parseInt(index) + 1) ;

                        document.getElementById("mini" + item.value).title = "Day " +  (parseInt(index) + 1) ;

                    }
                   
                }
            
            }
            )
        }

  }, [currentGame, collapsed])

 const refreshAnimation = () => {
    document.getElementById('miniMap').style.display = 'none';
    setTimeout(() => {
        document.getElementById('miniMap').style.display = 'flex'
    }, 1)
    }
    
 

    return (
        <>

            <div className='minimap-container '>
                <div className='minimap-wrapper '>
                    <div className='minimap-header' onClick={
                            () => {
                                setCollapsed(!collapsed);
                            }
                        }>
                        <button style={
                            {
                                opacity: '0'
                            }
                        } className='minimap-action'><i className='material-icons'>expand_more</i></button>
                        <h3><i className='material-icons-outlined'>place</i>Mini-Map</h3>
                        <button className='minimap-action' ><i className='material-icons'>
                            {
                                collapsed ? 'expand_less' : 'expand_more'
                            }
                            </i></button>
                    </div>

                    <div className='auto-place-btn'>
                        <button type='button' onClick={
                            () => {
                                props.autoplaceFunction();
                                setCollapsed(true);
                                refreshAnimation();

                            }
                        }><i className='material-icons'>auto_awesome</i>Auto Place</button>
                    </div>
                    {
                        collapsed ?  <div className='minimap ' id='miniMap'>
                        <div className='minimap-column-a mini-column vivify fadeIn duration-500 delay-100'>
                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                    return (
                                        <>
                                            <p title={"Empty"} id={"miniA" + num}>0</p>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div className='minimap-column-b mini-column vivify fadeIn duration-500 delay-500'>
                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                    return (
                                        <>
                                            <p title={"Empty"} id={"miniB" + num}>0</p>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div className='minimap-column-c mini-column vivify fadeIn duration-500 delay-1000'>
                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
                                    return (
                                        <>
                                            <p title={"Empty"} id={"miniC" + num}>0</p>
                                        </>
                                    )
                                })
                            }
                        </div>


                    </div>
                    :
                    ""
                    }
                   
<p id='duplicate-detected' className='vivify blink delay-100'>Hover over items on mini-map to view all duplicate points.</p>
                </div>
            </div>

        </>
    );

}

export default MiniMap;
import React, { useRef, useEffect, useState } from 'react';
import NameChangeProduct from '../../../assets/products/bot.png';
import { useDispatch, useSelector } from 'react-redux';
import { purchaseItem } from '../../../httpcalls/storeThunk';
import { alterNotification } from '../../../store/schemas/notificationSlice';
const BotPurchase = () => {
    const dispatch = useDispatch();
    const bot_accounts = useSelector((state) => state.auth.userInfo.bot_accounts)
    const purchase = async () => {
        console.log(bot_accounts)
        if(bot_accounts[0].length >= 9){
           dispatch(alterNotification({ position: 'bottom-left', autoClose: 10000, alertStyle: 'warning', messageTitle: "Bot Limit Reached", message: "Thank you for supporting our new system! We currently only support 1 bot. " ,visible: true}))
        }
        else{
            dispatch(purchaseItem({item_id:3, url:'/'}))
        }
    }
    return (
        <>
            <div className='product'>
                <div className='product-image'>
                    <img src={NameChangeProduct} alt='product' />
                </div>
                <div className='product-action'>
                
                    <div className='product-interact'>
                    <div className='product-title'>
                        <h2><span>20.00</span> BUSD</h2>
                    </div>
                     <p>Meet our Auto-Play Bot, making gaming easier than ever. Set your own game entry criteria, and let the bot do the work. Keep track of stats and manage crypto payouts, all from your player account, for an effortless gaming experience.
     
                     </p>
                    </div>
                    <div className='product-checkout vivify fadeIn duration-300 delay-600'>
                        <button onClick={() => { purchase()}} className='massive-button mini-btn'> <i className='material-icons'>
                        shopping_cart_checkout
                        </i>
                            CONFIRM & PAY
                        </button>
                        {    /*  NOTICE TO DAMION: please use notification system for when name change is complete  */}
                        <p>By Clicking "Confirm & Pay" You Agree To Our <button className='' onClick={
                            () => {
                                localStorage.setItem('agreeStatus', 'false');
                                window.location.reload();
                            }
                        }>Disclaimer</button></p>
                    </div>
                   
                </div>
            </div>

            <style>
                {`
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}
        `}
            </style>

        </>
    );

}

export default BotPurchase;
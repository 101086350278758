import React, { useRef, useEffect, useState } from 'react';
import BackPlate from "../../../components/backplate";
import "../../../css/gamePlay.css"

import { Link, NavLink } from "react-router-dom";
import GameFooterNav from '../../../components/gameFooterNav';
import { useDispatch } from 'react-redux';
import { alterNotification } from '../../../store/schemas/notificationSlice';

const Defend = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(alterNotification( {position: 'bottom-left', autoClose: 0, alertStyle: 'help', messageTitle: 'Defense Game Help', message: 'Defend your base by placing your defense points on the grid. You only have 1 defense point if your point is attacked you will be eliminated. ', visible: true} )) ;
  }, []);

  return (
    <>





      <div className='game-desc-container'>
        <BackPlate backplateType="defend" />
        <div className='back-title'>
          <h1 style={{ marginLeft: "-15px" }}>Defend</h1>
        </div>
        <div className='game-desc'>
          <p >Build Your</p>
          <h1 className='page-title' style={{ marginTop: "0px" }}>Defense Point</h1>
          <span>Note: The points you choose on the grid cannot be changed after you confirm for today. </span>
        </div>
      </div>



      <div className='game-footer'>
        <div className='game-help'>
          <h1>Help</h1>
          <p>Defend your base by placing your defense points on the grid. You only have 1 defense point if your point is attacked you will be eliminated. </p>
        </div>
        <div className='game-nav-footer'>

          <GameFooterNav state="disabled" style="defend" />
        </div>
      </div>




      <style>
        {`
       :root {
        --main-color: var(--alt-color)
       }
        `}
      </style>

    </>
  );

}

export default Defend;
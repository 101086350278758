import React, { useRef, useEffect, useState } from 'react';
import BackPlate from "../../components/backplate";
import BackTitle from "../../components/backTitle";
import SubNavBar from '../../components/subNavbar';
import Footer from '../../components/footer';

import { Outlet, useNavigate } from 'react-router-dom';
const Automate = () => {
  const navigate = useNavigate();

  /* useEffect(() => {
    if (window.location.pathname === '/') {
    navigate('/play')
    }
  }, []) */
  return (
    <>
    
      <BackPlate backplateType="automate" />
      <div className='content-body'>
        <div style={
            {
                display: "none"
            }
        }>
        <SubNavBar links={
        [{
          'name': 'Home',
          'link': '/shop'
        }
        ]
      }
      />
     
        </div>
        <Outlet />
      </div>
      <div  className='lg-footer-container' >
        <Footer />
      </div>

      <style>
        {`
       :root {
        --main-color: var(--alt-color3)
       }

       .side-dock > a {
        display: block
       }

       .side-content {
        overflow: hidden;
       }

       .side-content {
        display: flex;
    flex-direction: column;
       }

       .side-dock {
        justify-content: unset
       }

       .backToTop {
       display:none !important;
        
       }
        `}
      </style>
    </>
  );

}

export default Automate;
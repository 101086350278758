import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SubNavBar from '../../subNavbar';
const AutomateModal = () => {

  const navigate = useNavigate();
  const currentBot = useSelector(state => state.mainapp.automateSlice.currentBot);

  const [currentSetupStatus, setCurrentSetupStatus] = useState(currentBot.setupStatus);



  useEffect(() => {
    if (!currentBot.setupStatus) {

    }else{
      navigate('/automate/settings/setup');
    }
  }, [currentBot]);



  return (
    <>
{
  !currentBot.setupStatus ? null :    <SubNavBar links={
    [
      {
        'name': 'Setup',
        'link': '/automate/settings/setup'
      }
    
    ]
  }
  />
}
  
  {
    currentBot.setupStatus  ? null : <SubNavBar links={
      [
        {
          'name': 'Bot Settings',
          'link': '/automate/settings'
        },
        {
          'name': 'Bot Funds',
          'link': '/automate/settings/funds'
        }
      
      ]
    }
    />
  }


      <Outlet />


      
      <style>
        {`
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}

        .sub-navbar-wrapper {
            position: static;
        }

        .product {
          margin-top: 30px
        }
        `}
      </style>

    </>
  );

}

export default AutomateModal;
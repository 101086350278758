import React, { useRef, useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import skull from '../../assets/skull.svg';
import { completedBattlesReport } from '../../httpcalls/adminThunk';

const Reports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [targetIndex, setTargetIndex] = useState(null)
  const dashboard = useSelector(state => state.mainapp.adminSlice.dashboard)

  
  return (
    <>
    
    <div className='admin-game-manager-container'>
 

        <div className='featured-btn vivify fadeIn duration-300'>
          <h1>Generate Completed Battles Report</h1>
          <p>
            This will generate a report of all completed battles. This report will include the following information: Uid,	Player Wallet,	Username,	account_age_in_days,	TCEF,	TCRE,	PC.ROI$, and Completed Lobbies
          </p>
          <button className='massive-button' onClick={() => {dispatch(completedBattlesReport())}}>Download Excel File</button>
        </div>

    </div>
    
      
    </>
  );

}

export default Reports;
import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import LeaderBoardStage from '../../../components/leaderboardComponents/stage';
import LeaderBoardBoard from '../../../components/leaderboardComponents/board';

const M_Top10 = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (
        <>
       
        <div className='leaderboard-container'>
         
                <LeaderBoardStage />
                <p className='vivify fadeIn duration-300 delay-400'>You must be entered into at least 1 game in order to show up on the leaderboard.</p>
                <LeaderBoardBoard displayCount={10} />
            </div>


        </>
    );

}

export default M_Top10;
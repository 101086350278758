import React, {useRef, useEffect, useState} from 'react';
import SideToolBar from '../../../components/sideToolbar';
import Footer from '../../../components/footer';
const LeaderboardMenu = () => {

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
      function handleScroll() {
          const threshold = 160; // Set your threshold here
          if (window.pageYOffset > threshold) {
              setHasScrolled(true);
          
          } else {
              setHasScrolled(false);
          }
      }

      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);


 

  return (
    <>
   
     <div className='menu-body vivify fadeIn duration-300 delay-200'>
    
     <h1 className='page-title' id='pageTitle' style={
        !hasScrolled ? {opacity: 1} : {opacity: 0}
     }>Leaderboard</h1>
     
        <SideToolBar 
        links={
          (window.location.pathname.includes('/admin_console') ?
          [{
            'name': 'Top 10',
            'id': 'top10'
          },
          
          {
            'name': 'Top 100',
            'id': 'top100'
          },
          {
            'name': 'Player Lookup',
            'id': 'lookup'
          },
          ]
          :
          [{
            'name': 'Top 10',
            'id': 'top10'
          },
          {
            'name': 'Player Lookup',
            'id': 'lookup'
          },
          ]
          )
        
      }
      defaultLink={'top10'}
      />


     </div>
   
    </>
  );

}

export default LeaderboardMenu;
import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboard, addLobby, addFake, shootGame } from '../../httpcalls/adminThunk';
import moment from 'moment-timezone';
import { loadingReset, setLoadingSlice } from '../../store/schemas/loadingSlice';
import skull from '../../assets/skull.svg';

const Manager = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [targetIndex, setTargetIndex] = useState(null);
  const [sortConfig, setSortConfig] = useState(null);
  const dashboard = useSelector(state => state.mainapp.adminSlice.dashboard);
  const [sortedDashboard, setSort] = useState([])
  const load = useSelector(state => state.mainapp.loadingSlice.loading);

  const insertLobby = (amount, type) => {
    dispatch(addLobby()).then(res => {
      if (res.payload == false) {
        dispatch(loadingReset());
      }
    });
    dispatch(setLoadingSlice('Altering Data...'));
  };


  useEffect(() => {
    if(dashboard.length != 0){
      console.log(dashboard)
      setSort(dashboard)
    }
  }, [dashboard])
  const insertFake = id => {
    if (process.env.REACT_APP_DEPLOYMENT_STATUS == 'development') {
      dispatch(addFake({ id: id })).then(res => {
        if (res.payload == false) {
          dispatch(loadingReset());
        }
      });
      dispatch(setLoadingSlice('Altering Data...'));
    }
  };

  const fireGame = id => {
    dispatch(shootGame({ id: id })).then(res => {
      if (res.payload == false) {
        dispatch(loadingReset());
      }
    });
    dispatch(setLoadingSlice('Altering Data...'));
  };

  const getCombinedDateTime = item => {
    let itemTime = moment(item.time);  // assuming item.time is in the 'h:mm:ss A' format

    while(itemTime.isBefore(moment().utc())) {
      itemTime = itemTime.clone().add(1, 'days');
    }
    
    return itemTime;
  };


useEffect(() => {
  if (sortConfig !== null) {
    setSort([...sortedDashboard].sort((a, b) => {
       const key = sortConfig.key;
       const direction = sortConfig.direction === 'ascending' ? 1 : -1;
       switch (key) {
         case 'id':
           const idA = String(a.id);
           const idB = String(b.id);
           return idA.localeCompare(idB) * direction;
         case 'total_players':
           return (b.total_players.length - a.total_players.length) * direction;
         case 'dayProgress':
           return ((b.dayProgress - 1) - (a.dayProgress - 1)) * direction;
         case 'time':
           const valA = getCombinedDateTime(a);
           const valB = getCombinedDateTime(b);
           if (valA.isBefore(valB)) {
             return -1 * direction;
           }
           if (valB.isBefore(valA)) {
             return 1 * direction;
           }
           return 0;
         default:
           return 0;
       }
     })
    )
   }
}, [sortConfig])
 


  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = null;
      key = null;
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (load != null) {
      dispatch(loadingReset());
    }
  }, [dashboard]);

  const getSortIcon = column => {
    if (sortConfig && sortConfig.key === column) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <>
      <div className='admin-game-manager-container'>
        <div className='pricing-block admin-game-manager'>
          <div className='admin-geneal-game-info'>
            <div>
              <h5>Active Lobbies:</h5>
              <h5>{dashboard.length}</h5>
            </div>
            <button className='admin-add-game' onClick={() => insertLobby()}>
              Add Lobby
            </button>
          </div>

          <div className='game-table'>
            <div className='topper'>
              <div onClick={() => requestSort('id')}>
                <h5>Lobby Id {getSortIcon('id')}</h5>
              </div>
              <div onClick={() => requestSort('total_players')}>
                <h5>Total Players {getSortIcon('total_players')}</h5>
              </div>
              <div onClick={() => requestSort('dayProgress')}>
                <h5>Current Day {getSortIcon('dayProgress')}</h5>
              </div>
              <div onClick={() => requestSort('time')}>
                <h5>Fire Date & Time {getSortIcon('time')}</h5>
              </div>
            </div>

            {sortedDashboard.map((item, index) => {
              return (
                <div className='game-item' onClick={() => setTargetIndex(item.id)} key={item.id}>
                  <div>
                    <h5>{item.id}</h5>
                  </div>
                  <div>
                    <h5>{item.total_players.length}</h5>
                  </div>
                  <div>
                    <h5>{item.dayProgress - 1}</h5>
                  </div>
                  <div>
                    <h5>
                      {item.time == null ? "Not enough players" : (() => {
                        // const d = new Date(item.time)
                        let a = moment(item.time);  

                        while(a.isBefore(moment().utc())) {
                          a = a.clone().add(1, 'days');
                        }
                        
                       
                                              
                        const z = moment.tz(a, moment.tz.guess());

                        const formattedDateTime = z.format("MM/DD/YYYY h:mm:ss A");
                        return formattedDateTime;
                      })()}


                    </h5>


                  </div>
                </div>
              );
            })}
          </div>
        </div>



        <div className='pricing-block admin-game-manager'>
          {(targetIndex == null) ?
            <div className='no-game-selected'>
              <h2>No Lobby Selected</h2>
            </div>
            :
            (!dashboard.some(item => item.id == targetIndex) ?
              <div className='no-game-selected'>
                <h2>Lobby is not Active</h2>
              </div>
              :
              (() => {
                const item = dashboard.find(item => item.id == targetIndex)
                return (
                  <div class="selected-lobby">
                    <div className='admin-geneal-game-info'>
                      <div>
                        <h5>Lobby ID: </h5>
                        <h5>{item.id}</h5>
                      </div>
                      <div>
                        <h5>Status: </h5>
                        <h5>
                          {(() => {
                            if (item.time == null) {
                              return "Waiting for players"
                            }
                            if (item.lock == false) {
                              const a = moment(item.time);
                              const c = a.add(5, 'hours')
                              const b = moment.tz(c.toDate(), moment.tz.guess())
                              return "Launch at " + b.format("h:mm:ss a")
                            }
                            return "In progress"
                          })()}
                        </h5>
                      </div>

                      <button className='admin-add-game' onClick={() => { fireGame(item.id) }}>Shoot & Lock Lobby</button>
                      {(process.env.REACT_APP_DEPLOYMENT_STATUS == "development") ? <button className='admin-add-game' onClick={() => { insertFake(item.id) }} style={{ background: "var(--darkFrostedBackground)" }}>Add Fake Player</button> : ""}

                    </div>
                    <div className='lobby-info'>

                      <div className='g-block'>
                        <div className='timeline'>
                          <div className='timeline-title'>
                            <h3>Game Timeline <span>Day {item.dayProgress - 1}</span></h3>


                            <p className='progress-chip'><span>{
                              (item.dayProgress - 1 == 0) ? 0 : item.totalDeadPlayers
                            }</span> Total Eleminated</p>

                          </div>

                          <div className='progress'>
                            <div className='progress-bar'>
                              <p>Day {item.dayProgress - 1}</p>
                              <progress value={item.dayProgress - 1} max={item.maxDayEnd}>
                              </progress>
                              <p>Day {item.maxDayEnd}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='g-block'>
                        <div className='timeline'>
                          <div className='timeline-title'>
                            <h3>Players <span>Total {item.total_players.length}</span></h3>
                          </div>

                          <div className='admin-players'>
                            {(item.total_players.length == 0) ?
                              <h5>No players are present</h5>
                              :
                              item.total_players.map(item => {
                                return (
                                  <div className='admin-player-card' >
                                    <div className='member-text'>
                                      <div className='m-icons'>
                                        <img src={"https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=" + item.username}></img>
                                      </div>
                                      <div className='m-text'>

                                        <h2>{
                                          item.username.replace(/#.*/, '')
                                        }</h2>


                                      </div>
                                    </div>
                                  </div>
                                )
                              })

                            }
                          </div>

                        </div>
                      </div>
                      <div className='g-block'>
                        <div className='timeline'>
                          <div className='timeline-title'>
                            <h3>Team 1 <span>Players {item.userTeam.length}</span></h3>
                          </div>

                          <div className='admin-players'>
                            {(item.userTeam.length == 0) ?
                              <h5>No players are present</h5>
                              :
                              item.userTeam.map(item => {
                                return (
                                  <div className='admin-player-card-team' >
                                    <div className='member-text'>
                                      <div className='m-icons'>
                                        <img src={"https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=" + item.userName}></img>
                                      </div>
                                      <div className='m-text'>

                                        <h2>{
                                          item.userName.replace(/#.*/, '')
                                        }</h2>
                                        {
                                          item.dead ? <h3>Eliminated</h3> : null
                                        }
                                      </div>
                                    </div>
                                    <div className='member-stats'>
                                      <div className='member-rewards'>
                                        <h2>{item.totalBusd.toLocaleString("en-us", { style: "currency", currency: "USD" })}</h2>
                                        <h3>BUSD</h3>
                                      </div>
                                      <div className='member-kills'>
                                        <img src={skull}></img>
                                        <h2>{item.totalKills}</h2>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })

                            }
                          </div>

                        </div>
                      </div>
                      <div className='g-block'>
                        <div className='timeline'>
                          <div className='timeline-title'>
                            <h3>Team 2 <span>Players {item.enemyTeam.length}</span></h3>
                          </div>

                          <div className='admin-players'>
                            {(item.enemyTeam.length == 0) ?
                              <h5>No players are present</h5>
                              :
                              item.enemyTeam.map(item => {
                                return (
                                  <div className='admin-player-card-team' >
                                    <div className='member-text'>
                                      <div className='m-icons'>
                                        <img src={"https://api.dicebear.com/5.x/bottts-neutral/svg?eyes=bulging,dizzy,eva,frame1,frame2,happy,robocop,roundFrame01,roundFrame02,sensor,shade01&backgroundColor=26F980,E2049A,E2049A&seed=" + item.userName}></img>
                                      </div>
                                      <div className='m-text'>

                                        <h2>{
                                          item.userName.replace(/#.*/, '')
                                        }</h2>
                                        {
                                          item.dead ? <h3>Eliminated</h3> : null
                                        }
                                      </div>
                                    </div>
                                    <div className='member-stats'>
                                      <div className='member-rewards'>
                                        <h2>{item.totalBusd.toLocaleString("en-us", { style: "currency", currency: "USD" })}</h2>
                                        <h3>BUSD</h3>
                                      </div>
                                      <div className='member-kills'>
                                        <img src={skull}></img>
                                        <h2>{item.totalKills}</h2>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })

                            }
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                )
              })()

            )
          }

        </div>

      </div>


    </>
  );

}

export default Manager;
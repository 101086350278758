import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getGameProgress } from '../../httpcalls/gameThunk';
import { alterLobbyBackgroundInfo } from '../../store/schemas/gameProgressSlice';
import { loadingReset, setLoadingSlice } from '../../store/schemas/loadingSlice';
import Loader from '../loader';

const LobbyActiveGameCard = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [timeLeft, setTimeLeft] = useState(props.time);
    const [status, setStatus] = useState(props.status);
    const [interval, setInt] = useState(null)


    useEffect(() => {
        const i = setInterval(() => {
            setTimeLeft(timeLeft => timeLeft - 1);
    
        }, 1000);
        setInt(i)
        return () => clearInterval(interval);
    }, []);

    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds =     timeLeft % 60;


    // Runs When Time Is Up
    useEffect(() => {
        if (timeLeft < 1) {
            setStatus('available')
            clearInterval(interval)
        }
    }, [timeLeft]);

    
    const launchTask = () => {
        dispatch(setLoadingSlice('Loading Game Progress...'))
        dispatch(alterLobbyBackgroundInfo(props))
        dispatch(getGameProgress({gameIndex:props.trackNum})).then((res) => {
            dispatch(loadingReset())
            const location = window.location.pathname
            const first_name = '/' + (location.split('/'))[1] +'/progress/report'
            navigate(first_name)
        })
    }    


    return (
        <>
            <div className={'game-card ' + status + '-card '} >

                <div className='game-card-header'>
                    <div className='game-card-header-left'>
                        <h3 className={'game-track ' + status + '-track'}><span>{props.trackNum}</span></h3>
                        <div className='game-card-title'>
                            {
                                (() => {
                                    if(status == 'available'){
                                        return ( <h4>Attack Launched!</h4>)
                                    }
                                    else{
                                        if(props.day == 0){
                                            return(
                                                <h4>Collecting Players...</h4>
                                            )
                                        }
                                        else{
                                            return(
                                                <h4>Preparing Attack</h4>
                                            )
                                        }
                                    }
                                })()
                            }
                           {(props.day > 0) ? <p>BUSD <span className={status + '-price'}>{props.value}</span>  </p> : ""}
                        </div>
                    </div>

                    <div className='game-card-header-right'>
                         
                        {(props.day > 0) ? <p>Day {props.day}</p> : ""}
                    </div>
                </div>

                <div className='game-card-body'>

                    <div className={'timer ' + status + '-timer'}>
                        <h3>{ props.day == "0" ? "Game Starting In" : "Next Attack In"}</h3>
                        <h2>{hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{parseInt(seconds).toString().padStart(2, '0')}</h2>
                    </div>
                    
                    
                    <div className={'claim-mode ' + status + '-theMode'}>
                            {

                                (status == 'taken') ? 
                                    (() => {
                                        if(props.day > 0){
                                            return (<button onClick={() => {launchTask()}} style={{"padding":"9px 18px","backgroundColor":"var(--main-color)","fontWeight":"700","fontSize":"var(--px13)","borderRadius":"26px","border":"none","cursor":"pointer", "pointerEvents":"all", "color":"white"}} className="card-button-double">View Game</button>)
                                        }
                                    
                                    })()
                                :
                                (() => {
                                    if(props.day > 0){
                                        return (<button onClick={() => {launchTask()}} style={{"padding":"9px 18px","backgroundColor":"var(--main-color)","fontWeight":"700","fontSize":"var(--px13)","borderRadius":"26px","border":"none","cursor":"pointer", "pointerEvents":"all", "color":"white"}} className="card-button-solo">View Game</button>)
                                    }
                                
                                })()
                              
                            }
                       
                        <p style={{fontSize:"12px", textAlign:"center"}}>All games are final. No refunds.</p>
                    </div>

                </div>

                    
              
                <div className='game-card-block'>
                    <p>Game Mode: {
                        props.gameType == 'default' ? "Classic" : props.gameType.includes('survivor') ? "Survivor" : "Unavailable"
                    }</p>
                    <div className='vert-divider'>
                        <div className='vert-line'></div>
                    </div>
                    <p>Total Players: {props.players}</p>      </div>
  
                    
            </div>


        </>
    );

}

export default LobbyActiveGameCard;
import React, { useRef, useEffect, useState } from 'react';
import SideToolBar from '../../../components/sideToolbar';
import Footer from '../../../components/footer';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const BotMenu = () => {
    const allBots = useSelector(state => state.mainapp.automateSlice.allBots);
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        if(location.pathname.match(/\/automate.+/)){
            navigate('/automate', {replace:true})
        }
    }, [])
    return (
        <>
            <div className='menu-body vivify fadeIn duration-300 delay-200' id='botMenuBody'>

                <h1 className='page-title'>Automate</h1>
                <SideToolBar
                    links={

                        (allBots[0].botNumber == null)

                        ?
                            [{
                                'name': 'No Bots Detected',
                                'id': 'botview',
                                'variableId': -1
                            }]
                        :
                        allBots.map((bot) => (
                            {
                                'name': bot.botName,
                                'id': 'botview',
                                'variableId': bot.botNumber
                            }
                        ))

                    }
                    defaultLink={
                        (allBots.length == 0)

                        ?
                        -1
                        :
                        allBots[0].botNumber
                    }

                    variableLinks={true}
                  
                />


            </div>
            <Outlet />

        </>
    );

}

export default BotMenu;
import React, { useRef, useEffect, useState } from 'react';
import BackPlate from "../../components/backplate";
import BackTitle from "../../components/backTitle";
import SubNavBar from '../../components/subNavbar';
import Footer from '../../components/footer';

import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getHundredPlayers, getTenPlayers } from '../../httpcalls/leaderboardThunk';
const Leaderboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [load, setLoad] = useState(true)

    useEffect(() => {
        dispatch(getTenPlayers()).then((res) => {
            setLoad(false)
        })
    }, [])
  
    return (
        <>

            <BackPlate backplateType="leaderboard" />
            <div className='content-body'>
                <SubNavBar links={
                    [{
                        'name': 'Leaderboard',
                        'link': '/leaderboard'
                    }
                    ]
                }
                />




                {(load == false) ? <Outlet />  : "" }
                
            </div>
            <div className='lg-footer-container' >
                <Footer />
            </div>

            <style>
        {`
       :root {
        --main-color: var(--alt-color2)
       }

        `}
      </style>
        </>
    );

}

export default Leaderboard;
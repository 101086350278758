import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
const DisclaimerModal = () => {

  const [agreeStatus, setAgreeStatus] = useState(false);
  const navigate = useNavigate();


  return (
    <>

      <div className='legal-text'>
        <p><strong>Legal Disclaimer & Not Financial Advice: </strong>
          NONE of the information provided on this App, website, marketing materials (where/if applicable), social media, or any other affiliated 0xBullzEye assets, or what could be perceived to be 0xBullzEye assets, is to be construed or taken as investment advice, financial advice, trading advice, or any other sort of advice, and you should not treat any of this content as such. 0xBullzEye does not recommend that any crypto currency or blockchain technology should be bought, sold, or held by you. 0xBullzEye is a blockchain research and entertainment experiment, use at your own risk.</p>

        <p>
          <strong>Accuracy of Information: </strong>
          0xBullzEye does not guarantee or confirm the accuracy of information listed on this website and thus claims no responsibility for any missing or wrong information. 0xBullzEye provides this information as is. You understand that you are using any and all information here at your own risk.
        </p>

        <p>
          <strong>KYC, Age and Sound Mind Policy: </strong>
          By using these services you hereby submit that you are of sound mind and are not currently using and or have been using any substance that could inhibit your judgment. Further, that you are of legal age and are able to participate in this experiment. Lastly, that you are not violating any IDO/KYC legislation based upon your current geographical location. The following is an example of territories that have rules around the use of these types of experiments. It is not meant to include all territories but only as an example: The United States, China, Russia, Portugal, Parts of Europe, etc. By accepting the following you acknowledge that you are abiding by any and all local authorities’ rules and regulations and assume all responsibility for the use of this website. You hold harmless 0xBullzEye, its affiliates, and any or all members of its community.
        </p>

     

      </div>

      <div className='modal-sticky-bar'>
        <div className='modal-action'>
          <div className='legal-agree'><input onChange={
            (e) => {
              setAgreeStatus(e.target.checked);
              localStorage.setItem('agreeStatus', e.target.checked);
            }
          } type='checkbox' id='legal' name='legal' value='legal' />
            <label for='legal'>I agree to the terms and conditions above</label></div>

          <button onClick={
            () => {
             
      
              if (agreeStatus == true) {
                
              window.location.reload();
              }
             
            }
          } className={"modal-action-btn" + " activeStatus" + agreeStatus}>Continue</button>
        </div>

      </div>


    </>
  );

}

export default DisclaimerModal;
import React, { useRef, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import SubNavBar from '../subNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { alterSetupStatus } from '../../store/schemas/automateSlice';
import { fundBotSettings, uploadRules, validateBotSetup } from '../../httpcalls/botThunk';
const BotConfig = (props) => {
  //NOTICE: To Damion This is where the values for the joining criteria and bot funds are outputted
  const [activeLink, setActiveLink] = useState(null);
  const [optMode, setOptMode] = useState(null);
  const [botFunds, setBotFunds] = useState(null);

  const [agreeStatus, setAgreeStatus] = useState(false);
  const current_bot = useSelector((state) => state.mainapp.automateSlice.currentBot)
  const location = useLocation()
  const botRules = [
    {
      'rulename': 'botentry',
      rules : [
        {
          'rule': 'optMode',
          'value': optMode,
        },
        {
          'rule': 'players',
          'value': activeLink,
        },
      ]
    },
  ]


  const formatCurrency = (value) => {
    value = value.replace(/[^\d.]/g, ""); // Remove non-numerical or non-period characters
    value = value.replace(/\./, "x"); // Change the first period to x
    value = value.replace(/\./g, ""); // Remove all periods
    value = value.replace(/x/, "."); // Change x back to a period

    let parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
  };


  const handleBotFundsChange = (e) => {
    const formattedValue = formatCurrency(e.target.value);
    setBotFunds(formattedValue);  
  };

  const handleBotFundsBlur = (e) => {
    console.log('link change')
    document.getElementById('1').checked = false;
    document.getElementById('5').checked = false;
    document.getElementById('20').checked = false;
  };



  const links = [
    {
      'name': '1 Player',
      'id': '1',
    },
    {
      'name': '5 Players',
      'id': '5',
    },
    {
      'name': '20 Players',
      'id': '20',
    },
  ]

  const modes = [
    {
      'name': 'Minimum',
      'id': 'minimum',
    },
    {
      'name': 'Exact',
      'id': 'exact',
    }
  
  ]

  const dispatch = useDispatch();
  const [username, setUsername] = useState("")
 

  const setupBot = (e, mode) => {

    // console.log(e)

      if(location.pathname.includes('/funds')){
        const elements = e.target.elements
        const busd = elements.botFunds.value
        dispatch(fundBotSettings({busd}) )
      }
      else if(location.pathname.includes('/setup')){
        const elements = e.target.elements
        const player_amount = (elements.smartStrip.value != '') ? elements.smartStrip.value : elements.playerValueCustom.value
        const busd = elements.botFunds.value
        const operation = elements.smartStrip1.value
    
        dispatch(validateBotSetup({player_amount, busd, operation}))
      }
      else {
        const elements = e.target.elements
        const player_amount = (elements.smartStrip.value != '') ? elements.smartStrip.value : elements.playerValueCustom.value
        const operation = elements.smartStrip1.value
        dispatch(uploadRules({player_amount, operation}))
      }
  
      
      // dispatch(validateBotSetup({player_amount, busd, operation}))
 
     
   
  }


  return (
    <>

      <form onSubmit={(e) => {e.preventDefault(); setupBot(e,  props.mode)}} id="bot-setup" className='product'>
        <div className='product-form'>

          <div className='product-interact'>
        {
          props.mode === "settings" ? (
            <>
            </>
          ) : (
            <>
            <div className='product-input'>
             <label htmlFor='botFunds'>Bot Funds</label>
             <span>Funds are used to automatically enter games.</span>
             <input defaultValue={botFunds} onChange={handleBotFundsChange} placeholder='BUSD 0.00' maxLength={"17"} type='text' name='botFunds' id='botFunds' />
           </div>
           </>
          )

        }
           
{
  props.mode === "funds" ? (
    <>
    </>
  ) : (
    <>
    {(current_bot.botName != null) ?
       <div className='product-input'>
       <label htmlFor='username'>When Exactly This Number Of Players Have Joined</label>
       <span>Your Bot Will Enter A Game.</span>

       <div className='side-buttons smart-strip' style={
         {
           width: "100%",
           marginBottom: "30px"
         }
       }>
         {modes.map((link) => (
           <div className='side-button side-form-button' style={{ width: "33%" }}>
             <input type="radio" id={link.id + "x1"} onChange={
               (e) => {
                 setOptMode(e.target.value);
               }
             } defaultChecked={
               link.id === (current_bot.botRules.length == 0 ? 'exact' : current_bot.botRules[0].operation) ? true : false
             } name="smartStrip1" value={link.id}/>
             <label style={
               {
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 flexDirection: "column",
                 padding: "12px 15px",
               }
             } for={link.id + "x1"}>{link.name}

             </label>
           </div>
         ))}


       </div>

       <div className='side-buttons smart-strip smart-form-strip' style={
         {
           width: "100%",
         }
       }>
         {links.map((link) => (
           <div className='side-button side-form-button' style={{ width: "33%" }}>
             <input type="radio" id={link.id} onChange={
               (e) => {
                 e.target.form.elements.playerValueCustom.value = '';
                 setActiveLink(e.target.value);
               }
             } defaultChecked={
               link.id === (current_bot.botRules.length == 0 ? '1' : current_bot.botRules[0].conditionValue) ? true : false
             } name="smartStrip" value={link.id} />
             <label style={
               {
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 flexDirection: "column",
                 padding: "12px 15px",
               }
             } for={link.id}>{link.name}
               <div className='smartBreak'>In Game</div>
             </label>
           </div>
         ))}


       </div>

       <div className='product-input' style={{ marginBottom: "0px" }}>

         <input style={{ marginTop: "15px" }} defaultValue={  current_bot.botRules.length != 0 ? (current_bot.botRules[0].conditionValue != '1' && current_bot.botRules[0].conditionValue != '5' && current_bot.botRules[0].conditionValue != '20') ? parseInt(current_bot.botRules[0].conditionValue) : '' : ''} onChange={(e) => { setActiveLink(e.target.value); handleBotFundsBlur(); if(e.target.value == ''){ e.target.form.elements.smartStrip[0].click() }; }} placeholder='Custom Player Count' max={15} type='number' name='playerValueCustom' id='playerValueCustom' />
         <p>Maximum of 54 Player Join Limit</p>

       </div>
     </div>
      :
             ''
    }
   
    </>
  )
}
            


          </div>


        </div>
        <div className='product-confirm'>
          <div className='product-checkout vivify fadeIn duration-300 delay-600'>
            <button onClick={
              () => {
                document.getElementById('botConfirm').innerHTML = 'Saving Changes...';
              }
            } id='botConfirm'  className='massive-button mini-btn'> 
             Confirm
            
            </button>



          </div>
        </div>

      </form>

      <style>
        {`
        .navbar {
           display: none;
        }

        .profile-chip {
          background-color: var(--frostedBackground);
          border: var(--frostedBorder);
        }

        .profile-chip:hover {border-color:transparent}
        `}
      </style>

    </>
  );

}

export default BotConfig;
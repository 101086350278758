import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import M_AllGames from '../../play/activeGames/m_allgames';
import { getAnalytics, getIndividualBot, turnOnOrOff } from '../../../httpcalls/botThunk';
import { alterNotification } from '../../../store/schemas/notificationSlice';
import { Tooltip } from '@mantine/core';

const M_Botview = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [activeLink, setActiveLink] = useState('today');
    const currentBot = useSelector(state => state.mainapp.automateSlice.currentBot);
    const [activeGame, setActiveGame] = useState('active');
    const [initalLoad, setIntialLoad] = useState(true)
    const [botPysicalStatus, setBotPysicalStatus] = useState(false)
    const [hasScrolled, setHasScrolled] = useState(false);
    const [opened, setOpened] = useState(localStorage.getItem('featureDiscoveredToolTip2') !== 'true');


    useEffect(() => {
        if (props.type != null) {
            dispatch(getIndividualBot({ bot_uid: props.type })).then((res) => {
                setIntialLoad(false)
            })
        }else{
            setIntialLoad(false)

        }

    }, [])




    useEffect(() => {

        if (initalLoad == false) {

            if (currentBot.botActivity == false && currentBot.setupStatus == false) {
                setTimeout(() => {

                    dispatch(alterNotification({
                        messageTitle: "Your Bot is now Offline",
                        message: 'Please click the "Run Bot" button to put your bot to work.',
                        alertStyle: "warning", visible: true,
                    }))
                }, 500);





            }





        }


    }, [currentBot, initalLoad])



    useEffect(() => {

        if(window.location.pathname.includes("settings")){
            dispatch(alterNotification( {visible: false} )) ;

        }

    }, [window.location.pathname])




    const alertBotChanges = () => {
        setTimeout(() => {
            if (currentBot.botActivity == false) {
                dispatch(alterNotification({
           
                    messageTitle: "Finding Games...",
                    message: 'It can take up to 10 minutes to enter a game.',
                    alertStyle: "success", visible: true,
                }))
            }
        }, 1500);




    }




    useEffect(() => {
        if (initalLoad == false) {
            document.getElementById('pageContent').style.display = 'none';
            setTimeout(() => {
                document.getElementById('pageContent').style.display = 'flex';
            }, 1);
        }

    }, [props.type, initalLoad])


    const links = [


        {
            'name': 'Today',
            'id': 'today',
        },
        {
            'name': '7 Days',
            'id': 'sevendays',
        },
        {
            'name': 'Month',
            'id': 'month',
        },
        {
            'name': 'Year',
            'id': 'year',
        },


    ]


    const gameLinks = [


        {
            'name': 'Active Games',
            'id': 'active',
        },
        {
            'name': 'Completed Games',
            'id': 'complete',
        },
        {
            'name': 'All Games',
            'id': 'waiting',
        },


    ]


    {/*  This File Should ONLY be accessed from the sideToolbar.js  */ }

    return (

        <>

            <>


                <div id='pageContent' className='side-content vivify fadeIn duration-300' style={
                    {
                        marginTop: "0px"
                    }
                }>
{
                initalLoad ? 
                <div className='inline-loader'>
                <div className='loader vivify swoopInBottom delay-100'>

                    <div className='loader-inner '>
                        <div class="indeterminate-progress-bar">
                            <div class="indeterminate-progress-bar__progress"></div>
                        </div>
                        <h3 className='vivify'>Loading Your Bots...</h3>

                    </div>
                </div>
            </div>: null
}



                    {(initalLoad == false || props.type == null)

                        ?
                        <>
                            <div className='block-layout' style={
                                currentBot.setupStatus ? {
                                    display: "flex"
                                } : {
                                    display: "none"
                                }
                            }>

                                {(props.type == null) ?
                                    <div className='hero-block'>
                                        <div className='block-tagline'>
                                            <div className='block-vert-divder'>
                                                <div className='block-vert-line'></div>
                                            </div>
                                            <h1>Create Bot</h1>
                                        </div>
                                        <div className='block-charm'>
                                            <div className='charm-text'>

                                                <h2>AUTO PLAY <span>BOT</span> </h2>
                                                <p>Level up Your Earnings. Make Auto-Play Bot Your Winning Strategy</p>
                                            </div>
                                            <div className='charm-button'>
                                                <button type='button' className='btn' onClick={
                                                    () => {
                                                        navigate('/shop/item/botPurchase')
                                                    }
                                                }>GET STARTED</button>



                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='hero-block'>
                                        <div className='block-tagline'>
                                            <div className='block-vert-divder'>
                                                <div className='block-vert-line'></div>
                                            </div>
                                            <h1>New Bot </h1>
                                        </div>
                                        <div className='block-charm'>
                                            <div className='charm-text'>

                                                <h2>Bot <span>Setup</span> </h2>
                                                <p>Discover and setup your brand new bot. Put your money to work now.</p>
                                            </div>
                                            <div className='charm-button'>
                                                <button type='button' className='btn' onClick={
                                                    () => {
                                                        navigate('/automate/settings/setup')
                                                    }
                                                }>START SETUP</button>



                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>

                            <div style={
                                currentBot.setupStatus ? {
                                    display: "none"
                                } : {
                                    display: "flex"
                                }

                            } className='block-layout' >
                                <div className='block-nav'>

                                <div className='block-alert vivify fadeIn duration-300 delay-350'>
                                    <i className='material-icons-outlined'>info</i>
                                    <p>Notice: Bots currently have a cooldown period of 10 minutes. They need time to work and find your games. </p>
                                </div>

                                    <button type='button' className='block-nav-btn' onClick={
                                        () => {
                                            navigate('/automate/settings')
                                        }
                                    }>
                                        <i className='material-icons'>settings</i>
                                        Settings
                                    </button>

                                </div>
                                <div style={
                                    {
                                        backgroundColor: "var(--frostedBackground)",
                                        backdropFilter: "blur(10px)",
                                        padding: "0px",
                                        height: "298px",
                                        justifyContent: "inherit",
                                        gap: "23px"
                                    }
                                } className='hero-block' >
                                    <div className='slim-blocks'>
                                        <div className='slim-block'>
                                            <div className='slim-title'>
                                                <i className='material-icons' style={
                                                    currentBot.totalEarnings > 0 ? {
                                                        color: "var(--main-color)"
                                                    } : {
                                                        color: "var(--light-text)"
                                                    }
                                                }>auto_graph</i>
                                                {
                                                    currentBot.totalEarnings > 0 ?
                                                        <h2>{currentBot.totalEarnings.toFixed(2)} BUSD</h2>
                                                        :
                                                        <h2>No Earnings</h2>

                                                }

                                                <p>Total Earnings</p>
                                            </div>
                                            <div className='slim-action'>
                                            <a href='#viewall'>
                                                            <button type='button' >View All Matches</button>
                                                        </a>

                                                <p>Rewards bots have collected so far.</p>
                                            </div>
                                        </div>

                                        <div className='slim-block '>
                                       {/*      <p>It Can Take Up To 10 Minutes To Find a Game</p> */}
                                            <div className='slim-title'>
                                                <i className={
                                                    !currentBot.botActivity ? "material-icons" : "material-icons vivify blink infinite duration-2500"

                                                } style={
                                                    currentBot.botActivity ? {
                                                        color: "var(--main-color)"
                                                    } : {
                                                        color: "var(--light-text)"
                                                    }
                                                }>bolt</i>
                                                <h2>{
                                                    currentBot.botActivity ? "ON" : "OFF"
                                                }</h2>
                                                <p>Bot Activity</p>
                                            </div>
                                            <div className='slim-action'>
                                                <button id='loadingActivity' type='button' onClick={(e) => {
                                                    dispatch(turnOnOrOff({ bot_uid: props.type }))
                                                    document.getElementById('loadingActivity').innerHTML = "Processing..."
                                                    alertBotChanges()
                                                }}>{
                                                        currentBot.botActivity ? "Disable Bot" : "Run Bot"
                                                    }</button>
                                                <p>Whether the bot is running or not.</p>
                                            </div>
                                        </div>

                                        <div className='slim-block'>
                                            <div className='slim-title'>
                                                <i className='material-icons' style={
                                                    currentBot.botFunds > 0 ? {
                                                        color: "var(--main-color)"
                                                    } : {
                                                        color: "var(--light-text)"
                                                    }
                                                }>attach_money</i>
                                                {
                                                    currentBot.botFunds > 0 ?
                                                        <h2>{currentBot.botFunds.toFixed(2)} BUSD</h2>
                                                        :
                                                        <h2>No Funds</h2>

                                                }
                                                <p>Bot Funds</p>
                                            </div>
                                            <div className='slim-action'>
                                                <button type='button' onClick={
                                                    () => {
                                                        navigate('/automate/settings/funds')
                                                    }
                                                } className='prominent-action'>ADD FUNDS</button>
                                                <p>Available bot funds to enter a game.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='block-charm' style={
                                        {
                                            width: "fit-content",
                                        }
                                    }>
                                        <div className='charm-text'>

                                            <h2>Bot <span >#{(currentBot.botName == null) ? '' : currentBot.botName.substring(currentBot.botName.length - 5, currentBot.botName.length)}</span> </h2>
                                            <p onClick={
                                                () => {
                                                    navigate('/automate/settings')
                                                }
                                            }>Bot Rules: Enters a game when a(n) {
                                                (currentBot.botRules.length != 0) ?
                                                    currentBot.botRules.map((rule) => (
                                                        currentBot.botRules.length > 1 ?
                                                            currentBot.botRules.indexOf(rule) === currentBot.botRules.length - 1 ?

                                                                currentBot.botRules.indexOf(rule) === 0 ?
                                                                    <div className='charm-inline'> <span>{rule.operation}</span> of <span>{rule.conditionValue}</span>  </div>
                                                                    :
                                                                    <div className='charm-inline'> and <span>{rule.operation}</span> of <span>{rule.conditionValue}</span>  </div>
                                                                : <div className='charm-inline'> <span>{rule.operation}</span> of <span>{rule.conditionValue}</span>  </div>
                                                            :
                                                            <div className='charm-inline'> <span>{rule.operation}</span> of <span>{rule.conditionValue} player(s)</span>  is in the lobby</div> 
                                                    ))
                                                    :
                                                    ""
                                            }  </p>
                                        </div>

                                    </div>
                                </div>

                                <div className='action-block-container'>
                                    <div className='action-block vivify fadeIn duration-300 delay-100'>
                                        <div className='action-title'>
                                            <h2>Bot Matches</h2>

                                        </div>
                                        <div className='action-body'>
                                            <div className='action-blockling'>
                                                <div className='slim-block' style={
                                                    {
                                                        width: "100%",
                                                        height: "237px"
                                                    }
                                                }>
                                                    <div className='slim-title'>
                                                        <i className='material-icons'>smart_toy</i>
                                                        <h2>
                                                            {
                                                                currentBot.botMatches > 0 ?
                                                                    currentBot.botMatches + " Matches"
                                                                    :
                                                                    "No Matches"

                                                            }
                                                        </h2>
                                                        <p>Active In</p>
                                                    </div>
                                                    <div className='slim-action'>
                                                        <a href='#viewall'>
                                                            <button type='button' >View All Matches</button>
                                                        </a>
                                                        <p>How many games this bot is currently active in.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='action-block vivify fadeIn duration-300 delay-100' style={
                                        {
                                            width: "66%",

                                        }
                                    }>
                                        <div className='action-title'>
                                            <h2>Bot Performance</h2>
                                            <div className='side-buttons smart-strip'>
                                                {links.map((link) => (
                                                    <div className='side-button'>
                                                        <input type="radio" id={link.id} onChange={
                                                            async (e) => {
                                                                await dispatch(getAnalytics({ bot_uid: props.type, time_id: link.id }));
                                                                setActiveLink(e.target.value);
                                                            }
                                                        } defaultChecked={
                                                            link.id === activeLink ? true : false
                                                        } name="smartStrip" value={link.id} />
                                                        <label for={link.id}>{link.name}</label>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                        <div className='action-body'>
                                            <div className='action-blockling'>
                                                <div className='data-blockling'>
                                                    <div className='data-b'>
                                                        <h1>Total Rewards</h1>
                                                        {
                                                            <p>{currentBot.performanceData.totalRewards.toFixed(2)} BUSD</p>

                                                        }
                                                    </div>
                                                    <div className='data-b'>
                                                        <h1>Total Kills</h1>
                                                        {
                                                            <p>{currentBot.performanceData.totalKills}</p>


                                                        }
                                                    </div>
                                                    <div className='data-b'>
                                                        <h1>Total Deaths</h1>
                                                        {

                                                            <p>{currentBot.performanceData.totalDeaths}</p>


                                                        }
                                                    </div>
                                                    <div className='data-b'>
                                                        <h1>Bot Funds Added</h1>
                                                        {

                                                            <p>{currentBot.performanceData.botFundsDeposited.toFixed(2)} BUSD</p>

                                                        }
                                                    </div>
                                                </div>
                                                <div className='data-blockling'>
                                                    <div className='data-b'>
                                                        <h1>Avg Game Length</h1>
                                                        {
                                                            currentBot.performanceData.avgGameLength > 0 ?
                                                                <p>{currentBot.performanceData.avgGameLength} Days</p>
                                                                :
                                                                <p>Data Unavailable</p>
                                                        }
                                                    </div>
                                                    <div className='data-b'>

                                                    </div>
                                                    <div className='data-b'>

                                                    </div>
                                                    <div className='data-b'>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>





                            </div>
                            {!currentBot.setupStatus ? <h3 id='viewall' style={
                                {

                                    marginTop: "-65px",
                                    paddingTop: "90px",
                                    marginBottom: "-88px",

                                }
                            }>Bot Games</h3> : null
                            }

                           
                            {
                                !currentBot.setupStatus ?
                                    <>
                                        <div className='right-offset'>
                                            <div className='side-buttons smart-strip fixed-sides'>
                                                {gameLinks.map((link) => (
                                                    <div className='side-button'>
                                                        <input type="radio" id={link.id} onChange={
                                                            (e) => {
                                                                setActiveGame(link.id);
                                                            }
                                                        } defaultChecked={
                                                            link.id === activeGame ? true : false
                                                        } name="smartStripGames" value={link.id} />
                                                        <label for={link.id}>{link.name}</label>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>

                                    </> :
                                    null
                            }


                            {
                                !currentBot.setupStatus ? <M_AllGames type={activeGame} bot={props.type} /> : null
                            }


                        </>

                        :

                        ""
                    }



                </div>



            </>



            <style>
                {`
        .side-content {
            margin-top: -40px !important;
        }

        .side-content > .side-content {padding-left:0px; padding-right:0px;padding-top:0px;}

        .game-card {width:32%}

        .side-content {border-radius: 0px}

        .notif-container {left: 34px !important;}
        `}
            </style>
        </>
    );

}

export default M_Botview;